import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { DetailBundling } from "../../constants/types";
import { useDetailBarangModal } from "../../context/detailBarangModalContext";
import { useTransaksi } from "../../context/transaksiContext";
import toRupiah from "../../utils/toRupiah";
import StarIcon from "@mui/icons-material/Star";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import toRibuan from "../../utils/toRibuan";

interface ICardBarangMiniProps {
    id: number;
    namaBarang: string;
    namaKategori: string;
    deskripsi?: string;
    harga: number;
    url: string;
    sku: string;
    jumlahStok: number;
    detailBundling: DetailBundling[] | [];
    favorite: number;
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    multipleHarga: {
        id: number;
        harga: number;
        keterangan: string | null;
    }[];
}

const CardBarangMini = ({
    id,
    namaBarang,
    namaKategori,
    deskripsi,
    harga,
    url,
    sku,
    jumlahStok,
    detailBundling,
    favorite,
    hpp,
    multipleHarga,
}: ICardBarangMiniProps) => {
    const { addToCart, cart } = useTransaksi();
    const { openModalDetailBarang } = useDetailBarangModal();

    return (
        <Box
            onClick={() => openModalDetailBarang(namaBarang, url, deskripsi)}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid rgba(0,0,0,0.12)",
                borderRadius: 1,
                marginBottom: 1,
                padding: 1,
                gap: 1,
            }}
        >
            <Stack spacing={0.5}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body2" fontWeight={500}>
                        {namaBarang}
                    </Typography>
                    {favorite > 0 && (
                        <StarIcon
                            sx={{
                                color: "#fbbf24",
                                bgcolor: "#FFFFFF",
                            }}
                            fontSize="small"
                        />
                    )}
                </Stack>
                <Typography
                    variant="caption"
                    color={
                        (jumlahStok < 5 && jumlahStok !== null) ||
                        !detailBundling.every(
                            (item) => item.stok === null || item.stok > 0,
                        )
                            ? "#ef4444"
                            : "#76747C"
                    }
                    fontWeight={
                        (jumlahStok < 5 && jumlahStok !== null) ||
                        !detailBundling.every(
                            (item) => item.stok === null || item.stok > 0,
                        )
                            ? 700
                            : 600
                    }
                >
                    {detailBundling.length > 0
                        ? detailBundling.every(
                              (item) => item.stok === null || item.stok > 0,
                          )
                            ? "Otomatis"
                            : "Ada barang yang habis"
                        : jumlahStok === null
                        ? "Tanpa stok"
                        : jumlahStok > 5
                        ? "Tersedia " + toRibuan(jumlahStok) + " barang"
                        : jumlahStok <= 5 && jumlahStok > 0
                        ? "Barang tersisa " + toRibuan(jumlahStok)
                        : "Barang telah habis"}
                </Typography>
                {multipleHarga.length > 0 ? (
                    <Typography
                        variant="caption"
                        color="#76747C"
                        fontWeight="bold"
                    >
                        {toRupiah(Number(multipleHarga[0].harga))}
                    </Typography>
                ) : (
                    <Typography
                        variant="caption"
                        color="#76747C"
                        fontWeight="bold"
                    >
                        {toRupiah(harga)}
                    </Typography>
                )}
            </Stack>
            <Button
                disabled={
                    cart.findIndex((c) => c.id === id) >= 0 ||
                    (jumlahStok <= 0 && jumlahStok !== null) ||
                    !detailBundling.every(
                        (item) => item.stok === null || item.stok > 0,
                    )
                }
                variant="contained"
                onClick={(event) => {
                    event.stopPropagation();
                    addToCart({
                        id,
                        namaBarang,
                        namaKategori,
                        harga:
                            multipleHarga.length > 0
                                ? Number(multipleHarga[0].harga)
                                : harga,
                        url,
                        qty: 1,
                        sku,
                        jumlahStok,
                        detailBundling,
                        hpp,
                        faktur: [],
                        multipleHarga,
                    });
                }}
                startIcon={
                    cart.findIndex((c) => c.id === id) >= 0 ? (
                        <ShoppingCartIcon />
                    ) : (
                        <AddShoppingCartIcon />
                    )
                }
                size="small"
                sx={{ minWidth: "150px", maxWidth: "150px" }}
            >
                {cart.findIndex((c) => c.id === id) >= 0
                    ? "Di Keranjang"
                    : (jumlahStok <= 0 && jumlahStok !== null) ||
                      !detailBundling.every(
                          (item) => item.stok === null || item.stok > 0,
                      )
                    ? "Stok Habis"
                    : "Pesan"}
            </Button>
        </Box>
    );
};

export default CardBarangMini;
