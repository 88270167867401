import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import React from "react";
import LogoUlo from "../LogoUlo/LogoUlo";
import { ReactComponent as ErrorFetch } from "../../assets/images/ErrorFetch.svg";
import Typography from "@mui/material/Typography";

const ErrorFetchView = () => {
    return (
        <Stack
            height="100vh"
            spacing={4}
            justifyContent="center"
            alignItems="center"
        >
            <LogoUlo width="100px" />
            <ErrorFetch style={{ height: "fit-content", width: 350 }} />
            <Stack spacing={1} justifyContent="center" alignItems="center">
                <Typography variant="h6" fontWeight={700} textAlign="center">
                    Maaf, kami tidak dapat memproses permintaan anda
                </Typography>
                <Typography textAlign="center">
                    Silahkan coba lagi dengan memuat ulang halaman
                </Typography>
            </Stack>
            <Button
                variant="contained"
                size="large"
                color="ulo"
                onClick={() => window.location.reload()}
            >
                Muat Ulang Halaman
            </Button>
        </Stack>
    );
};

export default ErrorFetchView;
