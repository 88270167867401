import { useQuery } from "react-query";
import {
    ParamsGetPengeluaran,
    GetPengeluaranResponse,
} from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (params: ParamsGetPengeluaran) => {
    const apiGetPengeluaran = qs.stringifyUrl({
        url: "api/biaya",
        query: {
            ukmId: params.ukmId,
            page: params.page,
            size: params.size,
            search: `${params.search}`,
            tanggalAwal: params.tanggalAwal,
            tanggalAkhir: params.tanggalAkhir,
        },
    });
    const { data } = await axios.get<GetPengeluaranResponse>(apiGetPengeluaran);
    return data.data;
};

export default function usePengeluaran(params: ParamsGetPengeluaran) {
    return useQuery<GetPengeluaranResponse["data"]>(
        ["pengeluaran", params],
        () => handleRequest(params),
    );
}
