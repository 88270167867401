type FieldMapping = {
    [key: string]:
        | "kategori"
        | "nama"
        | "harga"
        | "stok"
        | "sku"
        | "kadaluarsa"
        | undefined;
};

export const mapStockFieldSorting = (
    fieldName: string,
): FieldMapping[keyof FieldMapping] => {
    const fieldMap: FieldMapping = {
        namaBarang: "nama",
        kategori: "kategori",
        hargaMultiple: "harga",
        stok: "stok",
        sku: "sku",
        expAt: "kadaluarsa",
    };

    return fieldMap[fieldName];
};
