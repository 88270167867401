import qs from "query-string";
import { useMutation, useQueryClient } from "react-query";
import axios from "../axios";

export type DeleteLogoBody = {
    ukmId: number;
};

const deleteLogo = async (body: DeleteLogoBody) => {
    const apiUrl = qs.stringifyUrl({
        url: `/api/delgambarukm`,
    });
    const response = await axios.post(apiUrl, body);
    return response.data;
};

export default function useDeleteLogo() {
    const queryClient = useQueryClient();

    return useMutation((body: DeleteLogoBody) => deleteLogo(body), {
        onSuccess: () => {
            queryClient.invalidateQueries("ukm");
        },
    });
}
