import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState } from "react";
import NoRowsImage from "../NoRowsImage/NoRowsImage";
import LoadingTabel from "../LoadingTabel/LoadingTabel";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../../context/authContext";
import { ParamsGetProdukTerjual } from "../../constants/types";
import useProdukTerjual from "../../services/queries/useProdukTerjual";
import SearchIcon from "@mui/icons-material/Search";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import Tooltip from "@mui/material/Tooltip";
import toRupiah from "../../utils/toRupiah";

moment.locales();
moment.locale("id");

interface ICardStokTerjualProps {
    tanggalAwal: string;
    tanggalAkhir: string;
}

const CardStokTerjual = ({
    tanggalAwal,
    tanggalAkhir,
}: ICardStokTerjualProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    let now = moment().format("YYYY/MM/DD");

    const { ukmIdUser } = useAuth();
    const [searchProdukTerjual, setSearchProdukTerjual] = useState("");

    const initialParamsProdukTerjual = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 10,
        page: 1,
        tanggalAwal: now,
        tanggalAkhir: now,
    };
    const [paramsProdukTerjual, setParamsProdukTerjual] =
        useState<ParamsGetProdukTerjual>(initialParamsProdukTerjual);
    const { data: produkTerjual, isLoading: isLoadingProdukTerjual } =
        useProdukTerjual(paramsProdukTerjual);

    const handleOnPaginationProdukTerjual = (page: number) => {
        setParamsProdukTerjual((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChangeProdukTerjual = (size: number) => {
        setParamsProdukTerjual((prev) => ({ ...prev, size }));
    };

    const columns: GridColDef[] = [
        {
            field: "namaItem",
            headerName: "Nama Barang",
            headerAlign: "center",
            align: "left",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 150,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "jumlahItem",
            headerName: "Jumlah Item",
            headerAlign: "center",
            align: "center",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 150,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "totalHarga",
            headerName: "Total Harga",
            headerAlign: "center",
            align: "right",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 150,
            renderCell: (params) => (
                <Tooltip title={toRupiah(params.value)}>
                    <span className="table-cell-trucate">
                        {toRupiah(params.value)}
                    </span>
                </Tooltip>
            ),
        },
    ];

    useEffect(() => {
        if (ukmIdUser) {
            setParamsProdukTerjual((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        if (tanggalAwal || tanggalAkhir) {
            setParamsProdukTerjual((prev) => ({
                ...prev,
                tanggalAwal: tanggalAwal,
                tanggalAkhir: tanggalAkhir,
            }));
        }
    }, [tanggalAkhir, tanggalAwal]);

    return (
        <Card variant="outlined" sx={{ borderRadius: "0 4px 4px 4px" }}>
            <CardContent>
                <Stack
                    direction={{
                        xs: "column",
                        sm: "row",
                    }}
                    width="100%"
                    display="flex"
                    sx={{
                        alignItems: {
                            xs: "stretch",
                            sm: "center",
                        },
                        justifyContent: {
                            xs: "space-between",
                            sm: "space-between",
                        },
                    }}
                    spacing={2}
                >
                    <Stack
                        direction={{
                            xs: "column",
                            sm: "row",
                        }}
                        sx={{
                            alignItems: {
                                sx: "normal",
                                sm: "center",
                            },
                        }}
                        spacing={{ xs: 1, sm: 2 }}
                    >
                        <Typography id="produkTerjual" fontWeight="bold">
                            Stok Terjual
                        </Typography>
                        <Typography variant="subtitle2">
                            {`Periode: ${moment(
                                paramsProdukTerjual.tanggalAwal,
                            ).format("DD/MM/YYYY")} - ${
                                paramsProdukTerjual.tanggalAkhir &&
                                moment(paramsProdukTerjual.tanggalAkhir).format(
                                    "DD/MM/YYYY",
                                )
                            }`}
                        </Typography>
                    </Stack>
                    <TextField
                        placeholder="Cari barang..."
                        size="small"
                        value={searchProdukTerjual}
                        onChange={(e) => setSearchProdukTerjual(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                setParamsProdukTerjual((prev) => ({
                                    ...prev,
                                    search: searchProdukTerjual,
                                    page: 1,
                                }));
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <Link
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    onClick={() =>
                                        setParamsProdukTerjual((prev) => ({
                                            ...prev,
                                            search: searchProdukTerjual,
                                            page: 1,
                                        }))
                                    }
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <SearchIcon fontSize="small" />
                                </Link>
                            ),
                        }}
                        variant="outlined"
                    />
                </Stack>
                <Box
                    marginTop={2}
                    sx={{
                        height: 630,
                        width: "100%",
                        borderRadius: 1,
                        "& .headerColumn": {
                            backgroundColor: "#E4EEE8",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                        },
                        "& .MuiDataGrid-columnSeparator": {
                            visibility: "hidden",
                        },
                        "& .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        },
                        "& .MuiDataGrid-iconButtonContainer": {
                            visibility: "visible",
                            width: "0 !important",
                        },
                    }}
                >
                    {/* <ThemeProvider theme={theme}> */}
                    <DataGrid
                        pagination
                        rows={produkTerjual?.content ?? []}
                        columns={columns}
                        components={{
                            ColumnUnsortedIcon: () => {
                                return <ImportExportIcon />;
                            },
                            NoRowsOverlay: NoRowsImage,
                            LoadingOverlay: LoadingTabel,
                        }}
                        rowsPerPageOptions={[10]}
                        disableColumnMenu
                        disableSelectionOnClick
                        paginationMode="server"
                        onPageSizeChange={handleOnSizeChangeProdukTerjual}
                        onPageChange={handleOnPaginationProdukTerjual}
                        rowCount={produkTerjual?.totalElements ?? 0}
                        page={paramsProdukTerjual.page - 1}
                        pageSize={paramsProdukTerjual.size}
                        loading={isLoadingProdukTerjual}
                    />
                    {/* </ThemeProvider> */}
                </Box>
            </CardContent>
        </Card>
    );
};

export default CardStokTerjual;
