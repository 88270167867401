// import { useQuery } from "react-query";
// import { GetDetailInvoiceResponse } from "../../constants/types";
// import axios from "../axios";

// const handleRequest = async (id: number) => {
//     const { data } = await axios.get<GetDetailInvoiceResponse>(
//         `/api/laporan/invoice/${id}`,
//     );
//     return data.data;
// };

// export default function useDetailInvoice(id: number) {
//     return useQuery<GetDetailInvoiceResponse["data"]>(
//         ["detailInvoice", id],
//         () => handleRequest(id),
//         { enabled: !!id },
//     );
// }

import { useQuery } from "react-query";
import {
    GetDetailInvoiceResponse,
    ParamsGetDetailInvoice,
} from "../../constants/types";
import axios from "../axios";
const queryString = require("query-string");

const handleRequest = async (params: ParamsGetDetailInvoice) => {
    const apiUrl = queryString.stringifyUrl({
        url: `/api/laporan/invoice/${params.id}`,
        query: {
            grupProduk: params.grupProduk,
        },
    });
    const { data } = await axios.get<GetDetailInvoiceResponse>(apiUrl);
    return data.data;
};

export default function useDetailInvoice(params: ParamsGetDetailInvoice) {
    return useQuery<GetDetailInvoiceResponse["data"]>(
        ["detailInvoice", params],
        () => handleRequest(params),
        { enabled: !!params.id },
    );
}
