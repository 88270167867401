import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
    Box,
    Button,
    Typography,
    FormControl,
    OutlinedInput,
    FormHelperText,
    InputAdornment,
    IconButton,
    Link,
    useMediaQuery,
    Grid,
    Autocomplete,
    TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoginImage from "../../assets/images/IlustrasiDaftar.png";
import LogoUlo from "../LogoUlo/LogoUlo";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
    ErrorFieldResponse,
    RegistrasiForm,
    RegistrasiResponse,
} from "../../constants/types";
import defaultAxios, { AxiosError } from "axios";
import axios from "../../services/axios";
import ThemeDefaultComponent from "../ThemeDefaultComponent/ThemeDefaultComponent";
import ReCAPTCHA from "react-google-recaptcha";
import { captchaSiteKey } from "../../constants/common";
import Swal from "sweetalert2";
import useProvinsi from "../../services/queries/useProvinsi";
import useKota from "../../services/queries/useKota";
import Stack from "@mui/material/Stack";
import CheckBox from "@mui/material/Checkbox";

interface IRegisterProps {}

const schema = yup.object({
    nama: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama terlalu panjang"),
    username: yup
        .string()
        .matches(/^\S*$/, "Tidak boleh ada spasi")
        .required("Kolom wajib diisi")
        .min(6, "Username terlalu pendek")
        .max(50, "Username terlalu panjang"),
    email: yup
        .string()
        .email("Format email salah")
        .required("Kolom wajib diisi")
        .max(50, "Email terlalu panjang"),
    no_telp: yup
        .string()
        .required("Kolom wajib diisi")
        .matches(/^8[1-9][0-9]/, "No. Telepon tidak valid, contoh: 831xxxx")
        .min(9, "No. Telepon terlalu pendek")
        .max(13, "No. Telepon terlalu panjang"),
    password: yup
        .string()
        .required("Kolom wajib diisi")
        .min(8, "Kata sandi minimal 8 karakter"),
    passwordUlang: yup
        .string()
        .required("Kolom wajib diisi")
        .oneOf([yup.ref("password")], "Kata sandi tidak sama"),
    namaToko: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama terlalu panjang"),
    alamat: yup.string().required("Kolom wajib diisi"),
    provinsi: yup
        .object()
        .shape({
            id: yup.string().required("Kolom wajib diisi"),
            nama: yup.string().required("Kolom wajib diisi"),
        })
        .required("Kolom wajib diisi"),
    kota: yup
        .object()
        .shape({
            id: yup.string().required("Kolom wajib diisi"),
            nama: yup.string().required("Kolom wajib diisi"),
            provinsiId: yup.string().required("Kolom wajib diisi"),
            provinsiNama: yup.string().required("Kolom wajib diisi"),
        })
        .required("Kolom wajib diisi"),
});

const Register = (props: IRegisterProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up("xl"));
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] =
        useState(false);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState<string | null>();
    const [isHuman, setIsHuman] = useState(false);
    const thisYear = new Date().getFullYear();
    const [initReferral, setInitReferral] = useState<any>();

    const { data: provinsiOptions, isLoading: isLoadingProvinsi } =
        useProvinsi();

    const [paramsKota, setParamsKota] = useState<number>();
    const { data: kotaOptions, isLoading: isLoadingKota } = useKota(paramsKota);

    const [checked, setChecked] = useState(false);

    const history = useHistory();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryReferral = urlParams.get("ref");

    const initialValues: RegistrasiForm = useMemo(
        () => ({
            nama: "",
            username: "",
            email: "",
            no_telp: "",
            password: "",
            passwordUlang: "",
            referral: initReferral ?? "",
            namaToko: "",
            alamat: "",
        }),
        [initReferral],
    );

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        reset,
    } = useForm<RegistrasiForm>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    useEffect(() => {
        if (queryReferral) {
            reset({ ...initialValues, referral: queryReferral ?? "" });
        } else {
            setInitReferral("");
        }
    }, [initialValues, queryReferral, reset]);

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handleClickShowPasswordConfirmation = () => {
        setShowPasswordConfirmation((prev) => !prev);
    };

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const onSubmit = async (values: RegistrasiForm) => {
        console.log(values);
        setLoading(true);
        const formattedNoTelp = "62" + values.no_telp;
        if (isHuman && token) {
            try {
                const formData = new FormData();
                formData.append("nama", values.nama);
                formData.append("username", values.username);
                formData.append("email", values.email);
                formData.append("no_telp", formattedNoTelp);
                formData.append("alamat", values.alamat);
                formData.append("password", values.password);
                formData.append("role", "10");
                formData.append("referral", values.referral);
                formData.append("namaToko", values.namaToko);
                formData.append("no_telpToko", formattedNoTelp);
                formData.append("alamatToko", values.alamat);
                formData.append("warnaToko", "#45A779");
                if (values.provinsi?.id) {
                    formData.append("provinsi", String(values.provinsi.id));
                }
                if (values.kota?.id) {
                    formData.append("kota", String(values.kota.id));
                }
                // formData.append("gambar", "");
                const { data } = await axios.post<RegistrasiResponse>(
                    "/api/register",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                console.log(`data register`, data);

                if (data.code === 200) {
                    history.push(`/verifikasi`);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                if (defaultAxios.isAxiosError(error)) {
                    const serverError = error as AxiosError<
                        ErrorFieldResponse | undefined
                    >;
                    if (serverError && serverError.response) {
                        const fieldError = serverError?.response?.data;
                        Swal.fire({
                            title: "Terjadi Kesalahan!",
                            text: `Data yang diberikan salah`,
                            icon: "error",
                            confirmButtonColor: "#45A779",
                            customClass: {
                                container: "my-swal",
                            },
                        });
                        console.log("", `${fieldError}`, [{ text: "OK" }]);
                        if (fieldError?.errors) {
                            Object.keys(fieldError.errors).forEach((key) => {
                                const errorMessage = fieldError.errors[key];
                                setError(key as keyof typeof initialValues, {
                                    type: "manual",
                                    message: errorMessage[0],
                                });
                            });
                        }
                    }
                }
                console.error(error);
            }
        } else {
            setLoading(false);
            Swal.fire({
                title: "Terjadi Kesalahan!",
                text: `Tolong centang box reCAPTCHA`,
                icon: "error",
                confirmButtonColor: "#45A779",
                customClass: {
                    container: "my-swal",
                },
            });
        }
    };

    return (
        <ThemeDefaultComponent>
            <Box
                padding={3}
                display={"flex"}
                justifyContent={"center"}
                sx={{ backgroundColor: "#45A779" }}
            >
                <Box
                    sx={{
                        minHeight: "930px",
                        width: isTabletScreen ? undefined : "fit-content",
                        // width:
                        //     isPhoneScreen || isTabletScreen
                        //         ? undefined
                        //         : isLaptopScreen
                        //         ? "90%"
                        //         : "650px",
                        // height: isPhoneScreen
                        //     ? undefined
                        //     : isLaptopScreen
                        //     ? "90%"
                        //     : "550px",
                        position: undefined,
                        top: undefined,
                        left: undefined,
                        transform: undefined,
                        backgroundColor: "#FFF",
                        display:
                            isPhoneScreen || isTabletScreen ? "flex" : "grid",
                        justifyContent: "center",
                        gridTemplateColumns: isPhoneScreen
                            ? undefined
                            : "1fr 1fr",
                        borderRadius: 1,
                        paddingX: isTabletScreen ? 2 : undefined,
                        paddingY: 2,
                    }}
                >
                    {isTabletScreen ? (
                        ""
                    ) : (
                        <Box position="relative">
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    height: "90%",
                                    width: "90%",
                                }}
                            >
                                <img
                                    alt="Ilustrasi Main"
                                    src={LoginImage}
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: "contain" }}
                                />
                            </Box>
                            <Box
                                position="absolute"
                                bottom={0}
                                left={24}
                                display="flex"
                                justifyContent="center"
                            >
                                <Typography
                                    variant="caption"
                                    component="div"
                                    textAlign="center"
                                >
                                    <Link
                                        variant="caption"
                                        target="_blank"
                                        href="https://nore.web.id/"
                                        underline="hover"
                                    >
                                        Nore Inovasi
                                    </Link>{" "}
                                    {`© ${thisYear}. All right reserved`}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        paddingY={3}
                        paddingX={isTabletScreen ? 2 : 4}
                        sx={{
                            backgroundColor: "#fff",
                            borderRadius: "inherit",
                        }}
                    >
                        <Link
                            href="https://ukmlokal.com/"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <LogoUlo width="100px" />
                        </Link>
                        {isLaptopScreen ? (
                            <Typography
                                marginTop={2}
                                component="div"
                                textAlign="center"
                                variant="h6"
                                fontWeight="bold"
                            >
                                Bergabung dengan UKM{" "}
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{ color: "#20965F" }}
                                    display="inline"
                                >
                                    LOKAL
                                </Typography>
                            </Typography>
                        ) : (
                            <React.Fragment>
                                <Typography
                                    marginTop={2}
                                    textAlign="center"
                                    variant="h6"
                                    fontWeight="bold"
                                >
                                    Bergabung dengan
                                </Typography>
                                <Typography
                                    component="div"
                                    textAlign="center"
                                    variant="h6"
                                    fontWeight="bold"
                                >
                                    UKM{" "}
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        sx={{ color: "#20965F" }}
                                        display="inline"
                                    >
                                        LOKAL
                                    </Typography>
                                </Typography>{" "}
                            </React.Fragment>
                        )}
                        <form
                            style={{
                                width: "100%",
                                maxWidth: "500px",
                                minWidth: "200px",
                            }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="#000"
                                fontSize="16px"
                                sx={{
                                    width: "fit-content",
                                    marginTop: 3,
                                    borderBottom: "1px solid #20965F",
                                    marginBottom: 2,
                                }}
                            >
                                Daftar
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        Nama
                                    </Typography>
                                    <Controller
                                        name="nama"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.nama)}
                                                sx={{
                                                    maxWidth: "500px",
                                                }}
                                            >
                                                <OutlinedInput
                                                    id="nama"
                                                    autoFocus={isLaptopScreen}
                                                    placeholder="Contoh: Budi Saputra"
                                                    size="small"
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.nama
                                                        ? errors.nama.message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        Username
                                    </Typography>
                                    <Controller
                                        name="username"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.username)}
                                                sx={{
                                                    maxWidth: "500px",
                                                }}
                                            >
                                                <OutlinedInput
                                                    id="username"
                                                    placeholder="Contoh: budi93"
                                                    size="small"
                                                    inputProps={{
                                                        style: {
                                                            textTransform:
                                                                "lowercase",
                                                        },
                                                    }}
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.username
                                                        ? errors.username
                                                              .message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        Email
                                    </Typography>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.email)}
                                                sx={{
                                                    maxWidth: "500px",
                                                }}
                                            >
                                                <OutlinedInput
                                                    id="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    placeholder="Contoh: budi93@gmail.com"
                                                    size="small"
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.email
                                                        ? errors.email.message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        No. Telepon
                                    </Typography>
                                    <Controller
                                        name="no_telp"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.no_telp)}
                                                sx={{
                                                    maxWidth: "500px",
                                                }}
                                            >
                                                <OutlinedInput
                                                    id="noTelp"
                                                    placeholder="831xxxxxx"
                                                    type="number"
                                                    onWheel={(e) => {
                                                        const target =
                                                            e.target as HTMLButtonElement;
                                                        if (target) {
                                                            target.blur();
                                                            e.stopPropagation();
                                                            setTimeout(() => {
                                                                target.focus();
                                                            }, 0);
                                                        }
                                                    }}
                                                    size="small"
                                                    startAdornment={
                                                        <InputAdornment
                                                            position="start"
                                                            sx={{
                                                                // marginTop:
                                                                //     !isDesktopScreen &&
                                                                //     Boolean(
                                                                //         !errors.email,
                                                                //     )
                                                                //         ? "-2px"
                                                                //         : isDesktopScreen &&
                                                                //           Boolean(
                                                                //               errors.email,
                                                                //           )
                                                                //         ? "-2px"
                                                                //         : undefined,
                                                                marginTop:
                                                                    isDesktopScreen
                                                                        ? "-2px"
                                                                        : undefined,
                                                                color: "#000",
                                                            }}
                                                        >
                                                            <Typography>
                                                                +62
                                                            </Typography>
                                                        </InputAdornment>
                                                    }
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.no_telp
                                                        ? errors.no_telp.message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Typography variant="body2">
                                        Provinsi
                                    </Typography>
                                    <Controller
                                        name="provinsi"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <Autocomplete
                                                disablePortal
                                                disableClearable
                                                loading={isLoadingProvinsi}
                                                options={
                                                    provinsiOptions?.content ??
                                                    []
                                                }
                                                getOptionLabel={(option) =>
                                                    option.nama
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) => option.id === value.id}
                                                value={field.value}
                                                size="small"
                                                onChange={(
                                                    _event,
                                                    newProvinsi: {
                                                        id: number;
                                                        nama: string;
                                                    } | null,
                                                ) => {
                                                    field.onChange(newProvinsi);
                                                    if (newProvinsi) {
                                                        setParamsKota(
                                                            newProvinsi.id,
                                                        );
                                                    } else {
                                                        return;
                                                    }
                                                    setValue("kota", undefined);
                                                }}
                                                fullWidth
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Pilih Provinsi"
                                                        error={
                                                            !!fieldState?.error
                                                        }
                                                        helperText={
                                                            fieldState?.error
                                                                ? "Pilih satu provinsi"
                                                                : " "
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Typography variant="body2">
                                        Kota
                                    </Typography>
                                    <Controller
                                        name="kota"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <Autocomplete
                                                disablePortal
                                                disableClearable
                                                loading={isLoadingKota}
                                                options={
                                                    kotaOptions?.content ?? []
                                                }
                                                getOptionLabel={(option) =>
                                                    option.nama
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) => option.id === value.id}
                                                // inputValue={
                                                //     field.value?.nama ?? ""
                                                // }
                                                value={field.value}
                                                size="small"
                                                onChange={(
                                                    _event,
                                                    newkota: {
                                                        id: number;
                                                        nama: string;
                                                        provinsiId: number;
                                                        provinsiNama: string;
                                                    } | null,
                                                ) => {
                                                    field.onChange(newkota);
                                                }}
                                                fullWidth
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Pilih Kota"
                                                        error={
                                                            !!fieldState?.error
                                                        }
                                                        helperText={
                                                            fieldState?.error
                                                                ? "Pilih satu kota"
                                                                : " "
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        Alamat
                                    </Typography>
                                    <Controller
                                        name="alamat"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.alamat)}
                                                sx={{
                                                    maxWidth: "500px",
                                                }}
                                            >
                                                <OutlinedInput
                                                    id="alamat"
                                                    placeholder="Contoh: Jalan Merdeka Raya no. 45"
                                                    size="small"
                                                    multiline
                                                    rows={3}
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.alamat
                                                        ? errors.alamat.message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Typography variant="body2">
                                        Kata Sandi
                                    </Typography>
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.password)}
                                                sx={{
                                                    maxWidth: "500px",
                                                }}
                                            >
                                                <OutlinedInput
                                                    id="password"
                                                    type={
                                                        showPassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    placeholder="Minimal 8 karakter"
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={
                                                                    handleClickShowPassword
                                                                }
                                                                edge="end"
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    size="small"
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.password
                                                        ? errors.password
                                                              .message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Typography variant="body2">
                                        Konfirmasi Kata Sandi
                                    </Typography>
                                    <Controller
                                        name="passwordUlang"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(
                                                    errors.passwordUlang,
                                                )}
                                                sx={{
                                                    maxWidth: "500px",
                                                }}
                                            >
                                                <OutlinedInput
                                                    id="passwordUlang"
                                                    type={
                                                        showPasswordConfirmation
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    placeholder="Minimal 8 karakter"
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={
                                                                    handleClickShowPasswordConfirmation
                                                                }
                                                                edge="end"
                                                            >
                                                                {showPasswordConfirmation ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    size="small"
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.passwordUlang
                                                        ? errors.passwordUlang
                                                              .message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        Nama Toko
                                    </Typography>
                                    <Controller
                                        name="namaToko"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.namaToko)}
                                                sx={{
                                                    maxWidth: "500px",
                                                }}
                                            >
                                                <OutlinedInput
                                                    id="namaToko"
                                                    placeholder="Contoh: Budi Shop"
                                                    size="small"
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.namaToko
                                                        ? errors.namaToko
                                                              .message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Typography variant="body2">
                                        Kode Referral (Opsional)
                                    </Typography>
                                    <Controller
                                        name="referral"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.referral)}
                                                sx={{
                                                    maxWidth: "500px",
                                                }}
                                            >
                                                <OutlinedInput
                                                    id="referral"
                                                    placeholder="Masukkan kode referral"
                                                    size="small"
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.referral
                                                        ? errors.referral
                                                              .message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <CheckBox
                                    checked={checked}
                                    onChange={handleCheck}
                                />
                                <Typography fontSize={16} fontWeight={400}>
                                    Saya setuju dengan{" "}
                                    <Link
                                        variant="body2"
                                        href="/syarat-ketentuan"
                                        target="blank"
                                        underline="hover"
                                        color="#0A0AE1"
                                        sx={{
                                            textDecoration: "underline",
                                            fontWeight: 600,
                                            fontSize: 16,
                                        }}
                                    >
                                        syarat dan ketentuan
                                    </Link>{" "}
                                    yang berlaku
                                </Typography>
                            </Stack>

                            <Box
                                marginTop={3}
                                display="flex"
                                justifyContent="center"
                            >
                                <ReCAPTCHA
                                    sitekey={captchaSiteKey}
                                    onChange={(value) => {
                                        setToken(value);
                                        setIsHuman(true);
                                    }}
                                    onExpired={() => {
                                        setToken(null);
                                        setIsHuman(false);
                                    }}
                                    hl="id"
                                />
                            </Box>

                            <Button
                                disabled={!checked}
                                type="submit"
                                fullWidth
                                size="large"
                                variant="contained"
                                sx={{ marginTop: 3, maxWidth: "500px" }}
                            >
                                Daftar
                            </Button>
                            <Typography
                                variant="body2"
                                component="div"
                                textAlign="center"
                                sx={{ marginTop: 2 }}
                            >
                                Sudah punya akun?{" "}
                                <Link
                                    component={RouterLink}
                                    to="/"
                                    variant="body2"
                                    underline="hover"
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Masuk
                                </Link>
                            </Typography>
                        </form>
                    </Box>
                </Box>
                <LoadingOverlay open={loading} />
            </Box>
        </ThemeDefaultComponent>
    );
};

export default Register;
