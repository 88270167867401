import React, {
    createContext,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from "react";

interface SelectedProductBundling {
    id: number;
    nama: string;
    harga: number;
    stok: number;
}

interface State {
    closeModalHapusMultipleStok: () => void;
    selectedProducts: SelectedProductBundling[];
    isOpenModalHapusMultipleStok: boolean;
    openModalHapusMultipleStok: (products: SelectedProductBundling[]) => void;
    clearSelectedProducts: () => void;
}

interface IHapusMultipleStokModalContext {
    children: ReactNode;
}

const HapusMultipleStokModalContext = createContext<State | undefined>(undefined);

const HapusMultipleStokModalProvider = ({ children }: IHapusMultipleStokModalContext) => {
    const [isOpenModalHapusMultipleStok, setIsOpenModalHapusMultipleStok] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState<SelectedProductBundling[]>([]);

    const openModalHapusMultipleStok = useCallback((products: SelectedProductBundling[]) => {
        setSelectedProducts(products);
        setIsOpenModalHapusMultipleStok(true);
    }, []);

    const closeModalHapusMultipleStok = useCallback(() => {
        setIsOpenModalHapusMultipleStok(false);
    }, []);

    const clearSelectedProducts = useCallback(() => {
        setSelectedProducts([]);
    }, []);

    const value = useMemo(
        () => ({
            closeModalHapusMultipleStok,
            selectedProducts,
            isOpenModalHapusMultipleStok,
            openModalHapusMultipleStok,
            clearSelectedProducts,
        }),
        [closeModalHapusMultipleStok, selectedProducts, isOpenModalHapusMultipleStok, openModalHapusMultipleStok, clearSelectedProducts]
    );

    return (
        <HapusMultipleStokModalContext.Provider value={value}>
            {children}
        </HapusMultipleStokModalContext.Provider>
    );
};

const useHapusMultipleStokModal = () => {
    const context = React.useContext(HapusMultipleStokModalContext);
    if (context === undefined) {
        throw new Error(
            "useHapusMultipleStokModal must be used within a HapusMultipleStokModalProvider"
        );
    }
    return context;
};

export { HapusMultipleStokModalContext, HapusMultipleStokModalProvider, useHapusMultipleStokModal };
