import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PageLayout from "../layouts/PageLayout";
import Laporan from "../pages/Laporan/Laporan";
import LoginPage from "../pages/LoginPage/LoginPage";
import StokBarang from "../pages/StokBarang/StokBarang";
import Transaksi from "../pages/Transaksi/Transaksi";
import Pengaturan from "../pages/Pengaturan/Pengaturan";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ThemeComponent from "../components/ThemeComponent/ThemeComponent";
import Kategori from "../pages/Kategori/Kategori";
import RegisterPage from "../pages/RegisterPage/RegisterPage";
import BuatTokoPage from "../pages/BuatTokoPage/BuatTokoPage";
import VerifikasiEmailPage from "../pages/VerifikasiEmailPage/VerifikasiEmailPage";
import LupaPassword from "../pages/LupaPassword/LupaPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import Halaman404 from "../pages/Halaman404/Halaman404";
import HalamanStruk from "../pages/HalamanStruk/HalamanStruk";
import PaymentInfoPage from "../pages/Payment/PaymentInfoPage";
import PaymentMethodPage from "../pages/Payment/PaymentMethodPage";
import PaymentVAPage from "../pages/Payment/PaymentVAPage";
import StatusError from "../pages/Payment/StatusError";
import StatusSuccess from "../pages/Payment/StatusSuccess";
import SyaratKetentuanPage from "../pages/SyaratKetentuanPage/SyaratKetentuanPage";

export default function Routes() {
    return (
        <Router>
            <Switch>
                <PrivateRoute path="/pengaturan">
                    <ThemeComponent>
                        <PageLayout>
                            <Pengaturan />
                        </PageLayout>
                    </ThemeComponent>
                </PrivateRoute>
                <PrivateRoute path="/transaksi">
                    <ThemeComponent>
                        <PageLayout>
                            <Transaksi />
                        </PageLayout>
                    </ThemeComponent>
                </PrivateRoute>
                <PrivateRoute path="/stok">
                    <ThemeComponent>
                        <PageLayout>
                            <StokBarang />
                        </PageLayout>
                    </ThemeComponent>
                </PrivateRoute>
                <PrivateRoute path="/kategori">
                    <ThemeComponent>
                        <PageLayout>
                            <Kategori />
                        </PageLayout>
                    </ThemeComponent>
                </PrivateRoute>
                <PrivateRoute path="/laporan">
                    <ThemeComponent>
                        <PageLayout>
                            <Laporan />
                        </PageLayout>
                    </ThemeComponent>
                </PrivateRoute>
                <PrivateRoute path="/buat-ukm">
                    <BuatTokoPage />
                </PrivateRoute>
                <PublicRoute path="/" exact>
                    <LoginPage />
                </PublicRoute>
                <PublicRoute path="/registrasi">
                    <RegisterPage />
                </PublicRoute>
                <PublicRoute path="/verifikasi">
                    <VerifikasiEmailPage />
                </PublicRoute>
                <PublicRoute path="/syarat-ketentuan">
                    <SyaratKetentuanPage />
                </PublicRoute>
                <Route path="/lupa-kata-sandi">
                    <LupaPassword />
                </Route>
                <Route path="/atur-kata-sandi">
                    <ResetPassword />
                </Route>
                <Route path="/struk/:slug">
                    <HalamanStruk />
                </Route>
                <Route path="/payment/:orderId/:layananId/:ukmId" exact>
                    <PaymentInfoPage />
                </Route>
                <Route path="/payment/:orderId/:layananId/:ukmId/metode" exact>
                    <PaymentMethodPage />
                </Route>
                <Route
                    path="/payment/:orderId/:layananId/:ukmId/metode/:type"
                    exact
                >
                    <PaymentVAPage />
                </Route>
                <Route path="/payment/error">
                    <StatusError />
                </Route>
                <Route path="/payment/success">
                    <StatusSuccess />
                </Route>
                <Route path="*">
                    <Halaman404 />
                </Route>
            </Switch>
        </Router>
    );
}
