import React, { Fragment } from "react";
import { Text, Font, View, StyleSheet } from "@react-pdf/renderer";
import toRibuan from "../../utils/toRibuan";

Font.register({
    family: "Open-Sans",
    src: "https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf",
});

Font.register({
    family: "OpenSansBold",
    src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
});

interface ICart {
    id: number;
    namaBarang: string;
    namaKategori: string;
    sku?: string | undefined;
    qty: number;
    harga: number;
    jumlahStok?: number | undefined;
}

interface IPrintStrukA4TableRowProps {
    cart?: ICart[];
}

const styles = StyleSheet.create({
    container: {
        marginBottom: 10,
    },
    header: {
        flexDirection: "row",
        borderTopColor: "#000",
        borderTopWidth: 1,
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        alignItems: "center",
        fontFamily: "OpenSansBold",
        fontSize: 11,
        borderLeftColor: "#000",
        borderLeftWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    headerText: {
        paddingHorizontal: 8,
        height: 24,
        fontFamily: "OpenSansBold",
        fontSize: 11,
    },
    row: {
        flexDirection: "row",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        borderLeftColor: "#000",
        borderLeftWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
        alignItems: "center",
        fontFamily: "Open-Sans",
        fontSize: 11,
    },
    no: {
        width: "5%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 4,
        height: 24,
    },
    kategori: {
        width: "20%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 24,
    },
    description: {
        width: "40%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 24,
    },
    qty: {
        width: "10%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        textAlign: "center",
        height: 24,
    },
    rate: {
        width: "25%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        textAlign: "right",
        height: 24,
    },
    amount: {
        width: "25%",
        paddingHorizontal: 8,
        textAlign: "right",
        height: 24,
    },
});

const PrintStrukA4TableRow = ({ cart }: IPrintStrukA4TableRowProps) => {
    if (!cart || cart.length === 0) {
        return null;
    }

    const rows = cart.map((df, index: number) => (
        <View style={styles.row} key={df.id.toString()}>
            <Text style={styles.no}>{index + 1}</Text>
            <Text style={styles.kategori}>{df.namaKategori}</Text>
            <Text style={styles.description}>{df.namaBarang}</Text>
            <Text style={styles.qty}>{df.qty}</Text>
            <Text style={styles.rate}>{toRibuan(df.harga)}</Text>
            <Text style={styles.amount}>{toRibuan(df.qty * df.harga)}</Text>
        </View>
    ));

    return (
        <Fragment>
            <View style={styles.header}>
                <Text style={[styles.headerText, styles.no]}>NO</Text>
                <Text style={[styles.headerText, styles.kategori]}>NAMA KATEGORI</Text>
                <Text style={[styles.headerText, styles.description]}>NAMA BARANG</Text>
                <Text style={[styles.headerText, styles.qty]}>QTY</Text>
                <Text style={[styles.headerText, styles.rate]}>HARGA SATUAN (Rp)</Text>
                <Text style={[styles.headerText, styles.amount]}>JUMLAH (Rp)</Text>
            </View>
            {rows}
        </Fragment>
    );
};

export default PrintStrukA4TableRow;
