import { useQuery } from "react-query";
import {
    ParamsGetLaporanLabaRugi,
    GetLaporanLabaRugiResponse,
} from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (params: ParamsGetLaporanLabaRugi) => {
    const endpoint = qs.stringifyUrl({
        url: "api/laporanlabarugi",
        query: {
            ukmId: params.ukmId,
            tglawal: params.tglawal,
            tglakhir: params.tglakhir,
        },
    });
    const { data } = await axios.post<GetLaporanLabaRugiResponse>(endpoint);
    return data.data;
};

export default function useLaporanLabaRugi(params: ParamsGetLaporanLabaRugi) {
    return useQuery<GetLaporanLabaRugiResponse["data"]>(
        ["laporanLabaRugi", params],
        () => handleRequest(params),
        {
            enabled: !!params?.ukmId,
        },
    );
}
