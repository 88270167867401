import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState } from "react";
import NoRowsImage from "../NoRowsImage/NoRowsImage";
import LoadingTabel from "../LoadingTabel/LoadingTabel";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTransaksi } from "../../context/transaksiContext";
import { useAuth } from "../../context/authContext";
import { ParamsGetPengeluaran } from "../../constants/types";
import usePengeluaran from "../../services/queries/usePengeluaran";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import toRupiah from "../../utils/toRupiah";
import ModalPengeluaran from "../ModalPengeluaran/ModalPengeluaran";

moment.locales();
moment.locale("id");

interface ICardPengeluaranProps {
    tanggalAwal: string;
    tanggalAkhir: string;
}

const CardPengeluaran = ({
    tanggalAwal,
    tanggalAkhir,
}: ICardPengeluaranProps) => {
    const theme = useTheme();
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));
    let now = moment().format("YYYY/MM/DD");

    const { isFetchingItems, cancelFetchingItems } = useTransaksi();
    const { ukmIdUser } = useAuth();
    const [searchPengeluaran, setSearchPengeluaran] = useState("");
    const [isOpenModalPengeluaran, setIsOpenModalPengeluaran] = useState(false);

    const openModalPengeluaran = () => setIsOpenModalPengeluaran(true);
    const closeModalPengeluaran = () => setIsOpenModalPengeluaran(false);

    const initialParamsPengeluaran = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 10,
        page: 1,
        search: "",
        tanggalAwal: now,
        tanggalAkhir: now,
    };
    const [paramsPengeluaran, setParamsPengeluaran] =
        React.useState<ParamsGetPengeluaran>(initialParamsPengeluaran);
    const {
        data: pengeluaran,
        refetch: refetchPengeluaran,
        isLoading: isLoadingPengeluaran,
    } = usePengeluaran(paramsPengeluaran);

    const handleOnSizeChangePengeluaran = (size: number) => {
        setParamsPengeluaran((prev) => ({ ...prev, size }));
    };

    const handleOnPaginationPengeluaran = (page: number) => {
        setParamsPengeluaran((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const columnsPengeluaran: GridColDef[] = [
        {
            field: "tanggal",
            headerName: "Tanggal Transaksi",
            flex: isLaptopScreen ? 0.4 : undefined,
            minWidth: 160,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            params.value
                                ? moment(params.value).format("LL")
                                : "-"
                        }
                    >
                        <Typography variant="body2">
                            {params.value
                                ? moment(params.value).format("LL")
                                : "-"}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "kode",
            headerName: "Kode",
            flex: isLaptopScreen ? 0.2 : undefined,
            minWidth: 160,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "nama",
            headerName: "Keterangan",
            flex: isLaptopScreen ? 0.8 : undefined,
            minWidth: 200,
            renderCell: (params) => (
                <Tooltip title={params.value ? params.value : "-"}>
                    <span className="table-cell-trucate">
                        {params.value ? params.value : "-"}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "totalNominal",
            headerName: "Total Bayar",
            align: "right",
            flex: isLaptopScreen ? 0.4 : undefined,
            minWidth: 160,
            renderCell: (params) => (
                <Tooltip
                    title={
                        params.value
                            ? toRupiah(Number(params.value))
                            : toRupiah(0)
                    }
                >
                    <span className="table-cell-trucate">
                        {params.value
                            ? toRupiah(Number(params.value))
                            : toRupiah(0)}
                    </span>
                </Tooltip>
            ),
        },
    ];

    useEffect(() => {
        if (ukmIdUser) {
            setParamsPengeluaran((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        if (tanggalAwal || tanggalAkhir) {
            setParamsPengeluaran((prev) => ({
                ...prev,
                tanggalAwal: tanggalAwal,
                tanggalAkhir: tanggalAkhir,
            }));
        }
    }, [tanggalAkhir, tanggalAwal]);

    useEffect(() => {
        if (isFetchingItems) {
            refetchPengeluaran();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchPengeluaran]);

    return (
        <>
            <Card variant="outlined" sx={{ borderRadius: "0 4px 4px 4px" }}>
                <CardContent>
                    <Stack
                        direction={{
                            xs: "column",
                            sm: "row",
                        }}
                        width="100%"
                        display="flex"
                        sx={{
                            alignItems: {
                                xs: "stretch",
                                sm: "center",
                            },
                            justifyContent: {
                                xs: "space-between",
                                sm: "space-between",
                            },
                        }}
                        spacing={2}
                        marginBottom={1}
                    >
                        <Stack
                            direction={{
                                xs: "column",
                                sm: "row",
                            }}
                            sx={{
                                alignItems: {
                                    sx: "normal",
                                    sm: "center",
                                },
                            }}
                            spacing={{ xs: 1, sm: 2 }}
                        >
                            <Typography id="produkTerjual" fontWeight="bold">
                                Atur Pengeluaran
                            </Typography>
                            <Typography variant="subtitle2">
                                {`Periode: ${moment(
                                    paramsPengeluaran.tanggalAwal,
                                ).format("DD/MM/YYYY")} - ${
                                    paramsPengeluaran.tanggalAkhir &&
                                    moment(
                                        paramsPengeluaran.tanggalAkhir,
                                    ).format("DD/MM/YYYY")
                                }`}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <TextField
                            placeholder="Cari..."
                            size="small"
                            value={searchPengeluaran}
                            onChange={(e) =>
                                setSearchPengeluaran(e.target.value)
                            }
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    setParamsPengeluaran((prev) => ({
                                        ...prev,
                                        search: searchPengeluaran,
                                        page: 1,
                                    }));
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <Link
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        onClick={() =>
                                            setParamsPengeluaran((prev) => ({
                                                ...prev,
                                                search: searchPengeluaran,
                                                page: 1,
                                            }))
                                        }
                                        sx={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <SearchIcon fontSize="small" />
                                    </Link>
                                ),
                            }}
                            variant="outlined"
                        />
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={openModalPengeluaran}
                            disableElevation
                        >
                            Tambah
                        </Button>
                    </Stack>
                    <Box
                        marginTop={2}
                        sx={{
                            height: 630,
                            width: "100%",
                            borderRadius: 1,
                            "& .headerColumn": {
                                backgroundColor: "#E4EEE8",
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontWeight: "bold",
                            },
                            "& .MuiDataGrid-columnSeparator": {
                                visibility: "hidden",
                            },
                            "& .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },
                            "& .MuiDataGrid-iconButtonContainer": {
                                visibility: "visible",
                                width: "0 !important",
                            },
                        }}
                    >
                        <DataGrid
                            pagination
                            rows={pengeluaran?.content ?? []}
                            columns={columnsPengeluaran}
                            components={{
                                ColumnUnsortedIcon: () => {
                                    return <ImportExportIcon />;
                                },
                                NoRowsOverlay: NoRowsImage,
                                LoadingOverlay: LoadingTabel,
                            }}
                            rowsPerPageOptions={[10]}
                            disableColumnMenu
                            disableSelectionOnClick
                            paginationMode="server"
                            onPageSizeChange={handleOnSizeChangePengeluaran}
                            onPageChange={handleOnPaginationPengeluaran}
                            rowCount={pengeluaran?.totalElements ?? 0}
                            page={paramsPengeluaran.page - 1}
                            pageSize={paramsPengeluaran.size}
                            loading={isLoadingPengeluaran}
                        />
                    </Box>
                </CardContent>
            </Card>
            <ModalPengeluaran
                isOpenModalPengeluaran={isOpenModalPengeluaran}
                closeModalPengeluaran={closeModalPengeluaran}
                ukmId={ukmIdUser ? String(ukmIdUser[0]) : ""}
            />
        </>
    );
};

export default CardPengeluaran;
