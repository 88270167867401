import { useQuery } from "react-query";
import { ParamsKesimpulan, GetKesimpulanResponse } from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (params: ParamsKesimpulan) => {
    const apiGetKesimpulan = qs.stringifyUrl({
        url: "api/laporan/kesimpulaninvoice",
        query: {
            ukmId: params.ukmId,
            tanggal: params.tanggal,
            grupProduk: params.grupProduk,
        },
    });
    const { data } = await axios.get<GetKesimpulanResponse>(apiGetKesimpulan);
    return data.data;
};

export default function useKesimpulan(params: ParamsKesimpulan) {
    return useQuery<GetKesimpulanResponse["data"]>(
        ["Kesimpulan", params],
        () => handleRequest(params),
        {
            enabled: !!params.ukmId,
        },
    );
}
