import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState } from "react";
import NoRowsImage from "../NoRowsImage/NoRowsImage";
import LoadingTabel from "../LoadingTabel/LoadingTabel";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../../context/authContext";
import {
    ContentGetMutasiStok,
    Keterangan,
    ParamsGetMutasiStok,
} from "../../constants/types";
import { useTransaksi } from "../../context/transaksiContext";
import useMutasiStok from "../../services/queries/useMutasiStok";
import SearchIcon from "@mui/icons-material/Search";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";
import truncate from "../../utils/truncate";
import toRibuan from "../../utils/toRibuan";
import toRupiah from "../../utils/toRupiah";
import Button from "@mui/material/Button";
import ModalDetailMutasi from "../ModalDetailMutasi/ModalDetailMutasi";

moment.locales();
moment.locale("id");

interface DetailMutasi {
    namaBarang: string;
    sku: string;
    stokAwal: number;
    hargaBarang: number;
    masuk: number;
    keluar: number;
    stokAkhir: number;
}

interface ICardMutasiStokProps {
    tanggalAwal: string;
    tanggalAkhir: string;
}

const CardMutasiStok = ({
    tanggalAwal,
    tanggalAkhir,
}: ICardMutasiStokProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    let now = moment().format("YYYY/MM/DD");

    const { isFetchingItems, cancelFetchingItems } = useTransaksi();
    const { ukmIdUser } = useAuth();
    const [searchMutasiStok, setSearchMutasiStok] = useState("");
    const [keteranganMasuk, setKeteranganMasuk] = useState<Keterangan[]>([]);
    const [keteranganKeluar, setKeteranganKeluar] = useState<Keterangan[]>([]);
    const initialDetailMutasi = {
        namaBarang: "",
        sku: "",
        stokAwal: 0,
        hargaBarang: 0,
        masuk: 0,
        keluar: 0,
        stokAkhir: 0,
    };
    const [detailMutasi, setDetailMutasi] =
        useState<DetailMutasi>(initialDetailMutasi);
    const [isOpenModalDetailMutasi, setIsOpenModalDetailMutasi] =
        useState(false);

    const openModalDetailMutasi = (
        detail: DetailMutasi,
        keteranganMasuk: Keterangan[],
        keteranganKeluar: Keterangan[],
    ) => {
        setDetailMutasi(detail);
        setKeteranganMasuk(keteranganMasuk);
        setKeteranganKeluar(keteranganKeluar);
        setIsOpenModalDetailMutasi(true);
    };
    const closeModalDetailMutasi = () => setIsOpenModalDetailMutasi(false);

    const initialParamsMutasiStok = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 10,
        page: 1,
        tanggalAwal: now,
        tanggalAkhir: now,
    };

    const [paramsMutasiStok, setParamsMutasiStok] =
        useState<ParamsGetMutasiStok>(initialParamsMutasiStok);

    const {
        data: mutasiStok,
        refetch: refetchMutasiStok,
        isLoading: isLoadingMutasiStok,
    } = useMutasiStok(paramsMutasiStok);

    const handleOnPaginationMutasiStok = (page: number) => {
        setParamsMutasiStok((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChangeMutasiStok = (size: number) => {
        setParamsMutasiStok((prev) => ({ ...prev, size }));
    };

    const columnsMutasiStok: GridColDef[] = [
        {
            field: "namaBarang",
            headerName: "Info Barang",
            headerAlign: "center",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 155,
            valueGetter: (params) => params.value,
            renderCell: (params) => {
                return (
                    <Stack direction="column">
                        <Tooltip title={params.value}>
                            <Typography variant="subtitle2" fontWeight="bold">
                                {truncate(params.value, 20)}
                            </Typography>
                        </Tooltip>
                        <Tooltip title={params.row.sku}>
                            <Typography variant="caption">
                                {truncate(params.row.sku, 20)}
                            </Typography>
                        </Tooltip>
                    </Stack>
                );
            },
        },
        {
            field: "stokAwal",
            headerName: "Stok Awal",
            headerAlign: "center",
            align: "center",
            type: "number",
            flex: isPhoneScreen ? undefined : 0.5,
            minWidth: 150,
            valueGetter: (params) =>
                params.value === "Otomatis"
                    ? params.value
                    : toRibuan(params.value),
            renderCell: (params) => (
                <Tooltip
                    title={
                        params.value === "Otomatis"
                            ? params.value
                            : toRibuan(params.value)
                    }
                >
                    <span className="table-cell-trucate">
                        {params.value === "Otomatis"
                            ? params.value
                            : toRibuan(params.value)}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "hargaBarang",
            headerName: "Harga",
            headerAlign: "center",
            align: "right",
            type: "number",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 150,
            valueGetter: (params) => params.value,
            renderCell: (params) => (
                <Tooltip title={toRupiah(params.value)}>
                    <span className="table-cell-trucate">
                        {toRupiah(params.value)}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "masuk",
            headerName: "Mutasi In",
            headerAlign: "center",
            align: "center",
            type: "number",
            flex: isPhoneScreen ? undefined : 0.5,
            minWidth: 155,
            valueGetter: (params) =>
                params.value === 0 ? "-" : toRibuan(params.value),
            renderCell: (params) => (
                <Tooltip
                    title={params.value === 0 ? "-" : toRibuan(params.value)}
                >
                    <span className="table-cell-trucate">
                        {params.value === 0 ? "-" : toRibuan(params.value)}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "keluar",
            headerName: "Mutasi Out",
            headerAlign: "center",
            align: "center",
            type: "number",
            flex: isPhoneScreen ? undefined : 0.5,
            minWidth: 155,
            valueGetter: (params) =>
                params.value === 0 ? "-" : toRibuan(params.value),
            renderCell: (params) => (
                <Tooltip
                    title={params.value === 0 ? "-" : toRibuan(params.value)}
                >
                    <span className="table-cell-trucate">
                        {params.value === 0 ? "-" : toRibuan(params.value)}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "stokAkhir",
            headerName: "Stok Akhir",
            headerAlign: "center",
            align: "center",
            flex: isPhoneScreen ? undefined : 0.5,
            minWidth: 150,
            valueGetter: (params) =>
                params.value === "Otomatis"
                    ? params.value
                    : toRibuan(params.value),
            renderCell: (params) => (
                <Tooltip
                    title={
                        params.value === "Otomatis"
                            ? params.value
                            : toRibuan(params.value)
                    }
                >
                    <span className="table-cell-trucate">
                        {params.value === "Otomatis"
                            ? params.value
                            : toRibuan(params.value)}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "aksi",
            headerName: "",
            headerAlign: "center",
            minWidth: 160,
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            renderCell: (params) => {
                const row: ContentGetMutasiStok = params.row;
                return (
                    <Button
                        variant="contained"
                        color="buttongreen"
                        onClick={() => {
                            openModalDetailMutasi(
                                {
                                    namaBarang: row.namaBarang,
                                    sku: row.sku,
                                    stokAwal: row.stokAwal,
                                    hargaBarang: row.hargaBarang,
                                    masuk: row.masuk,
                                    keluar: row.keluar,
                                    stokAkhir: row.stokAkhir,
                                },
                                row.keteranganMasuk,
                                row.keteranganKeluar,
                            );
                        }}
                        size="small"
                        disableElevation
                        sx={{
                            minWidth: "unset",
                            padding: "8px",
                            width: "32px",
                            height: "32px",
                        }}
                    >
                        <VisibilityIcon
                            sx={{ width: "16px", height: "16px" }}
                        />
                    </Button>
                );
            },
        },
    ];

    useEffect(() => {
        if (ukmIdUser) {
            setParamsMutasiStok((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        if (tanggalAwal || tanggalAkhir) {
            setParamsMutasiStok((prev) => ({
                ...prev,
                tanggalAwal: tanggalAwal,
                tanggalAkhir: tanggalAkhir,
            }));
        }
    }, [tanggalAkhir, tanggalAwal]);

    useEffect(() => {
        if (isFetchingItems) {
            refetchMutasiStok();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchMutasiStok]);

    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    borderRadius: "0 4px 4px 4px",
                }}
            >
                <CardContent>
                    <Stack
                        direction={{
                            xs: "column",
                            sm: "row",
                        }}
                        width="100%"
                        display="flex"
                        sx={{
                            alignItems: {
                                xs: "stretch",
                                sm: "center",
                            },
                            justifyContent: {
                                xs: "space-between",
                                sm: "space-between",
                            },
                        }}
                        spacing={2}
                    >
                        <Stack
                            direction={{
                                xs: "column",
                                sm: "row",
                            }}
                            sx={{
                                alignItems: {
                                    sx: "normal",
                                    sm: "center",
                                },
                            }}
                            spacing={{
                                xs: 1,
                                sm: 2,
                            }}
                        >
                            <Typography id="mutasiStok" fontWeight="bold">
                                Mutasi Stok
                            </Typography>
                            <Typography variant="subtitle2">
                                {`Periode: ${moment(
                                    paramsMutasiStok.tanggalAwal,
                                ).format("DD/MM/YYYY")} - ${
                                    paramsMutasiStok.tanggalAkhir &&
                                    moment(
                                        paramsMutasiStok.tanggalAkhir,
                                    ).format("DD/MM/YYYY")
                                }`}
                            </Typography>
                        </Stack>
                        <TextField
                            placeholder="Cari barang mutasi..."
                            size="small"
                            value={searchMutasiStok}
                            onChange={(e) =>
                                setSearchMutasiStok(e.target.value)
                            }
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    setParamsMutasiStok((prev) => ({
                                        ...prev,
                                        search: searchMutasiStok,
                                        page: 1,
                                    }));
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <Link
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        onClick={() =>
                                            setParamsMutasiStok((prev) => ({
                                                ...prev,
                                                search: searchMutasiStok,
                                                page: 1,
                                            }))
                                        }
                                        sx={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <SearchIcon fontSize="small" />{" "}
                                    </Link>
                                ),
                            }}
                            variant="outlined"
                        />
                    </Stack>
                    <Box
                        marginTop={2}
                        sx={{
                            height: 630,
                            width: "100%",
                            borderRadius: 1,
                            "& .headerColumn": {
                                backgroundColor: "#E4EEE8",
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontWeight: "bold",
                            },
                            "& .MuiDataGrid-columnSeparator": {
                                visibility: "hidden",
                            },
                            "& .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },
                            "& .MuiDataGrid-iconButtonContainer": {
                                visibility: "visible",
                                width: "0 !important",
                            },
                        }}
                    >
                        {/* <ThemeProvider theme={theme}> */}
                        <DataGrid
                            pagination
                            rows={mutasiStok?.content ?? []}
                            getRowId={(row) => row.itemId}
                            columns={columnsMutasiStok}
                            components={{
                                ColumnUnsortedIcon: () => {
                                    return <ImportExportIcon />;
                                },
                                NoRowsOverlay: NoRowsImage,
                                LoadingOverlay: LoadingTabel,
                            }}
                            rowsPerPageOptions={[10]}
                            disableColumnMenu
                            disableSelectionOnClick
                            paginationMode="server"
                            onPageSizeChange={handleOnSizeChangeMutasiStok}
                            onPageChange={handleOnPaginationMutasiStok}
                            rowCount={mutasiStok?.totalElements ?? 0}
                            page={paramsMutasiStok.page - 1}
                            pageSize={paramsMutasiStok.size}
                            loading={isLoadingMutasiStok}
                        />
                        {/* </ThemeProvider> */}
                    </Box>
                </CardContent>
            </Card>
            <ModalDetailMutasi
                fullScreen={fullScreen}
                isOpenModalDetailMutasi={isOpenModalDetailMutasi}
                closeModalDetailMutasi={closeModalDetailMutasi}
                keteranganMasuk={keteranganMasuk}
                keteranganKeluar={keteranganKeluar}
                detailMutasi={detailMutasi}
            />
        </>
    );
};

export default CardMutasiStok;
