import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import defaultAxios, { AxiosError } from "axios";
import id from "date-fns/locale/id";
import moment from "moment";
import "moment/locale/id";
import { useEffect, useMemo, useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import * as yup from "yup";
import { ErrorFieldResponse, PengeluaranValues } from "../../constants/types";
import axios from "../../services/axios";
import formatHarga from "../../utils/formatHarga";
import { useQueryClient } from "react-query";

moment.locales();
moment.locale("id");
registerLocale("id", id);
setDefaultLocale("id");

interface IModalPengeluaranProps {
    isOpenModalPengeluaran: boolean;
    closeModalPengeluaran: () => void;
    ukmId: string;
}

const schema = yup.object().shape({
    ukmId: yup.string().required("Kolom wajib diisi"),
    keterangan: yup.string().required("Kolom wajib diisi"),
    totalNominal: yup.string().required("Kolom wajib diisi"),
    isKodeManual: yup.boolean(),
    kode: yup.string().nullable(),
});

const ModalPengeluaran = ({
    isOpenModalPengeluaran,
    closeModalPengeluaran,
    ukmId,
}: IModalPengeluaranProps) => {
    const queryClient = useQueryClient();
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const defaultKode = useMemo(
        () => `BYR/${moment().format("YYMMDDHHmmss")}`,
        [isOpenModalPengeluaran], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const initialValues: PengeluaranValues = useMemo(
        () => ({
            ukmId: ukmId,
            tanggal: new Date(),
            keterangan: "",
            totalNominal: "",
            isKodeManual: false,
            kode: defaultKode,
        }),
        [defaultKode], // eslint-disable-line react-hooks/exhaustive-deps
    );
    const { handleSubmit, control, reset, setError } =
        useForm<PengeluaranValues>({
            resolver: yupResolver(schema),
            defaultValues: initialValues,
        });

    const onSubmit = async (values: PengeluaranValues) => {
        setIsButtonLoading(true);
        try {
            const body = {
                ukmId,
                tanggal: values.tanggal
                    ? moment(values.tanggal).format("DD/MM/YYYY")
                    : null,
                nama: values.keterangan,
                totalNominal: formatHarga(String(values.totalNominal)),
                kode: values.kode ?? undefined,
            };
            console.log(body);
            const { data } = await axios.post(`/api/biaya`, body);
            if (data.code === 200) {
                Swal.fire({
                    title: "Pengeluaran telah ditambahkan",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                closeModalPengeluaran();
                reset(initialValues);
            }
            setIsButtonLoading(false);
            queryClient.invalidateQueries("pengeluaran");
            queryClient.invalidateQueries("laporanLabaRugi");
        } catch (error) {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.errors) {
                        Object.keys(fieldError.errors).forEach((key) => {
                            const errorMessage = fieldError.errors[key];
                            setError(key as keyof typeof initialValues, {
                                type: "manual",
                                message: errorMessage[0],
                            });
                        });
                    } else {
                        Swal.fire({
                            title: `${
                                fieldError?.message ?? "Terjadi Kesalahan!"
                            }`,
                            position: "top-end",
                            showConfirmButton: false,
                            icon: "error",
                            toast: true,
                            timer: 3000,
                            timerProgressBar: true,
                            showCloseButton: true,
                            customClass: {
                                container: "my-swal",
                            },
                        });
                    }
                }
            }
            console.error(error);
            setIsButtonLoading(false);
        }
    };

    useEffect(() => {
        if (isOpenModalPengeluaran) {
            reset(initialValues);
        }
    }, [initialValues, isOpenModalPengeluaran, reset]);

    return (
        <Dialog
            maxWidth="md"
            fullWidth={true}
            open={isOpenModalPengeluaran}
            onClose={closeModalPengeluaran}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalPengeluaran}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Tambah Pengeluaran
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    container
                    spacing={2}
                    alignItems="flex-start"
                    marginBottom={2}
                >
                    <Grid item xs={12} sm={5}>
                        <Typography fontWeight={500} component="div">
                            Tanggal:
                        </Typography>
                        <Controller
                            name="tanggal"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    className="range-filter"
                                    dateFormat="dd/MM/yyyy"
                                    popperPlacement="top-start"
                                    disabledKeyboardNavigation
                                    onFocus={(e) => e.target.blur()}
                                    startDate={field.value}
                                    onChange={(update) => {
                                        field.onChange(update);
                                    }}
                                    monthsShown={1}
                                    customInput={
                                        <Stack
                                            display="flex"
                                            justifyContent="space-between"
                                            direction="row"
                                            bgcolor="#FFFF"
                                            height="40px"
                                            sx={{
                                                border: "1px solid #cbcbcb",
                                                borderRadius: "4px",
                                                alignItems: "center",
                                                padding: 2,
                                                cursor: "pointer",
                                            }}
                                        >
                                            <Typography>
                                                {field.value?.toLocaleString(
                                                    "id",
                                                    {
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "numeric",
                                                    },
                                                )}
                                            </Typography>
                                            <ArrowDropDown
                                                sx={{
                                                    marginRight: "-9px",
                                                }}
                                            />
                                        </Stack>
                                    }
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="flex-end"
                    columnSpacing={2}
                    marginBottom={2}
                >
                    <Grid item xs={12} sm={9}>
                        <Typography fontWeight={500} component="div">
                            Kode Pengeluaran
                        </Typography>
                        <Controller
                            name="kode"
                            control={control}
                            render={({ field, fieldState }) => (
                                <TextField
                                    // margin="dense"
                                    id={"kode"}
                                    placeholder="BYR/2023/001"
                                    variant="outlined"
                                    size="small"
                                    error={!!fieldState?.error}
                                    helperText={
                                        fieldState?.error &&
                                        fieldState?.error.message
                                    }
                                    inputProps={{ maxLength: 16 }}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    alignItems="flex-start"
                    marginBottom={2}
                >
                    <Grid item xs={12} sm={5}>
                        <Typography fontWeight={500} component="div">
                            Nominal Pengeluaran
                        </Typography>
                        <Controller
                            name={"totalNominal"}
                            control={control}
                            render={({ field, fieldState }) => (
                                <NumberFormat
                                    customInput={TextField}
                                    placeholder="300.000"
                                    // margin="dense"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={!!fieldState?.error}
                                    helperText={
                                        fieldState?.error &&
                                        fieldState?.error.message
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>Rp.</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    alignItems="flex-start"
                    marginBottom={2}
                >
                    <Grid item xs={12} sm={10}>
                        <Typography fontWeight={500} component="div">
                            Keterangan
                        </Typography>
                        <Controller
                            name={"keterangan"}
                            control={control}
                            render={({ field, fieldState }) => (
                                <TextField
                                    id={"keterangan"}
                                    placeholder="Contoh: Bayar listrik"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    error={!!fieldState?.error}
                                    helperText={
                                        fieldState?.error &&
                                        fieldState?.error.message
                                    }
                                    inputProps={{ maxLength: 50 }}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ textAlign: "right", marginTop: 12 }}>
                    <LoadingButton
                        loading={isButtonLoading}
                        loadingPosition="center"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                        sx={{ width: "275px" }}
                    >
                        Simpan
                    </LoadingButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ModalPengeluaran;
