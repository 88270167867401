import React, { useCallback, useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
// import useItem from "../../services/queries/useItem";
import toRibuan from "../../utils/toRibuan";
import defaultAxios, { AxiosError } from "axios";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import { BlobProvider } from "@react-pdf/renderer";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    Box,
    Dialog,
    DialogTitle,
    Grid,
    Stack,
    Typography,
    IconButton,
    DialogContent,
    FormControlLabel,
    Checkbox,
    Button,
    Autocomplete,
    TextField,
    Chip,
    Link,
    InputAdornment,
    ToggleButtonGroup,
    ToggleButton,
    Tooltip,
} from "@mui/material";
import {
    IFaktur,
    // InputPembayaranBody,
    InputPembayaranResponse,
    IOptions,
    // ParamsGetItem,
    ParamsGetUkm,
    TipeStruk,
    DaftarKategoriListInvoice,
} from "../../constants/types";
import PrintStrukPdf from "../PrintStruk/PrintStrukPdf";
import useMetodePembayaran from "../../services/queries/useMetodePembayaran";
import ModalAlertPro from "../ModalAlertPro/ModalAlertPro";
import useFindMetodePembayaran from "../../services/queries/useFindMetodePembayaran";
import { Print } from "@mui/icons-material";
import useFindCustomStruk from "../../services/queries/useFindCustomStruk";
import { IconContext } from "react-icons";
import { GiMoneyStack, GiWallet } from "react-icons/gi";
import moment from "moment";
import PrintStrukA4 from "../PrintStrukA4/PrintStrukA4";
import truncate from "../../utils/truncate";
// import printJS from "print-js-updated";

import useListInvoice from "../../services/queries/useListInvoice";

interface IModalKonfirmasiBayarProps {
    refetch: () => void;
    persentaseDiskon?: number | null;
    diskon?: number;
    persentasePajak?: number | null;
    pajak?: number;
    totalTagihan: number;
    uangDibayar: number;
    kembalian: number;
    kasir: string;
    sales: IOptions | null;
    keteranganBeli: string;
    resetKeteranganBeli: () => void;
}

interface CartItem {
    namaKategori: string;
    namaBarang: string;
    harga: number;
    qty: number;
}

enum ModePembayaran {
    TUNAI = "tunai",
    TRANSFER = "transfer",
}

type ToggleKirimStruk = "email" | "whatsapp";

export const ModalKonfirmasiBayar = (props: IModalKonfirmasiBayarProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    let now = moment().format("DD MMM YYYY HH:mm");

    const { ukmIdUser } = useAuth();
    const { data: user } = useUser();

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };

    const [paramsUkm, setParamsUkm] =
        React.useState<ParamsGetUkm>(initialParamsUkm);
    const { data: ukm } = useUkm(paramsUkm);

    // const initialParams = {
    //     ukmId: ukmIdUser && ukmIdUser[0],
    //     size: 500,
    //     page: 1,
    //     search: "",
    //     kategori: "",
    // };

    const [printStruk, setPrintStruk] = useState(false);
    const [kirimEmail, setKirimEmail] = useState(false);
    const [email, setEmail] = useState("");
    // const [paramsItem, setParamsItem] = useState<ParamsGetItem>(initialParams);
    const [totalHargaSeluruhItem, setTotalHargaSeluruhItem] = useState(0);
    // const { refetch: refetchItem } = useItem(paramsItem);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [toggleModePembayaran, setToggleModePembayaran] = useState(
        ModePembayaran.TUNAI,
    );
    const [selectedMetode, setSelectedMetode] = useState<IOptions | null>(null);
    const [isOpenModalAlertPro, setIsOpenModalAlertPro] = useState(false);
    const [toggleKirimStruk, setToggleKirimStruk] =
        useState<ToggleKirimStruk>("whatsapp");
    const [tipeStruk, setTipeStruk] = useState<string | null>(
        localStorage.getItem("tipeStrukBayar") ?? TipeStruk.Mobile,
    );
    const [tipeStrukTagihan, setTipeStrukTagihan] = useState<string | null>(
        localStorage.getItem("tipeStrukInvoice") ?? TipeStruk.Mobile,
    );

    const handleTipeStruk = (
        _event: React.MouseEvent<HTMLElement>,
        newTipeStruk: TipeStruk | null,
    ) => {
        if (newTipeStruk !== null) {
            setTipeStruk(newTipeStruk);
            localStorage.setItem("tipeStrukBayar", newTipeStruk);
        }
    };

    const handleTipeStrukTagihan = (
        _event: React.MouseEvent<HTMLElement>,
        newTipeStruk: TipeStruk | null,
    ) => {
        if (newTipeStruk !== null) {
            setTipeStrukTagihan(newTipeStruk);
            localStorage.setItem("tipeStrukInvoice", newTipeStruk);
        }
    };

    const pilihWhatsapp = () => {
        setToggleKirimStruk("whatsapp");
        setEmail("");
    };

    const pilihEmail = () => {
        setToggleKirimStruk("email");
        setEmail("");
    };

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const { data: metodePembayaran, isLoading: isLoadingMetodePembayaran } =
        useMetodePembayaran(ukmId);

    const {
        data: findMetodePembayaran,
        isLoading: isLoadingFindMetodePembayaran,
    } = useFindMetodePembayaran(Number(selectedMetode?.id));

    const mappedMetodePembayaran = metodePembayaran?.map((li) => ({
        id: li.id,
        nama: li.namaMetode,
    }));

    const { data: findCustomStruk } = useFindCustomStruk(Number(ukmId));

    const initialParamsListInvoice = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 100,
        page: 1,
        tanggalAwal: now,
        tanggalAkhir: now,
        kasir: "",
        sales: "",
        metodeId: "",
        voidInvoice: "",
    };

    const { data: listInvoice, refetch: refetchListInvoice } = useListInvoice(
        initialParamsListInvoice,
    );

    const transaksiKe =
        (Number(listInvoice?.content?.[0]?.transaksiKe) || 0) + 1;

    console.log("Data transaksiKeBaru:", transaksiKe);

    useEffect(
        () => console.log("metodePembayaran", metodePembayaran),
        [metodePembayaran],
    );

    const pilihTunai = () => {
        setToggleModePembayaran(ModePembayaran.TUNAI);
    };

    const pilihTransfer = () => {
        setToggleModePembayaran(ModePembayaran.TRANSFER);
    };

    const {
        cart,
        totalHarga,
        isOpenModalKonfirmasiBayar,
        closeModalBayar,
        closeModalKonfirmasiBayar,
        // resetCart,
        handleReset,
        // isPrintStruk,
        // resetPrint,
        setFetchingItems,
        handlingDonePembayaran,
        // diskon,
        // grandTotal,
        // bayar,
        // kembalian,
        checked,
        setChecked,
        // keteranganBeli,
        handleIsKeterangan,
        // resetKeteranganBeli,
    } = useTransaksi();

    const current = new Date();
    current.getSeconds();

    const handleHitungTotalHargaSeluruhItem = useCallback(() => {
        let total = 0;
        if (cart) {
            cart.forEach((list) => {
                total += list.harga * list.qty ?? 0;
            });
            setTotalHargaSeluruhItem(total);
            return;
        }
    }, [cart]);

    useEffect(() => {
        handleHitungTotalHargaSeluruhItem();
    }, [cart, handleHitungTotalHargaSeluruhItem]);

    const handlePrintStruk = (checked: boolean) => {
        if (checked) {
            setPrintStruk(true);
        } else {
            setPrintStruk(false);
        }
    };

    const handleKirimEmail = (checked: boolean) => {
        if (checked) {
            setEmail("");
            setKirimEmail(true);
        } else {
            setKirimEmail(false);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("printStruk") === "true") {
            setPrintStruk(true);
        } else {
            setPrintStruk(false);
        }
    }, []);

    const filterNewKodeFaktur = (idBundling: number) => {
        let kodeFakturBundling: IFaktur[] = [];
        cart.forEach((li) =>
            li.detailBundling
                .filter((fd) => fd.bundlingId === idBundling)
                .forEach((db) => {
                    if (db.newFaktur) {
                        db.newFaktur.forEach((nf) => {
                            kodeFakturBundling.push(nf);
                        });
                    }
                }),
        );
        return kodeFakturBundling.map((li) => ({
            idFaktur: li.fakturId,
            kodeFaktur: li.kodeFaktur,
            jumlahItem: li.jumlahItem,
        }));
    };

    const [groupByKategori, setGroupByKategori] = useState(false);

    const handleCheckboxKategori = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setGroupByKategori(event.target.checked);
    };

    const inputPembayaran = async () => {
        setIsButtonLoading(true);
        const body = {
            ukmId: ukmIdUser && ukmIdUser[0],
            jenisItem: cart.map((li) => ({
                itemId: li.id,
                namaItem: li.namaBarang,
                namaKategori: li.namaKategori,
                skuItem: li.sku,
                jumlahItem: li.qty,
                hargaSatuan: li.harga,
                hargaTotal: li.harga * li.qty,
                kodeFaktur: checked
                    ? li.detailBundling.length > 0
                        ? filterNewKodeFaktur(li.id)
                        : li.faktur.map((fa) => ({
                              idFaktur: fa.fakturId,
                              kodeFaktur: fa.kodeFaktur,
                              jumlahItem: fa.jumlahItem,
                          }))
                    : null,
            })),
            userInput: `${user?.userId}`,
            sales: !!props.sales ? props.sales.id : null,
            totalHarga: totalHargaSeluruhItem,
            diskon: props.diskon,
            pajak: props.pajak,
            pajakPersen: ukmData?.pajak,
            totalPembayaran: props.totalTagihan,
            uangYangDibayar: props.uangDibayar,
            kembalian: props.kembalian,
            metodeId:
                toggleModePembayaran === ModePembayaran.TUNAI
                    ? 0
                    : selectedMetode?.id,
            email: toggleKirimStruk === "email" ? email : null,
            keterangan: !!props.keteranganBeli ? props.keteranganBeli : null,
            charge: findMetodePembayaran
                ? findMetodePembayaran.charge.charge
                : null,
            tipeCharge: findMetodePembayaran
                ? findMetodePembayaran.charge.mode
                : null,
        };

        const params = {
            grupProduk: groupByKategori ? 1 : 0,
        };

        try {
            const { data } = await axios.post<InputPembayaranResponse>(
                "/api/pembayaran",
                body,
                { params },
            );
            if (data.code === 200) {
                // setTransaksiKe(String(data.data.transaksiKe));
                console.log("Data transaksiKe:", String(data.data.transaksiKe));
                if (kirimEmail && toggleKirimStruk === "whatsapp") {
                    window.open(
                        `https://wa.me/62${email}?text=Struk%20pembayaran%20ULO%20${data.data.nomorInvoice}%20${window.location.origin}/struk/${data.data.encrypt}`,
                        "_blank",
                    );
                }
                closeModalKonfirmasiBayar();
                closeModalBayar();
                Swal.fire({
                    title: "Transaksi berhasil!",
                    text: "Pembayaran Anda telah berhasil",
                    icon: "success",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });
                if (printStruk) {
                    localStorage.setItem("printStruk", "true");
                } else {
                    localStorage.removeItem("printStruk");
                }
                setFetchingItems();
                handleReset();
                setChecked(false);
                setIsButtonLoading(false);
                handleIsKeterangan(false);
                props.resetKeteranganBeli();
                handlingDonePembayaran();
            }
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
        }
    };

    const handleErrorResponse = useCallback(
        (error) => {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<any>;
                if (serverError && serverError.response) {
                    closeModalKonfirmasiBayar();
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.data}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalBayar();
                } else {
                    closeModalKonfirmasiBayar();
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `Telah Terjadi Kesalahan`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalBayar();
                }
            }
        },
        [closeModalBayar, closeModalKonfirmasiBayar],
    );

    // useEffect(() => {
    //     if (ukmIdUser) {
    //         setParamsItem((prev) => ({
    //             ...prev,
    //             ukmId: ukmIdUser && ukmIdUser[0],
    //         }));
    //     }
    // }, [ukmIdUser]);

    React.useEffect(() => {
        if (user && user?.ukmId?.length > 0) {
            setParamsUkm((prev) => ({
                ...prev,
                userId: user.ukmId[0],
            }));
        }
    }, [user]);

    // useEffect(() => {
    //     if (isPrintStruk) {
    //         inputPembayaran();
    //         resetPrint();
    //     }
    // }, [inputPembayaran, isPrintStruk, resetPrint]);

    // if (status === "loading") {
    //     return (
    //         <Box position="relative">
    //             <Spinner />
    //         </Box>
    //     );
    // }

    const groupItemsByCategory = (items: CartItem[]) => {
        return items.reduce((acc, item) => {
            if (!acc[item.namaKategori]) {
                acc[item.namaKategori] = [];
            }
            acc[item.namaKategori].push(item);
            return acc;
        }, {} as { [key: string]: CartItem[] });
    };

    const groupedCartItems = groupItemsByCategory(cart as CartItem[]);

    const categoryData: DaftarKategoriListInvoice[] = [
        // Array of objects conforming to DaftarKategoriListInvoice structure
    ];

    return (
        <Box position="relative">
            <Dialog
                maxWidth="lg"
                fullWidth
                fullScreen={fullScreen || isTabletScreen}
                open={isOpenModalKonfirmasiBayar}
                onClose={() => {
                    closeModalKonfirmasiBayar();
                }}
                scroll="paper"
                PaperProps={{
                    sx: {
                        borderRadius: 1,
                    },
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton onClick={closeModalKonfirmasiBayar}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" fontWeight="bold">
                            Konfirmasi Pembayaran
                        </Typography>
                    </Stack>
                </DialogTitle>
                {/* <Divider flexItem /> */}
                <DialogContent
                    sx={{
                        paddingLeft: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: fullScreen ? "250px" : 0,
                    }}
                >
                    <Stack direction={{ xs: "column-reverse", md: "row" }}>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            paddingX={3}
                            paddingBottom={4}
                            height={
                                fullScreen
                                    ? undefined
                                    : isTabletScreen
                                    ? "calc(100vh - 72px)"
                                    : "calc(90vh - 72px)"
                            }
                            sx={{ overflowY: fullScreen ? undefined : "auto" }}
                        >
                            <Box
                                padding={2}
                                border={1}
                                borderColor="#d4d4d4"
                                borderRadius={2}
                                marginBottom={2}
                            >
                                <Typography
                                    variant="subtitle1"
                                    color="#000000"
                                    fontWeight="bold"
                                    marginBottom={2}
                                >
                                    Metode Pembayaran
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            fullWidth
                                            variant={
                                                toggleModePembayaran ===
                                                ModePembayaran.TUNAI
                                                    ? "contained"
                                                    : "outlined"
                                            }
                                            onClick={pilihTunai}
                                            sx={{ borderRadius: 4 }}
                                        >
                                            <Stack alignItems="center">
                                                <IconContext.Provider
                                                    value={{
                                                        size: "56px",
                                                    }}
                                                >
                                                    <GiMoneyStack />
                                                </IconContext.Provider>
                                                Tunai
                                            </Stack>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Stack
                                            component={
                                                ukmData?.isPro === 0
                                                    ? Link
                                                    : Stack
                                            }
                                            underline="none"
                                            onClick={() => {
                                                if (ukmData?.isPro === 1) {
                                                    return;
                                                } else {
                                                    setIsOpenModalAlertPro(
                                                        true,
                                                    );
                                                }
                                            }}
                                            sx={{
                                                cursor:
                                                    ukmData?.isPro === 0
                                                        ? "pointer"
                                                        : undefined,
                                            }}
                                        >
                                            <Button
                                                // disabled={
                                                //     ukmData?.isPro !== 1 ||
                                                //     metodePembayaran?.length === 0
                                                // }
                                                fullWidth
                                                variant={
                                                    toggleModePembayaran ===
                                                    ModePembayaran.TRANSFER
                                                        ? "contained"
                                                        : "outlined"
                                                }
                                                onClick={() => {
                                                    if (ukmData?.isPro === 1) {
                                                        pilihTransfer();
                                                    }
                                                }}
                                                sx={{ borderRadius: 4 }}
                                            >
                                                <Stack alignItems="center">
                                                    <IconContext.Provider
                                                        value={{
                                                            size: "56px",
                                                        }}
                                                    >
                                                        <GiWallet />
                                                    </IconContext.Provider>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        gap={1}
                                                    >
                                                        Metode lain{" "}
                                                        {ukmData?.isPro ===
                                                        1 ? null : (
                                                            <Chip
                                                                label="PRO"
                                                                size="small"
                                                            />
                                                        )}
                                                    </Box>
                                                </Stack>
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                            {toggleModePembayaran === ModePembayaran.TRANSFER &&
                            metodePembayaran?.length !== 0 ? (
                                <Box>
                                    <Autocomplete
                                        loading={isLoadingMetodePembayaran}
                                        options={mappedMetodePembayaran ?? []}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Pilih metode"
                                                variant="outlined"
                                            />
                                        )}
                                        getOptionLabel={(option) => option.nama}
                                        isOptionEqualToValue={(option, value) =>
                                            String(option.id) ===
                                            String(value.id)
                                        }
                                        size="small"
                                        fullWidth
                                        value={selectedMetode}
                                        onChange={(
                                            _event,
                                            newKategori: IOptions | null,
                                        ) => {
                                            setSelectedMetode(newKategori);
                                        }}
                                        sx={{ marginBottom: 2 }}
                                    />
                                    {findMetodePembayaran && (
                                        <Stack alignItems="center">
                                            {findMetodePembayaran.gambar && (
                                                <img
                                                    src={
                                                        findMetodePembayaran.gambar
                                                    }
                                                    alt="qrcode"
                                                    width={250}
                                                    height={250}
                                                />
                                            )}
                                            <Typography>
                                                a/n{" "}
                                                {
                                                    findMetodePembayaran.namaPemegang
                                                }
                                            </Typography>
                                            <Typography fontWeight="bold">
                                                {findMetodePembayaran.noTujuan}
                                            </Typography>
                                            <Stack
                                                marginTop={2}
                                                direction="row"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Typography>
                                                    Pilih tipe struk
                                                </Typography>
                                                <ToggleButtonGroup
                                                    value={tipeStrukTagihan}
                                                    exclusive
                                                    size="small"
                                                    onChange={
                                                        handleTipeStrukTagihan
                                                    }
                                                >
                                                    <ToggleButton
                                                        value={TipeStruk.Mobile}
                                                        sx={{
                                                            display: "flex",
                                                            paddingX: 2,
                                                            textTransform:
                                                                "none",
                                                        }}
                                                    >
                                                        <b>A8 58mm</b>
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        value={
                                                            TipeStruk.Mobile80
                                                        }
                                                        sx={{
                                                            display: "flex",
                                                            paddingX: 2,
                                                            textTransform:
                                                                "none",
                                                        }}
                                                    >
                                                        <b>A8 80mm</b>
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        value={TipeStruk.PC}
                                                        sx={{
                                                            display: "flex",
                                                            paddingX: 2,
                                                            textTransform:
                                                                "none",
                                                        }}
                                                    >
                                                        <b>A4</b>
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Stack>
                                            {tipeStrukTagihan ===
                                                TipeStruk.Mobile ||
                                            tipeStrukTagihan ===
                                                TipeStruk.Mobile80 ? (
                                                <BlobProvider
                                                    document={
                                                        <PrintStrukPdf
                                                            is80mm={
                                                                tipeStrukTagihan ===
                                                                TipeStruk.Mobile80
                                                            }
                                                            namaToko={String(
                                                                ukmData?.namaToko,
                                                            )}
                                                            alamatToko={String(
                                                                ukmData?.alamatToko,
                                                            )}
                                                            gambarToko={
                                                                ukmData?.gambarTokoBW ===
                                                                null
                                                                    ? undefined
                                                                    : ukmData?.gambarTokoBW
                                                            }
                                                            telpToko={String(
                                                                ukmData?.noTelp,
                                                            )}
                                                            tanggalTransaksi={
                                                                now
                                                            }
                                                            transaksiKe={String(
                                                                transaksiKe,
                                                            )}
                                                            kasir={props.kasir}
                                                            sales={
                                                                props.sales
                                                                    ?.nama ===
                                                                undefined
                                                                    ? null
                                                                    : props
                                                                          .sales
                                                                          .nama
                                                            }
                                                            total={
                                                                props.totalTagihan
                                                            }
                                                            cart={cart}
                                                            category={
                                                                categoryData
                                                            }
                                                            groupByKategori={
                                                                groupByKategori
                                                            }
                                                            diskon={
                                                                props.diskon
                                                            }
                                                            pajak={props.pajak}
                                                            pajakPersen={
                                                                ukmData?.pajak
                                                            }
                                                            belumLunas={true}
                                                            qr={
                                                                findMetodePembayaran.gambar
                                                            }
                                                            isPro={Number(
                                                                ukmData?.isPro,
                                                            )}
                                                            namaMetode={
                                                                selectedMetode &&
                                                                toggleModePembayaran ===
                                                                    ModePembayaran.TRANSFER
                                                                    ? selectedMetode.nama
                                                                    : "Tunai"
                                                            }
                                                            html={
                                                                findCustomStruk &&
                                                                findCustomStruk.html !==
                                                                    null
                                                                    ? findCustomStruk?.html
                                                                    : undefined
                                                            }
                                                            keterangan={
                                                                props.keteranganBeli
                                                            }
                                                            noMetode={
                                                                findMetodePembayaran.noTujuan
                                                            }
                                                        />
                                                    }
                                                >
                                                    {({
                                                        blob,
                                                        url,
                                                        loading,
                                                        error,
                                                    }) => {
                                                        return (
                                                            <LoadingButton
                                                                variant="outlined"
                                                                loading={
                                                                    loading
                                                                }
                                                                loadingPosition="center"
                                                                startIcon={
                                                                    <Print />
                                                                }
                                                                href={url ?? ""}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                sx={{
                                                                    marginTop: 2,
                                                                }}
                                                            >
                                                                Cetak Tagihan
                                                            </LoadingButton>
                                                        );
                                                    }}
                                                </BlobProvider>
                                            ) : (
                                                <BlobProvider
                                                    document={
                                                        <PrintStrukA4
                                                            ukmData={ukmData}
                                                            tanggalTransaksi={
                                                                now
                                                            }
                                                            transaksiKe={String(
                                                                transaksiKe,
                                                            )}
                                                            kasir={props.kasir}
                                                            sales={
                                                                props.sales
                                                                    ?.nama ===
                                                                undefined
                                                                    ? null
                                                                    : props
                                                                          .sales
                                                                          .nama
                                                            }
                                                            total={
                                                                props.totalTagihan
                                                            }
                                                            cart={cart}
                                                            category={
                                                                categoryData
                                                            }
                                                            groupByKategori={
                                                                groupByKategori
                                                            }
                                                            diskon={
                                                                props.diskon
                                                            }
                                                            pajak={props.pajak}
                                                            pajakPersen={
                                                                ukmData?.pajak
                                                            }
                                                            belumLunas={true}
                                                            namaMetode={
                                                                selectedMetode &&
                                                                toggleModePembayaran ===
                                                                    ModePembayaran.TRANSFER
                                                                    ? selectedMetode.nama
                                                                    : "Tunai"
                                                            }
                                                            html={
                                                                findCustomStruk &&
                                                                findCustomStruk.html !==
                                                                    null
                                                                    ? findCustomStruk?.html
                                                                    : undefined
                                                            }
                                                            keterangan={
                                                                props.keteranganBeli
                                                            }
                                                            namaPemegang={
                                                                findMetodePembayaran?.namaPemegang ??
                                                                null
                                                            }
                                                            nomorTujuan={
                                                                findMetodePembayaran?.noTujuan ??
                                                                null
                                                            }
                                                            qr={
                                                                findMetodePembayaran?.gambar
                                                            }
                                                        />
                                                    }
                                                >
                                                    {({
                                                        blob,
                                                        url,
                                                        loading,
                                                        error,
                                                    }) => {
                                                        return (
                                                            <LoadingButton
                                                                variant="outlined"
                                                                loading={
                                                                    loading
                                                                }
                                                                loadingPosition="center"
                                                                startIcon={
                                                                    <Print />
                                                                }
                                                                href={url ?? ""}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                sx={{
                                                                    marginTop: 2,
                                                                }}
                                                            >
                                                                Cetak Tagihan
                                                            </LoadingButton>
                                                        );
                                                    }}
                                                </BlobProvider>
                                            )}
                                        </Stack>
                                    )}
                                </Box>
                            ) : (
                                toggleModePembayaran ===
                                    ModePembayaran.TRANSFER &&
                                metodePembayaran?.length === 0 && (
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        height="50%"
                                        paddingX={isPhoneScreen ? undefined : 2}
                                    >
                                        <Typography textAlign="center">
                                            Tambahkan Bank Transfer, e-Money,
                                            QRIS, dan lain-lain sebagai metode
                                            alternatif transaksi UKM Anda.
                                        </Typography>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            marginTop={4}
                                        >
                                            <Typography
                                                variant="body2"
                                                textAlign="center"
                                            >
                                                Metode lain dapat diatur di
                                                juragan.ukmlokal.com
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            marginTop={2}
                                        >
                                            <Button
                                                variant="outlined"
                                                href={
                                                    process.env.NODE_ENV ===
                                                    "production"
                                                        ? `https://juragan.ukmlokal.com/ukm/metode-pembayaran`
                                                        : `http://localhost:3000/ukm/metode-pembayaran`
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Ke Juragan
                                            </Button>
                                        </Box>
                                    </Box>
                                )
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            borderLeft="1px solid #d4d4d4"
                            borderRadius="10px"
                        >
                            {/* <Divider flexItem /> */}
                            <Box
                                display="grid"
                                gridTemplateRows="auto 1fr auto"
                                height={
                                    fullScreen
                                        ? undefined
                                        : isTabletScreen
                                        ? "calc(100vh - 72px)"
                                        : "calc(90vh - 72px)"
                                }
                            >
                                <Stack
                                    direction="row"
                                    paddingLeft={3}
                                    paddingRight={4}
                                    paddingY={2}
                                    borderBottom="1px solid #d4d4d4"
                                    borderTop="1px solid #d4d4d4"
                                    borderRadius="10px 0 0 0"
                                >
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Typography
                                                variant="subtitle2"
                                                color="#A1A1AA"
                                            >
                                                Tanggal
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color="black"
                                            >
                                                {now}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="subtitle2"
                                                color="#A1A1AA"
                                            >
                                                Nama Kasir
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color="black"
                                            >
                                                {user?.namaLengkap}
                                            </Typography>
                                        </Grid>
                                        {!!props.sales && (
                                            <Grid item>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="#A1A1AA"
                                                >
                                                    Nama Sales
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="black"
                                                >
                                                    {props.sales.nama}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Stack>

                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    marginTop={1}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={groupByKategori}
                                                onChange={
                                                    handleCheckboxKategori
                                                }
                                            />
                                        }
                                        label="Group by Kategori"
                                        sx={{ height: "fit-content" }}
                                    />
                                </Box>

                                <DialogContent
                                    sx={{
                                        paddingBottom: 2,
                                    }}
                                >
                                    {groupByKategori ? (
                                        <Stack direction="column" spacing={1}>
                                            {cart && cart.length > 0 ? (
                                                Object.entries(
                                                    groupedCartItems,
                                                ).map(
                                                    (
                                                        [category, items],
                                                        idx,
                                                    ) => (
                                                        <Box key={String(idx)}>
                                                            <Stack
                                                                marginTop={2}
                                                                marginBottom={1}
                                                            >
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    fontWeight="700"
                                                                    fontSize={
                                                                        18
                                                                    }
                                                                >
                                                                    {truncate(
                                                                        category,
                                                                        20,
                                                                    )}
                                                                </Typography>
                                                            </Stack>
                                                            {items.map(
                                                                (
                                                                    rows,
                                                                    itemIdx,
                                                                ) => (
                                                                    <Stack
                                                                        key={String(
                                                                            itemIdx,
                                                                        )}
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                        marginLeft={
                                                                            2
                                                                        }
                                                                    >
                                                                        <Box width="50%">
                                                                            <Box>
                                                                                <Tooltip
                                                                                    title={
                                                                                        rows.namaBarang
                                                                                    }
                                                                                >
                                                                                    <Typography
                                                                                        variant={
                                                                                            isTabletScreen
                                                                                                ? "body2"
                                                                                                : "subtitle1"
                                                                                        }
                                                                                        fontWeight={
                                                                                            600
                                                                                        }
                                                                                        color="black"
                                                                                    >
                                                                                        {truncate(
                                                                                            rows.namaBarang,
                                                                                            20,
                                                                                        )}
                                                                                    </Typography>
                                                                                </Tooltip>
                                                                                <Typography>
                                                                                    Rp.{" "}
                                                                                    {toRibuan(
                                                                                        rows.harga,
                                                                                    )}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box display="flex">
                                                                            <Typography
                                                                                variant={
                                                                                    isTabletScreen
                                                                                        ? "body2"
                                                                                        : "subtitle1"
                                                                                }
                                                                                fontWeight={
                                                                                    600
                                                                                }
                                                                                color="black"
                                                                            >
                                                                                {
                                                                                    rows.qty
                                                                                }

                                                                                x
                                                                            </Typography>
                                                                        </Box>
                                                                        <Stack
                                                                            direction="row"
                                                                            width="30%"
                                                                            display="flex"
                                                                            justifyContent="flex-end"
                                                                        >
                                                                            <Typography
                                                                                display="flex"
                                                                                variant={
                                                                                    isTabletScreen
                                                                                        ? "body2"
                                                                                        : "subtitle1"
                                                                                }
                                                                                fontWeight={
                                                                                    600
                                                                                }
                                                                                color="black"
                                                                                textAlign="end"
                                                                            >
                                                                                Rp.{" "}
                                                                                {toRibuan(
                                                                                    rows.harga *
                                                                                        rows.qty,
                                                                                )}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </Stack>
                                                                ),
                                                            )}
                                                        </Box>
                                                    ),
                                                )
                                            ) : (
                                                <Typography variant="h6">
                                                    Barang tidak ditemukan
                                                </Typography>
                                            )}
                                        </Stack>
                                    ) : (
                                        <Stack direction="column" spacing={1}>
                                            {cart ? (
                                                cart.map((rows, idx) => (
                                                    <Box key={String(idx)}>
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                        >
                                                            <Box width="50%">
                                                                <Box>
                                                                    <Tooltip
                                                                        title={
                                                                            rows.namaBarang
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            variant={
                                                                                isTabletScreen
                                                                                    ? "body2"
                                                                                    : "subtitle1"
                                                                            }
                                                                            fontWeight={
                                                                                600
                                                                            }
                                                                            color="black"
                                                                        >
                                                                            {truncate(
                                                                                rows.namaBarang,
                                                                                20,
                                                                            )}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                    <Typography>
                                                                        Rp.{" "}
                                                                        {toRibuan(
                                                                            rows.harga,
                                                                        )}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            <Box display="flex">
                                                                <Typography
                                                                    variant={
                                                                        isTabletScreen
                                                                            ? "body2"
                                                                            : "subtitle1"
                                                                    }
                                                                    fontWeight={
                                                                        600
                                                                    }
                                                                    color="black"
                                                                >
                                                                    {rows.qty}x
                                                                </Typography>
                                                            </Box>
                                                            <Stack
                                                                direction="row"
                                                                width="30%"
                                                                display="flex"
                                                                justifyContent="flex-end"
                                                            >
                                                                <Typography
                                                                    display="flex"
                                                                    variant={
                                                                        isTabletScreen
                                                                            ? "body2"
                                                                            : "subtitle1"
                                                                    }
                                                                    fontWeight={
                                                                        600
                                                                    }
                                                                    color="black"
                                                                    textAlign="end"
                                                                >
                                                                    Rp.{" "}
                                                                    {toRibuan(
                                                                        rows.harga *
                                                                            rows.qty,
                                                                    )}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </Box>
                                                ))
                                            ) : (
                                                <Typography variant="h6">
                                                    Barang tidak ditemukan
                                                </Typography>
                                            )}
                                        </Stack>
                                    )}

                                    <Box
                                        marginY={2}
                                        padding={2}
                                        border={1}
                                        borderColor="#d4d4d4"
                                        borderRadius={2}
                                    >
                                        <Typography
                                            marginBottom={1}
                                            variant={
                                                isTabletScreen
                                                    ? "body2"
                                                    : "subtitle1"
                                            }
                                            color="#000000"
                                            fontWeight="bold"
                                        >
                                            Ringkasan Pembayaran
                                        </Typography>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={3}
                                                md={4}
                                                lg={4}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#404040"
                                                >
                                                    Subtotal Harga
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={5}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        fontWeight="bold"
                                                    >
                                                        Rp.
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        fontWeight="bold"
                                                    >
                                                        {toRibuan(totalHarga)}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            marginTop={1}
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={4}
                                                md={5}
                                                lg={5}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#404040"
                                                >
                                                    Diskon{" "}
                                                    {props.persentaseDiskon &&
                                                    props.persentaseDiskon > 0
                                                        ? `(${props.persentaseDiskon}%)`
                                                        : ""}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={5}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    bgcolor="#FFF5EC"
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#FB923C"
                                                        fontWeight="bold"
                                                    >
                                                        Rp.
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#FB923C"
                                                        fontWeight="bold"
                                                    >
                                                        {toRibuan(
                                                            props.diskon ?? 0,
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            marginTop={1}
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={4}
                                                md={5}
                                                lg={5}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#404040"
                                                >
                                                    Pajak{" "}
                                                    {props.persentasePajak &&
                                                    props.persentasePajak > 0
                                                        ? `(${props.persentasePajak}%)`
                                                        : ""}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={5}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    bgcolor="#FFF5EC"
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#FB923C"
                                                        fontWeight="bold"
                                                    >
                                                        Rp.
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#FB923C"
                                                        fontWeight="bold"
                                                    >
                                                        {toRibuan(
                                                            props.pajak ?? 0,
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            marginTop={1}
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={3}
                                                md={4}
                                                lg={4}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#404040"
                                                >
                                                    Total Tagihan
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={5}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    bgcolor="#E8F6ED"
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#45A779"
                                                        fontWeight="bold"
                                                    >
                                                        Rp.
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#45A779"
                                                        fontWeight="bold"
                                                    >
                                                        {toRibuan(
                                                            props.totalTagihan,
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            marginTop={1}
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={3}
                                                md={4}
                                                lg={4}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#404040"
                                                >
                                                    Uang yang Dibayar
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={5}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    bgcolor="#FFF5EC"
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#FB923C"
                                                        fontWeight="bold"
                                                    >
                                                        Rp.
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#FB923C"
                                                        fontWeight="bold"
                                                    >
                                                        {toRibuan(
                                                            props.uangDibayar ??
                                                                0,
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            marginTop={1}
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={3}
                                                md={4}
                                                lg={4}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#404040"
                                                >
                                                    Kembalian
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={5}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    bgcolor="#FCEAEA"
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#DC2626"
                                                        fontWeight="bold"
                                                    >
                                                        Rp.
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#DC2626"
                                                        fontWeight="bold"
                                                    >
                                                        {toRibuan(
                                                            props.kembalian,
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        {findMetodePembayaran && (
                                            <Grid
                                                container
                                                alignItems="center"
                                                marginTop={1}
                                                justifyContent="space-between"
                                            >
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={3}
                                                    md={4}
                                                    lg={4}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#404040"
                                                    >
                                                        Charge{" "}
                                                        {
                                                            findMetodePembayaran.namaMetode
                                                        }{" "}
                                                        {findMetodePembayaran
                                                            .charge.mode ===
                                                        "persen"
                                                            ? `(${findMetodePembayaran.charge.charge}%)`
                                                            : ""}
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={3} xs={5}>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        paddingX={1}
                                                    >
                                                        <Typography
                                                            variant={
                                                                isTabletScreen
                                                                    ? "body2"
                                                                    : "subtitle1"
                                                            }
                                                            fontWeight="bold"
                                                        >
                                                            Rp.
                                                        </Typography>
                                                        <Typography
                                                            variant={
                                                                isTabletScreen
                                                                    ? "body2"
                                                                    : "subtitle1"
                                                            }
                                                            fontWeight="bold"
                                                        >
                                                            {findMetodePembayaran
                                                                .charge.mode ===
                                                            "persen"
                                                                ? toRibuan(
                                                                      (props.uangDibayar *
                                                                          Number(
                                                                              findMetodePembayaran
                                                                                  .charge
                                                                                  .charge,
                                                                          )) /
                                                                          100,
                                                                  )
                                                                : toRibuan(
                                                                      findMetodePembayaran
                                                                          .charge
                                                                          .charge,
                                                                  )}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {props.keteranganBeli && (
                                            <Grid
                                                container
                                                alignItems="center"
                                                marginTop={1}
                                            >
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#404040"
                                                    >
                                                        Keterangan
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        disabled
                                                        multiline
                                                        rows={2}
                                                        fullWidth
                                                        value={
                                                            props.keteranganBeli
                                                        }
                                                        sx={{
                                                            "& .MuiOutlinedInput-input.Mui-disabled":
                                                                {
                                                                    color: "rgba(0, 0, 0, 0.87)",
                                                                    WebkitTextFillColor:
                                                                        "rgba(0, 0, 0, 0.87)",
                                                                },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Box>
                                </DialogContent>
                                <Grid
                                    container
                                    justifyContent="flex-end"
                                    display={fullScreen ? "none" : undefined}
                                >
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box
                                            paddingTop={3}
                                            paddingLeft={3}
                                            display="flex"
                                            justifyContent="flex-start"
                                            borderTop={1}
                                            borderColor="#d4d4d4"
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={printStruk}
                                                        size={
                                                            isTabletScreen
                                                                ? "small"
                                                                : undefined
                                                        }
                                                        onChange={(
                                                            _,
                                                            checked,
                                                        ) => {
                                                            handlePrintStruk(
                                                                checked,
                                                            );
                                                        }}
                                                    />
                                                }
                                                label="Print struk"
                                                componentsProps={{
                                                    typography: {
                                                        fontSize: isTabletScreen
                                                            ? "12px"
                                                            : undefined,
                                                    },
                                                }}
                                                sx={{
                                                    height: "fit-content",
                                                }}
                                            />
                                            {printStruk && (
                                                <ToggleButtonGroup
                                                    value={tipeStruk}
                                                    exclusive
                                                    size="small"
                                                    onChange={handleTipeStruk}
                                                >
                                                    <ToggleButton
                                                        value={TipeStruk.Mobile}
                                                        sx={{
                                                            display: "flex",
                                                            paddingX: 2,
                                                            textTransform:
                                                                "none",
                                                        }}
                                                    >
                                                        <b>A8 58mm</b>
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        value={
                                                            TipeStruk.Mobile80
                                                        }
                                                        sx={{
                                                            display: "flex",
                                                            paddingX: 2,
                                                            textTransform:
                                                                "none",
                                                        }}
                                                    >
                                                        <b>A8 80mm</b>
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        value={TipeStruk.PC}
                                                        sx={{
                                                            display: "flex",
                                                            paddingX: 2,
                                                            textTransform:
                                                                "none",
                                                        }}
                                                    >
                                                        <b>A4</b>
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            )}
                                        </Box>
                                        <Box
                                            paddingLeft={3}
                                            display="flex"
                                            // justifyContent="flex-start"
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={kirimEmail}
                                                        size={
                                                            isTabletScreen
                                                                ? "small"
                                                                : undefined
                                                        }
                                                        onChange={(
                                                            _,
                                                            checked,
                                                        ) => {
                                                            handleKirimEmail(
                                                                checked,
                                                            );
                                                        }}
                                                    />
                                                }
                                                label="Kirim struk"
                                                componentsProps={{
                                                    typography: {
                                                        fontSize: isTabletScreen
                                                            ? "12px"
                                                            : undefined,
                                                    },
                                                }}
                                                sx={{
                                                    height: "fit-content",
                                                }}
                                            />
                                        </Box>
                                        {kirimEmail && (
                                            <Box paddingX={3}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={6}>
                                                        <Stack
                                                            display="flex"
                                                            direction="row"
                                                            spacing={1}
                                                            sx={{
                                                                marginRight: 2,
                                                            }}
                                                        >
                                                            <Button
                                                                disabled={
                                                                    user &&
                                                                    !user.akses.transaksi.includes(
                                                                        12,
                                                                    )
                                                                }
                                                                size={
                                                                    isTabletScreen
                                                                        ? "small"
                                                                        : "medium"
                                                                }
                                                                fullWidth
                                                                variant={
                                                                    toggleKirimStruk ===
                                                                    "whatsapp"
                                                                        ? "contained"
                                                                        : "outlined"
                                                                }
                                                                onClick={() =>
                                                                    pilihWhatsapp()
                                                                }
                                                            >
                                                                Whatsapp
                                                            </Button>
                                                            <Button
                                                                disabled={
                                                                    user &&
                                                                    !user.akses.transaksi.includes(
                                                                        12,
                                                                    )
                                                                }
                                                                size={
                                                                    isTabletScreen
                                                                        ? "small"
                                                                        : "medium"
                                                                }
                                                                fullWidth
                                                                variant={
                                                                    toggleKirimStruk ===
                                                                    "email"
                                                                        ? "contained"
                                                                        : "outlined"
                                                                }
                                                                onClick={() =>
                                                                    pilihEmail()
                                                                }
                                                            >
                                                                Email
                                                            </Button>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            value={email}
                                                            size="small"
                                                            type="email"
                                                            fullWidth
                                                            sx={{
                                                                maxWidth: 300,
                                                            }}
                                                            placeholder={
                                                                toggleKirimStruk ===
                                                                "email"
                                                                    ? "Contoh: budi93@gmail.com"
                                                                    : "8316352725"
                                                            }
                                                            InputProps={{
                                                                startAdornment:
                                                                    toggleKirimStruk ===
                                                                        "whatsapp" && (
                                                                        <InputAdornment position="start">
                                                                            +62
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                            onChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>,
                                                            ) =>
                                                                setEmail(
                                                                    event.target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )}
                                        <Box
                                            marginTop={isTabletScreen ? 1 : 2}
                                            marginLeft={3}
                                            marginRight={3}
                                            marginBottom={
                                                isTabletScreen ? 2 : 4
                                            }
                                        >
                                            <Stack
                                                direction="column"
                                                display="flex"
                                                justifyContent="space-between"
                                                marginBottom={1}
                                                spacing={1}
                                            >
                                                <Stack
                                                    direction="row"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        fontWeight="bold"
                                                    >
                                                        Total
                                                    </Typography>
                                                    <Box
                                                        bgcolor="#E8F6ED"
                                                        borderRadius={1}
                                                        paddingX={2}
                                                    >
                                                        <Typography
                                                            variant={
                                                                isTabletScreen
                                                                    ? "body2"
                                                                    : "subtitle1"
                                                            }
                                                            fontWeight="bold"
                                                            color="#45A779"
                                                        >
                                                            Rp.{" "}
                                                            {toRibuan(
                                                                props.totalTagihan,
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                                <Stack
                                                    direction="row"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        fontWeight="bold"
                                                    >
                                                        Kembalian
                                                    </Typography>
                                                    <Box
                                                        justifyContent="space-between"
                                                        bgcolor="#FCEAEA"
                                                        paddingX={2}
                                                        borderRadius={1}
                                                    >
                                                        <Typography
                                                            variant={
                                                                isTabletScreen
                                                                    ? "body2"
                                                                    : "subtitle1"
                                                            }
                                                            fontWeight="bold"
                                                            color="#DC2626"
                                                        >
                                                            Rp.{" "}
                                                            {toRibuan(
                                                                props.kembalian,
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                                {printStruk ? (
                                                    <BlobProvider
                                                        document={
                                                            tipeStruk ===
                                                                TipeStruk.Mobile ||
                                                            tipeStruk ===
                                                                TipeStruk.Mobile80 ? (
                                                                <PrintStrukPdf
                                                                    is80mm={
                                                                        tipeStruk ===
                                                                        TipeStruk.Mobile80
                                                                    }
                                                                    namaToko={String(
                                                                        ukmData?.namaToko,
                                                                    )}
                                                                    alamatToko={String(
                                                                        ukmData?.alamatToko,
                                                                    )}
                                                                    gambarToko={
                                                                        ukmData?.gambarTokoBW ===
                                                                        null
                                                                            ? undefined
                                                                            : ukmData?.gambarTokoBW
                                                                    }
                                                                    telpToko={String(
                                                                        ukmData?.noTelp,
                                                                    )}
                                                                    tanggalTransaksi={
                                                                        now
                                                                    }
                                                                    transaksiKe={String(
                                                                        transaksiKe,
                                                                    )}
                                                                    kasir={
                                                                        props.kasir
                                                                    }
                                                                    sales={
                                                                        props
                                                                            .sales
                                                                            ?.nama ===
                                                                        undefined
                                                                            ? null
                                                                            : props
                                                                                  .sales
                                                                                  .nama
                                                                    }
                                                                    total={
                                                                        props.totalTagihan
                                                                    }
                                                                    cart={cart}
                                                                    category={
                                                                        categoryData
                                                                    }
                                                                    groupByKategori={
                                                                        groupByKategori
                                                                    }
                                                                    diskon={
                                                                        props.diskon
                                                                    }
                                                                    pajak={
                                                                        props.pajak
                                                                    }
                                                                    pajakPersen={
                                                                        ukmData?.pajak
                                                                    }
                                                                    tunai={Number(
                                                                        props.uangDibayar,
                                                                    )}
                                                                    kembalian={
                                                                        props.kembalian
                                                                    }
                                                                    isPro={Number(
                                                                        ukmData?.isPro,
                                                                    )}
                                                                    namaMetode={
                                                                        selectedMetode &&
                                                                        toggleModePembayaran ===
                                                                            ModePembayaran.TRANSFER
                                                                            ? selectedMetode.nama
                                                                            : "Tunai"
                                                                    }
                                                                    html={
                                                                        findCustomStruk &&
                                                                        findCustomStruk.html !==
                                                                            null
                                                                            ? findCustomStruk?.html
                                                                            : undefined
                                                                    }
                                                                    keterangan={
                                                                        props.keteranganBeli
                                                                    }
                                                                    noMetode={
                                                                        findMetodePembayaran?.noTujuan ??
                                                                        null
                                                                    }
                                                                />
                                                            ) : (
                                                                <PrintStrukA4
                                                                    ukmData={
                                                                        ukmData
                                                                    }
                                                                    tanggalTransaksi={
                                                                        now
                                                                    }
                                                                    transaksiKe={String(
                                                                        transaksiKe,
                                                                    )}
                                                                    kasir={
                                                                        props.kasir
                                                                    }
                                                                    sales={
                                                                        props
                                                                            .sales
                                                                            ?.nama ===
                                                                        undefined
                                                                            ? null
                                                                            : props
                                                                                  .sales
                                                                                  .nama
                                                                    }
                                                                    total={
                                                                        props.totalTagihan
                                                                    }
                                                                    cart={cart}
                                                                    category={
                                                                        categoryData
                                                                    }
                                                                    groupByKategori={
                                                                        groupByKategori
                                                                    }
                                                                    diskon={
                                                                        props.diskon
                                                                    }
                                                                    pajak={
                                                                        props.pajak
                                                                    }
                                                                    pajakPersen={
                                                                        ukmData?.pajak
                                                                    }
                                                                    tunai={Number(
                                                                        props.uangDibayar,
                                                                    )}
                                                                    kembalian={
                                                                        props.kembalian
                                                                    }
                                                                    namaMetode={
                                                                        selectedMetode &&
                                                                        toggleModePembayaran ===
                                                                            ModePembayaran.TRANSFER
                                                                            ? selectedMetode.nama
                                                                            : "Tunai"
                                                                    }
                                                                    html={
                                                                        findCustomStruk &&
                                                                        findCustomStruk.html !==
                                                                            null
                                                                            ? findCustomStruk?.html
                                                                            : undefined
                                                                    }
                                                                    keterangan={
                                                                        props.keteranganBeli
                                                                    }
                                                                    namaPemegang={
                                                                        findMetodePembayaran?.namaPemegang ??
                                                                        null
                                                                    }
                                                                    nomorTujuan={
                                                                        findMetodePembayaran?.noTujuan ??
                                                                        null
                                                                    }
                                                                    qr={
                                                                        findMetodePembayaran?.gambar
                                                                    }
                                                                />
                                                            )
                                                        }
                                                    >
                                                        {({
                                                            blob,
                                                            url,
                                                            loading,
                                                            error,
                                                        }) => {
                                                            return (
                                                                <LoadingButton
                                                                    disabled={
                                                                        toggleModePembayaran ===
                                                                            ModePembayaran.TRANSFER &&
                                                                        selectedMetode ===
                                                                            null
                                                                    }
                                                                    loading={
                                                                        loading ||
                                                                        isButtonLoading ||
                                                                        isLoadingFindMetodePembayaran
                                                                    }
                                                                    loadingPosition="center"
                                                                    variant="contained"
                                                                    fullWidth
                                                                    size={
                                                                        isTabletScreen
                                                                            ? "medium"
                                                                            : "large"
                                                                    }
                                                                    // href={
                                                                    //     url ??
                                                                    //     ""
                                                                    // }
                                                                    // target="_blank"
                                                                    // rel="noreferrer"
                                                                    onClick={() => {
                                                                        if (
                                                                            blob
                                                                        ) {
                                                                            const blobFile =
                                                                                new File(
                                                                                    [
                                                                                        blob,
                                                                                    ],
                                                                                    `Invoice_${ukmData?.namaToko}_${now}.pdf`,
                                                                                    {
                                                                                        type: "application/pdf",
                                                                                    },
                                                                                );
                                                                            const invoiceUrl =
                                                                                window.URL.createObjectURL(
                                                                                    blobFile,
                                                                                );
                                                                            console.log(
                                                                                invoiceUrl,
                                                                            );
                                                                            window.open(
                                                                                invoiceUrl,
                                                                            );
                                                                            inputPembayaran();
                                                                            // printJS(
                                                                            //     {
                                                                            //         printable:
                                                                            //             URL.createObjectURL(
                                                                            //                 blob,
                                                                            //             ),
                                                                            //         type: "pdf",
                                                                            //         showModal:
                                                                            //             true,
                                                                            //         modalMessage:
                                                                            //             "Mohon tunggu",
                                                                            //     },
                                                                            // );
                                                                        }
                                                                    }}
                                                                >
                                                                    Bayar
                                                                </LoadingButton>
                                                            );
                                                        }}
                                                    </BlobProvider>
                                                ) : (
                                                    <LoadingButton
                                                        disabled={
                                                            toggleModePembayaran ===
                                                                ModePembayaran.TRANSFER &&
                                                            selectedMetode ===
                                                                null
                                                        }
                                                        loading={
                                                            isButtonLoading ||
                                                            isLoadingFindMetodePembayaran
                                                        }
                                                        loadingPosition="center"
                                                        variant="contained"
                                                        fullWidth
                                                        size={
                                                            isTabletScreen
                                                                ? "medium"
                                                                : "large"
                                                        }
                                                        onClick={
                                                            inputPembayaran
                                                        }
                                                    >
                                                        Bayar
                                                    </LoadingButton>
                                                )}
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Stack>
                    <Grid
                        container
                        justifyContent="flex-end"
                        display={fullScreen ? undefined : "none"}
                        position="fixed"
                        bottom={0}
                        bgcolor="#fff"
                        zIndex={1}
                    >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box
                                paddingTop={3}
                                paddingLeft={3}
                                display="flex"
                                justifyContent="flex-start"
                                borderTop={1}
                                borderColor="#d4d4d4"
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={printStruk}
                                            onChange={(_, checked) => {
                                                handlePrintStruk(checked);
                                            }}
                                        />
                                    }
                                    label="Print struk"
                                    sx={{
                                        height: "fit-content",
                                    }}
                                />
                                {printStruk && (
                                    <ToggleButtonGroup
                                        value={tipeStruk}
                                        exclusive
                                        size="small"
                                        onChange={handleTipeStruk}
                                    >
                                        <ToggleButton
                                            value={TipeStruk.Mobile}
                                            sx={{
                                                display: "flex",
                                                paddingX: 2,
                                                textTransform: "none",
                                            }}
                                        >
                                            <b>A8 58mm</b>
                                        </ToggleButton>
                                        <ToggleButton
                                            value={TipeStruk.Mobile80}
                                            sx={{
                                                display: "flex",
                                                paddingX: 2,
                                                textTransform: "none",
                                            }}
                                        >
                                            <b>A8 80mm</b>
                                        </ToggleButton>
                                        <ToggleButton
                                            value={TipeStruk.PC}
                                            sx={{
                                                display: "flex",
                                                paddingX: 2,
                                                textTransform: "none",
                                            }}
                                        >
                                            <b>A4</b>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                )}
                            </Box>
                            <Box
                                paddingLeft={3}
                                display="flex"
                                // justifyContent="flex-start"
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={kirimEmail}
                                            onChange={(_, checked) => {
                                                handleKirimEmail(checked);
                                            }}
                                        />
                                    }
                                    label="Kirim struk"
                                    sx={{
                                        height: "fit-content",
                                    }}
                                />
                            </Box>
                            {kirimEmail && (
                                <Box paddingX={3}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Stack
                                                display="flex"
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    marginRight: 2,
                                                }}
                                            >
                                                <Button
                                                    disabled={
                                                        user &&
                                                        !user.akses.transaksi.includes(
                                                            12,
                                                        )
                                                    }
                                                    size="medium"
                                                    fullWidth
                                                    variant={
                                                        toggleKirimStruk ===
                                                        "whatsapp"
                                                            ? "contained"
                                                            : "outlined"
                                                    }
                                                    onClick={() =>
                                                        pilihWhatsapp()
                                                    }
                                                >
                                                    Whatsapp
                                                </Button>
                                                <Button
                                                    disabled={
                                                        user &&
                                                        !user.akses.transaksi.includes(
                                                            12,
                                                        )
                                                    }
                                                    size="medium"
                                                    fullWidth
                                                    variant={
                                                        toggleKirimStruk ===
                                                        "email"
                                                            ? "contained"
                                                            : "outlined"
                                                    }
                                                    onClick={() => pilihEmail()}
                                                >
                                                    Email
                                                </Button>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                value={email}
                                                size="small"
                                                type="email"
                                                fullWidth
                                                sx={{ maxWidth: 300 }}
                                                placeholder={
                                                    toggleKirimStruk === "email"
                                                        ? "Contoh: budi93@gmail.com"
                                                        : "8316352725"
                                                }
                                                InputProps={{
                                                    startAdornment:
                                                        toggleKirimStruk ===
                                                            "whatsapp" && (
                                                            <InputAdornment position="start">
                                                                +62
                                                            </InputAdornment>
                                                        ),
                                                }}
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) =>
                                                    setEmail(event.target.value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                            <Box
                                marginTop={2}
                                marginLeft={3}
                                marginRight={3}
                                marginBottom={4}
                            >
                                <Stack
                                    direction="column"
                                    display="flex"
                                    justifyContent="space-between"
                                    marginBottom={1}
                                    spacing={1}
                                >
                                    <Stack
                                        direction="row"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight="bold"
                                        >
                                            Total
                                        </Typography>
                                        <Box
                                            bgcolor="#E8F6ED"
                                            borderRadius={1}
                                            paddingX={2}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight="bold"
                                                color="#45A779"
                                            >
                                                Rp.{" "}
                                                {toRibuan(props.totalTagihan)}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight="bold"
                                        >
                                            Kembalian
                                        </Typography>
                                        <Box
                                            justifyContent="space-between"
                                            bgcolor="#FCEAEA"
                                            paddingX={2}
                                            borderRadius={1}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight="bold"
                                                color="#DC2626"
                                            >
                                                Rp. {toRibuan(props.kembalian)}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                                {printStruk ? (
                                    <BlobProvider
                                        document={
                                            tipeStruk === TipeStruk.Mobile ||
                                            tipeStruk === TipeStruk.Mobile80 ? (
                                                <PrintStrukPdf
                                                    is80mm={
                                                        tipeStruk ===
                                                        TipeStruk.Mobile80
                                                    }
                                                    namaToko={String(
                                                        ukmData?.namaToko,
                                                    )}
                                                    alamatToko={String(
                                                        ukmData?.alamatToko,
                                                    )}
                                                    gambarToko={
                                                        ukmData?.gambarTokoBW ===
                                                        null
                                                            ? undefined
                                                            : ukmData?.gambarTokoBW
                                                    }
                                                    telpToko={String(
                                                        ukmData?.noTelp,
                                                    )}
                                                    tanggalTransaksi={now}
                                                    transaksiKe={String(
                                                        transaksiKe,
                                                    )}
                                                    kasir={props.kasir}
                                                    sales={
                                                        props.sales?.nama ===
                                                        undefined
                                                            ? null
                                                            : props.sales.nama
                                                    }
                                                    total={props.totalTagihan}
                                                    cart={cart}
                                                    category={categoryData}
                                                    groupByKategori={
                                                        groupByKategori
                                                    }
                                                    diskon={props.diskon}
                                                    pajak={props.pajak}
                                                    pajakPersen={ukmData?.pajak}
                                                    tunai={Number(
                                                        props.uangDibayar,
                                                    )}
                                                    kembalian={props.kembalian}
                                                    isPro={Number(
                                                        ukmData?.isPro,
                                                    )}
                                                    namaMetode={
                                                        selectedMetode
                                                            ? selectedMetode.nama
                                                            : "Tunai"
                                                    }
                                                    html={
                                                        findCustomStruk &&
                                                        findCustomStruk.html !==
                                                            null
                                                            ? findCustomStruk?.html
                                                            : undefined
                                                    }
                                                    keterangan={
                                                        props.keteranganBeli
                                                    }
                                                    noMetode={
                                                        findMetodePembayaran?.noTujuan ??
                                                        null
                                                    }
                                                />
                                            ) : (
                                                <PrintStrukA4
                                                    ukmData={ukmData}
                                                    tanggalTransaksi={now}
                                                    transaksiKe={String(
                                                        transaksiKe,
                                                    )}
                                                    kasir={props.kasir}
                                                    sales={
                                                        props.sales?.nama ===
                                                        undefined
                                                            ? null
                                                            : props.sales.nama
                                                    }
                                                    total={props.totalTagihan}
                                                    cart={cart}
                                                    category={categoryData}
                                                    groupByKategori={
                                                        groupByKategori
                                                    }
                                                    diskon={props.diskon}
                                                    pajak={props.pajak}
                                                    pajakPersen={ukmData?.pajak}
                                                    tunai={Number(
                                                        props.uangDibayar,
                                                    )}
                                                    kembalian={props.kembalian}
                                                    namaMetode={
                                                        selectedMetode &&
                                                        toggleModePembayaran ===
                                                            ModePembayaran.TRANSFER
                                                            ? selectedMetode.nama
                                                            : "Tunai"
                                                    }
                                                    html={
                                                        findCustomStruk &&
                                                        findCustomStruk.html !==
                                                            null
                                                            ? findCustomStruk?.html
                                                            : undefined
                                                    }
                                                    keterangan={
                                                        props.keteranganBeli
                                                    }
                                                    namaPemegang={
                                                        findMetodePembayaran?.namaPemegang ??
                                                        null
                                                    }
                                                    nomorTujuan={
                                                        findMetodePembayaran?.noTujuan ??
                                                        null
                                                    }
                                                    qr={
                                                        findMetodePembayaran?.gambar
                                                    }
                                                />
                                            )
                                        }
                                    >
                                        {({ blob, url, loading, error }) => {
                                            return (
                                                <LoadingButton
                                                    disabled={
                                                        toggleModePembayaran ===
                                                            ModePembayaran.TRANSFER &&
                                                        selectedMetode === null
                                                    }
                                                    loading={
                                                        loading ||
                                                        isButtonLoading ||
                                                        isLoadingFindMetodePembayaran
                                                    }
                                                    loadingPosition="center"
                                                    variant="contained"
                                                    fullWidth
                                                    size="large"
                                                    // href={
                                                    //     url ??
                                                    //     ""
                                                    // }
                                                    // target="_blank"
                                                    // rel="noreferrer"
                                                    onClick={() => {
                                                        if (blob) {
                                                            const blobFile =
                                                                new File(
                                                                    [blob],
                                                                    `Invoice_${ukmData?.namaToko}_${now}.pdf`,
                                                                    {
                                                                        type: "application/pdf",
                                                                    },
                                                                );
                                                            const invoiceUrl =
                                                                window.URL.createObjectURL(
                                                                    blobFile,
                                                                );
                                                            console.log(
                                                                invoiceUrl,
                                                            );
                                                            window.open(
                                                                invoiceUrl,
                                                            );
                                                            inputPembayaran();
                                                            // printJS({
                                                            //     printable:
                                                            //         URL.createObjectURL(
                                                            //             blob,
                                                            //         ),
                                                            //     type: "pdf",
                                                            //     showModal: true,
                                                            //     modalMessage:
                                                            //         "Mohon tunggu",
                                                            // });
                                                        }
                                                    }}
                                                >
                                                    Bayar
                                                </LoadingButton>
                                            );
                                        }}
                                    </BlobProvider>
                                ) : (
                                    <LoadingButton
                                        disabled={
                                            toggleModePembayaran ===
                                                ModePembayaran.TRANSFER &&
                                            selectedMetode === null
                                        }
                                        loading={
                                            isButtonLoading ||
                                            isLoadingFindMetodePembayaran
                                        }
                                        loadingPosition="center"
                                        variant="contained"
                                        fullWidth
                                        size="large"
                                        onClick={inputPembayaran}
                                    >
                                        Bayar
                                    </LoadingButton>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {ukmData && (
                <ModalAlertPro
                    isOpenModalAlertPro={isOpenModalAlertPro}
                    closeModalAlertPro={() => setIsOpenModalAlertPro(false)}
                    dataUkm={{ id: ukmData?.id, nama: ukmData?.namaToko }}
                />
            )}
        </Box>
    );
};

export default ModalKonfirmasiBayar;
