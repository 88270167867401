import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import useUser from "../../services/queries/useUser";
import * as yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import defaultAxios, { AxiosError } from "axios";
import { useAuth } from "../../context/authContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { useImportModal } from "../../context/importModalContext";
import { Controller, useForm } from "react-hook-form";
import { DefaultResponse, ErrorFieldResponse } from "../../constants/types";
import {
    Dialog,
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    DialogContent,
    Grid,
    TextField,
    useMediaQuery,
    Button,
    Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTransaksi } from "../../context/transaksiContext";
import useNoWaAdmin from "../../services/queries/useNoWaAdmin";
import { apiUrl } from "../../constants/common";
import ModalAlertPro from "../ModalAlertPro/ModalAlertPro";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import useUkm from "../../services/queries/useUkm";

interface IModalImportProps {
    namaToko?: string;
    isPro?: number;
}

interface IValuesImport {
    ukmId: string;
    berkas: File | null;
}

const schema = yup.object({
    ukmId: yup.string(),
    berkas: yup.mixed(),
});

const ModalImport = ({ namaToko, isPro }: IModalImportProps) => {
    const theme = useTheme();
    const { setFetchingItems } = useTransaksi();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { ukmIdUser } = useAuth();
    const { data: user } = useUser();
    const initialParamsAllUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: allUkm } = useUkm(initialParamsAllUkm);
    const { isOpenModalImport, closeModalImport } = useImportModal();
    const [selectUkm, setSelectUkm] = useState<any>();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isOpenModalAlertPro, setIsOpenModalAlertPro] = useState(false);

    const initialValues: IValuesImport = useMemo(
        () => ({
            ukmId: selectUkm ?? Number(ukmIdUser && ukmIdUser[0]),
            berkas: null,
        }),
        [selectUkm, ukmIdUser],
    );

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<IValuesImport>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    // const onSubmit: SubmitHandler<Values> = async (values) => {
    //     console.log("submiting with ", values);
    //     try {
    //         const formData = new FormData();

    //         formData.append("fileMatriks", values.fileMatriks);

    //         const { data } = await axios.post<DefaultResponse>(
    //             `/api/matrix/upload`,
    //             formData,
    //             {
    //                 headers: {
    //                     "Content-Type": "multipart/form-data",
    //                 },
    //             }
    //         );
    //         if (data.code === 200) {
    //             ToastAndroid.show(`${data.message}`, ToastAndroid.LONG);
    //         }
    //     } catch (error) {
    //         handleErrorResponse(error);
    //     }
    // };

    const onSubmit = async (values: IValuesImport) => {
        console.log(values);
        setIsButtonLoading(true);
        try {
            const formData = new FormData();
            formData.append("ukmId", values.ukmId);
            formData.append(
                "file",
                values.berkas ? (values.berkas as any) : "",
            );
            // const { data } = await axios.post<DefaultResponse>(`/api/importitem`, {
            //     berkas: values.berkas,
            // });
            const { data } = await axios.post<DefaultResponse>(
                `/api/importitem`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            if (data.code === 200) {
                Swal.fire({
                    title: "Stok berhasil di-import",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                console.log("berhasil");
            }
            // openAlert();
            setIsButtonLoading(false);
            setFetchingItems();
            closeModalImport();
            reset(initialValues);
            console.log("test 2");
            // console.log(data.nama);
        } catch (error) {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    console.log(
                        "Ini message",
                        serverError.response.data?.message,
                    );
                    Swal.fire({
                        title: `${serverError.response.data?.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            }
            closeModalImport();
            setIsButtonLoading(false);
            console.error(error);
        }
    };

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return allUkm?.content.find((li) => li.id === ukmId);
        }
        return allUkm?.content[0];
    }, [allUkm?.content, ukmId]);

    useEffect(() => {
        if (Number(localStorage.getItem(`user${user?.userId}`))) {
            setSelectUkm(Number(localStorage.getItem(`user${user?.userId}`)));
        }
    }, [user?.userId]);

    // useEffect(() => {
    //     refetch();
    // }, [item]);

    return (
        <>
            <Dialog
                maxWidth="sm"
                fullScreen={isPhoneScreen}
                fullWidth={true}
                open={isOpenModalImport}
                onClose={closeModalImport}
                PaperProps={{
                    sx: {
                        borderRadius: 1,
                    },
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton onClick={closeModalImport}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" fontWeight="bold">
                            Unggah Stok
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    {/* <code>{JSON.stringify(values)}</code> */}
                    <Typography variant="h6" fontWeight="bold">
                        Template Pengisian
                    </Typography>
                    <Typography
                        variant="body1"
                        color="#545E6A"
                        sx={{ maxWidth: "360px" }}
                    >
                        Unduh template terlebih dahulu sebelum mengunggah berkas
                    </Typography>
                    <a href={`${apiUrl}api/importitem`}>
                        <Button
                            variant="outlined"
                            size="large"
                            sx={{ marginY: 4 }}
                        >
                            Unduh Template
                        </Button>
                    </a>
                    <Typography variant="h6" fontWeight="bold">
                        Unggah Berkas
                    </Typography>

                    <Typography
                        variant="body1"
                        color="#545E6A"
                        sx={{ maxWidth: "460px" }}
                    >
                        Maksimal barang adalah {ukmData?.limitItem ?? "500"}.
                    </Typography>
                    <Grid container alignItems="center" marginY={2}>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="#808080">
                                Berkas Stok
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="berkas"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        id="berkas"
                                        fullWidth
                                        type="file"
                                        variant="outlined"
                                        inputProps={{
                                            accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                                        }}
                                        error={Boolean(errors.berkas)}
                                        helperText={
                                            errors.berkas
                                                ? errors.berkas.message
                                                : " "
                                        }
                                        {...field}
                                        onChange={(event: any) => {
                                            if (
                                                event.target.files[0].size <
                                                512000
                                            ) {
                                                console.log(
                                                    event.target.files[0],
                                                );
                                                setValue(
                                                    "berkas",
                                                    event.target.files[0],
                                                );
                                                clearErrors("berkas");
                                            } else {
                                                event.target.value = "";
                                                setError("berkas", {
                                                    type: "manual",
                                                    message:
                                                        "Ukuran berkas harus kurang dari 500KB",
                                                });
                                            }
                                        }}
                                        value={undefined}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Stack
                        direction="row"
                        spacing={isDesktopScreen ? 0 : 2}
                        alignItems="center"
                    >
                        <Button
                            variant="outlined"
                            onClick={() => closeModalImport()}
                            fullWidth
                            sx={{ display: { xs: "block", md: "none" } }}
                        >
                            <Typography>Kembali</Typography>
                        </Button>
                        <LoadingButton
                            loading={isButtonLoading}
                            loadingPosition="center"
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                            type="submit"
                            fullWidth={isDesktopScreen ? false : true}
                        >
                            Unggah
                        </LoadingButton>
                    </Stack>
                </DialogContent>
            </Dialog>
            {ukmData && (
                <ModalAlertPro
                    isOpenModalAlertPro={isOpenModalAlertPro}
                    closeModalAlertPro={() => setIsOpenModalAlertPro(false)}
                    dataUkm={{ id: ukmData?.id, nama: ukmData?.namaToko }}
                />
            )}
        </>
    );
};

export default ModalImport;
