import {
    Card,
    Box,
    Typography,
    Stack,
    ClickAwayListener,
    IconButton,
    Tooltip,
    Skeleton,
} from "@mui/material";
import React from "react";
import HelpIcon from "@mui/icons-material/Help";

interface ICardLaporanKalkulasiProps {
    namaCard: string;
    onClickAway: () => void;
    tooltipTitle: string;
    onClose: () => void;
    isOpenTooltip: boolean;
    onClickTooltip: () => void;
    isiCard: string;
    icon: React.ReactNode;
    isLoading?: React.ReactNode;
}

const CardLaporanKalkulasi = ({
    namaCard,
    onClickAway,
    tooltipTitle,
    onClose,
    isOpenTooltip,
    onClickTooltip,
    isiCard,
    icon,
    isLoading,
}: ICardLaporanKalkulasiProps) => {
    return (
        <Card
            variant="outlined"
            sx={{
                display: "flex",
                alignItems: "center",
                paddingY: 1,
                width: "100%",
            }}
        >
            <Box display="flex" alignItems="center" marginLeft={3}>
                <Box
                    sx={{
                        position: "relative",
                        // bgcolor: "primary.light",
                        bgcolor: "white",
                        // filter: "brightness(300%)",
                        width: 60,
                        height: 60,
                        borderRadius: "50%",
                        marginRight: 4,
                    }}
                >
                    {icon}
                </Box>
                <Box justifyContent="space-between">
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="subtitle2">{namaCard}</Typography>
                        {tooltipTitle && (
                            <ClickAwayListener onClickAway={onClickAway}>
                                <Tooltip
                                    title={tooltipTitle}
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={onClose}
                                    open={isOpenTooltip}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                >
                                    <IconButton
                                        disableRipple
                                        disableFocusRipple
                                        size="small"
                                        onClick={onClickTooltip}
                                        color="primary"
                                    >
                                        <HelpIcon fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                            </ClickAwayListener>
                        )}
                    </Stack>
                    {isLoading ? null : (
                        <Typography fontSize={18} fontWeight="bold">
                            {isiCard}
                        </Typography>
                    )}
                    {isLoading ? (
                        <Skeleton variant="text" sx={{ fontSize: 18 }} />
                    ) : null}
                </Box>
            </Box>
        </Card>
    );
};

export default CardLaporanKalkulasi;
