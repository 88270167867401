import React from "react";
import SyaratKetentuan from "../../components/SyaratKetentuan/SyaratKetentuan";

interface ISyaratKetentuanPageProps {}

const SyaratKetentuanPage = (props: ISyaratKetentuanPageProps) => {
  return <SyaratKetentuan />;
};

export default SyaratKetentuanPage;
