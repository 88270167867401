"use client";

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ThemeDefaultComponent from "../ThemeDefaultComponent/ThemeDefaultComponent";
import LoginImage from "../../assets/images/IlustrasiDaftar.png";
import LogoUlo from "../LogoUlo/LogoUlo";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
    Link,
    useMediaQuery,
} from "@mui/material";
import Stack from "@mui/material/Stack";

export interface ISyaratKetentuanProps {}

export default function SyaratKetentuan(props: ISyaratKetentuanProps) {
    const height = "100px";
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up("xl"));
    const thisYear = new Date().getFullYear();

    return (
        <>
            <ThemeDefaultComponent>
                <Box
                    padding={3}
                    display={"flex"}
                    justifyContent={"center"}
                    sx={{ backgroundColor: "#45A779" }}
                >
                    <Box
                        sx={{
                            minHeight: "930px",
                            width: isTabletScreen ? "80%" : "50%",
                            position: undefined,
                            top: undefined,
                            left: undefined,
                            transform: undefined,
                            backgroundColor: "#FFF",
                            // display:
                            //     isPhoneScreen || isTabletScreen ? "flex" : "grid",
                            justifyContent: "center",
                            // gridTemplateColumns: isPhoneScreen
                            //     ? undefined
                            //     : "1fr 1fr",
                            borderRadius: 1,
                            paddingX: isTabletScreen ? 2 : undefined,
                            paddingY: 2,
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            paddingY={3}
                            paddingX={isTabletScreen ? 2 : 4}
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: "inherit",
                            }}
                        >
                            <Link
                                href="https://ukmlokal.com/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <LogoUlo width="100px" />
                            </Link>
                            {isLaptopScreen ? (
                                <Typography
                                    marginTop={2}
                                    component="div"
                                    textAlign="center"
                                    variant="h6"
                                    fontWeight="bold"
                                >
                                    Bergabung dengan UKM{" "}
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        sx={{ color: "#20965F" }}
                                        display="inline"
                                    >
                                        LOKAL
                                    </Typography>
                                </Typography>
                            ) : (
                                <React.Fragment>
                                    <Typography
                                        marginTop={2}
                                        textAlign="center"
                                        variant="h6"
                                        fontWeight="bold"
                                    >
                                        Bergabung dengan
                                    </Typography>
                                    <Typography
                                        component="div"
                                        textAlign="center"
                                        variant="h6"
                                        fontWeight="bold"
                                    >
                                        UKM{" "}
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            sx={{ color: "#20965F" }}
                                            display="inline"
                                        >
                                            LOKAL
                                        </Typography>
                                    </Typography>{" "}
                                </React.Fragment>
                            )}

                            <Box
                                component="main"
                                sx={{
                                    // backgroundColor: "#E2FCF9",
                                    // paddingTop: `calc(${height} + 24px)`,
                                    // paddingTop: {xs: `calc(${height} + 70px)`, sm: `calc(${height} + 24px)` },
                                    // paddingBottom: `calc(${height} + 24px)`,
                                    // minHeight: "100vh",
                                }}
                            >
                                <Container maxWidth="xl">
                                    <Typography
                                        component="h1"
                                        color="#464646"
                                        fontWeight={700}
                                        fontSize={32}
                                        sx={{
                                            mt: 5,
                                            mb: 2,
                                        }}
                                    >
                                        Syarat dan Ketentuan ULO
                                    </Typography>

                                    <Box width="100%">
                                        <Typography
                                            component="h4"
                                            color="#464646"
                                            fontWeight={700}
                                            fontSize={25}
                                            sx={{
                                                mb: 1,
                                            }}
                                        >
                                            PENGAKUAN DAN PERSETUJUAN 
                                        </Typography>

                                        <Stack
                                            spacing={2}
                                        >
                                            <Typography>
                                                Dengan menyetujui Pemberitahuan Privasi, 
                                                Anda mengakui bahwa Anda telah membaca dan memahami Pemberitahuan Privasi ini serta menyetujui segala ketentuannya. 
                                                Secara khusus, Anda sepakat dan memberikan persetujuan kepada Kami untuk Memproses Data Pribadi Anda sesuai dengan Pemberitahuan Privasi ini.
                                            </Typography>

                                            <Typography>
                                                Jika Anda menyediakan kepada Kami Data Pribadi yang berkaitan dengan individu lain (seperti Data Pribadi yang berkaitan dengan pasangan Anda, anggota keluarga, teman, atau pihak lain), maka Anda menyatakan dan menjamin bahwa Anda telah memperoleh persetujuan dari individu tersebut, dan dengan ini menyetujui atas nama individu tersebut, untuk 
                                                Pemrosesan Data Pribadi mereka oleh Kami. 
                                                Kami dapat meminta bukti dari persetujuan tersebut kepada anda setiap saat.
                                            </Typography>

                                            <Typography>
                                                Anda dapat menarik persetujuan Anda untuk setiap atau segala Pemrosesan Data Pribadi Anda kapan saja dengan memberikan kepada Kami pemberitahuan yang wajar secara tertulis menggunakan rincian kontak yang disebutkan di Pemberitahuan Privasi ini. 
                                                Anda juga dapat menarik persetujuan untuk Kami mengirimkan komunikasi tertentu melalui fasilitas "opt-out", 
                                                pilihan "berhenti berlangganan" yang tersedia dalam pesan yang Kami kirimkan, atau menggunakan opsi yang tersedia pada tiap-tiap media komunikasi yang 
                                                Kami gunakan untuk menghubungi Anda. Anda harus memahami dan mengakui bahwa setelah penarikan persetujuan tersebut, Anda mungkin tidak lagi dapat menggunakan Aplikasi atau layanan. 
                                                Penarikan persetujuan Anda dapat mengakibatkan penghentian layanan, penghapusan akun atau pengakhiran hubungan kontraktual Anda dengan Kami, dengan semua hak dan kewajiban yang muncul tetap dipenuhi sepenuhnya. Setelah menerima permohonan penarikan persetujuan dari Anda, 
                                                Kami akan menginformasikan Anda tentang konsekuensi yang mungkin terjadi dari penarikan tersebut sehingga Anda dapat memutuskan apakah Anda tetap ingin menarik persetujuan.
                                            </Typography>
                                        </Stack>

                                        <Typography
                                            component="h4"
                                            color="#464646"
                                            fontWeight={700}
                                            fontSize={25}
                                            sx={{
                                                mt: 3,
                                                mb: 2,
                                            }}
                                        >
                                            RINGKASAN
                                        </Typography>

                                        <Stack
                                            spacing={1}
                                        >
                                            <Typography
                                                fontWeight={600}
                                            >
                                                Data apa yang Kami kumpulkan tentang Anda?
                                            </Typography>

                                            <Typography>
                                                Kami mengumpulkan Data Pribadi saat Anda menggunakan Aplikasi Kami, termasuk data identitas, data kontak, data kelayakan, data transaksi, data keuangan, data pembayaran, data catatan (log) dan/atau data lokasi.
                                                Kami juga mengumpulkan data perangkat dan teknis dari Anda, dan data lain yang mungkin Anda sampaikan atau kirimkan saat Anda menggunakan Aplikasi Kami. 
                                                Jika Anda memilih untuk tidak menyediakan data tersebut,
                                                Kami mungkin tidak dapat menyediakan layanan dan Aplikasi kepada Anda secara optimal. 
                                            </Typography>
                                        </Stack>

                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography
                                                fontWeight={600}
                                            >
                                                Bagaimana Kami menggunakan data tentang Anda?
                                            </Typography>

                                            <Typography>
                                                Kami menggunakan Data Pribadi Anda untuk mengatur dan mengelola akun Anda dengan Kami, 
                                                berkomunikasi dengan Anda, serta untuk menyediakan berbagai fitur, 
                                                layanan dan fungsi bagi Anda pada Aplikasi Kami.

                                            </Typography>

                                            <Typography>
                                                Jika Anda adalah penyedia layanan, Kami menggunakan data Anda untuk memverifikasi bahwa Anda dapat menyediakan layanan Anda kepada pengguna, 
                                                mengatur dan mengelola akun Anda pada Aplikasi Kami, berkomunikasi dengan Anda, 
                                                dan sebaliknya memberi Anda berbagai layanan dan fungsi yang tersedia di Aplikasi Kami.
                                            </Typography>

                                            <Typography>
                                                Kami juga menggunakan data Anda untuk melakukan pemeliharaan atas Aplikasi Kami, 
                                                dan untuk menyesuaikan produk dan layanan Kami dengan preferensi Anda. Selain itu, 
                                                Kami menggunakan data Anda untuk memasarkan produk dan layanan Kami kepada Anda, dan dari perusahaan dalam afiliasi Kami, mitra, 
                                                dan agen (dengan persetujuan Anda jika diwajibkan oleh Peraturan Perundang-undangan Yang Berlaku). 
                                            </Typography>
                                        </Stack>
                                        
                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography
                                                fontWeight={600}
                                            >
                                                Dengan siapa Kami membagikan data Anda?
                                            </Typography>

                                            <Typography>
                                                Kami membagikan Data Pribadi Anda dengan mitra pihak ketiga Kami untuk memfasilitasi kinerja layanan untuk atau oleh Anda, 
                                                sejauh yang diperlukan bagi mereka untuk menyediakan layanan mereka, termasuk namun tidak terbatas pada, pemrosesan pembayaran, 
                                                pengiriman barang, penyediaan produk, klaim dan verifikasi asuransi.
                                            </Typography>

                                            <Typography>
                                                Kami menggunakan layanan pihak ketiga ini semata-mata untuk 
                                                mengolah dan/atau menyimpan data Anda untuk tujuan sebagaimana dijelaskan dalam 
                                                Pemberitahuan Privasi ini. Kami juga membagikan data Anda dengan afiliasi Kami untuk tujuan yang dijelaskan dalam 
                                                Pemberitahuan Privasi ini dan dengan lembaga pemerintah dan otoritas pemerintah lainnya sebagaimana diwajibkan oleh Peraturan Perundang-undangan yang Berlaku. 
                                            </Typography>

                                            <Typography>
                                                Kami juga mungkin akan memberikan akses dengan menampilkan Data Pribadi Anda kepada 
                                                Pengguna lain untuk memfasilitasi komunikasi dan/atau pelaksanaan pemenuhan layanan di 
                                                Aplikasi Kami.
                                            </Typography>
                                        </Stack>

                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography
                                                fontWeight={600}
                                            >
                                                Dimana Kami memproses data Anda?
                                            </Typography>

                                            <Typography>
                                                Kami Memproses Data Pribadi Anda di beberapa lokasi, baik di Indonesia maupun di luar wilayah Indonesia. Kami juga dapat mentransfer data Anda pada afiliasi Kami 
                                                dan mitra pihak ketiga di luar wilayah Indonesia untuk tujuan sebagaimana ditetapkan dalam Pemberitahuan Privasi ini. 
                                            </Typography>
                                        </Stack>
                                        
                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography
                                                fontWeight={600}
                                            >
                                                Berapa lama Kami memproses data Anda?
                                            </Typography>

                                            <Typography>
                                                Kami Memproses Data Pribadi Anda selama Anda memberikan persetujuan dan diperlukan 
                                                untuk memenuhi tujuan perolehan dan pengumpulannya, 
                                                atau sebagaimana diwajibkan oleh Peraturan Perundang-undangan yang Berlaku. 
                                            </Typography>
                                        </Stack>

                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography
                                                fontWeight={600}
                                            >
                                                Bagaimana cara Anda dapat melaksanakan hak Anda atas Data Pribadi Anda?
                                            </Typography>

                                            <Typography>
                                                Anda mungkin memiliki berbagai hak sehubungan dengan Data Pribadi Anda
                                                berdasarkan Peraturan Perundang-undangan yang Berlaku.
                                            </Typography>

                                            <Typography>
                                                Jika Anda ingin mengajukan permohonan pelaksanaan hak Anda, 
                                                Anda dapat mengajukan permohonan hak subjek data kepada Kami dengan 
                                                menghubungi Kami pada kontak yang telah disediakan di Pemberitahuan Privasi ini.
                                                Secara khusus untuk permohonan penghapusan akun Anda, hal tersebut dapat Anda lakukan melalui situs 
                                                web yang telah disediakan di Pemberitahuan Privasi ini.
                                            </Typography>
                                        </Stack>

                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography
                                                fontWeight={600}
                                            >
                                                Hubungi Kami
                                            </Typography>

                                            <Typography>
                                                Apabila Anda memiliki pertanyaan, perhatian atau keluhan, dapat menghubungi Kami melalui rincian kontak yang dapat ditemukan pada bagian Cara Menghubungi Kami.
                                            </Typography>
                                        </Stack>

                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography
                                                fontWeight={600}
                                            >
                                                Bagaimana Kami akan memberitahu Anda terkait perubahan Pemberitahuan Privasi ini?
                                            </Typography>

                                            <Typography>
                                                Kami akan mengubah Pemberitahuan Privasi ini dari waktu ke waktu dan memberitahu Anda tentang versi yang diperbarui melalui kanal yang relevan, 
                                                diantaranya, Aplikasi Kami atau email Anda yang terdaftar pada Kami. 
                                                Silakan periksa kembali secara berkala untuk melihat pembaruan atau perubahan apapun pada Pemberitahuan Privasi ini. 
                                            </Typography>

                                            <Typography>
                                                Penjelasan lebih lanjut tentang hal di atas dapat dibaca pada bagian di bawah ini.
                                            </Typography>
                                        </Stack>

                                        <Typography
                                            component="h4"
                                            color="#464646"
                                            fontWeight={700}
                                            fontSize={22}
                                            sx={{
                                                mt: 4,
                                                mb: 2,
                                            }}
                                        >
                                            1. JENIS-JENIS DATA PRIBADI YANG KAMI KUMPULKAN TENTANG ANDA
                                        </Typography>

                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography>
                                                “Data Pribadi” berarti data apa pun, baik yang benar maupun tidak, mengenai individu yang teridentifikasi atau dapat diidentifikasi, baik secara individu maupun gabungan dengan informasi lain, baik secara langsung maupun tidak langsung melalui sistem elektronik atau non-elektronik. 
                                                Data Pribadi termasuk, namun tidak terbatas pada, nama, alamat, tanggal lahir, pekerjaan, nomor telepon, alamat email, rekening bank dan rincian kartu kredit, jenis kelamin, identifikasi (termasuk paspor atau dokumen identitas nasional) atau tanda pengenal lainnya yang dikeluarkan pemerintah, 
                                                data kendaraan, foto, kewarganegaraan, nomor telepon Pengguna dan non-Pengguna di daftar kontak telepon seluler Anda, data kesehatan, data terkait keuangan, data biometrik (termasuk namun tidak terbatas pada pengenalan sidik jari dan pengenalan wajah), 
                                                dan data lainnya yang termasuk sebagai Data Pribadi sesuai dengan peraturan perundang-undangan yang berlaku. Untuk menghindari keragu-raguan, peraturan perundang-undangan yang berlaku berarti seluruh undang-undang yang berlaku, anggaran rumah tangga, perundangan, peraturan, kebijakan peraturan, 
                                                ordonansi, protokol, kode industri, kode lalu lintas jalan, izin peraturan atau persyaratan dari pengadilan, tribunal, atau pemerintah apa pun, pengesahan, peraturan, peradilan, administratif atau otoritas atau badan pengawas, yang berlaku dari waktu ke waktu selama jangka waktu Pemberitahuan Privasi ini (“Peraturan Perundang-undangan yang Berlaku”). 
                                                Selain itu, data-data lain, termasuk profil pribadi, dan/atau pengenal unik, dikaitkan atau digabungkan dengan Data Pribadi, maka data tersebut juga merupakan Data Pribadi.
                                            </Typography>

                                            <Typography>
                                                Jenis Data Pribadi yang Kami kumpulkan bergantung pada keadaan pengumpulan 
                                                dan sifat layanan yang diminta atau transaksi yang dilakukan.
                                            </Typography>

                                            <Typography>
                                                Sepanjang diizinkan oleh Peraturan Perundang-undangan yang Berlaku, Kami dapat memproses, mengumpulkan, menggunakan, 
                                                menyimpan dan mentransfer jenis-jenis berbeda dari Data Pribadi, yang terdiri dari Data Pribadi umum dan spesifik/sensitif tentang Anda yang telah Kami kategorikan sebagai berikut:
                                            </Typography>

                                            <Typography>
                                                a. Data Identitas termasuk nama, nama pengguna (username), kartu tanda penduduk, nomor pokok wajib pajak, 
                                                identitas Pengguna atau pengenal lainnya, jabatan, tanggal kelahiran, jenis kelamin, 
                                                tempat kelahiran, pekerjaan, kebangsaan, foto dan/atau data biometrik.
                                            </Typography>

                                            <Typography>
                                                b. Data Kontak termasuk alamat (-alamat) penagihan, alamat (-alamat) pengiriman dan labelnya, alamat (-alamat) email, nomor telepon dan daftar kontak. 
                                            </Typography>

                                            <Typography>
                                                c. Data Transaksi mencakup pembelian atau pesanan yang Anda lakukan, minat, preferensi, masukan, dan tanggapan survei Anda, sehubungan dengan Pengguna, 
                                                jenis layanan yang dicari, perincian mengenai pengambilan dan/atau pengiriman, dan jenis layanan yang dapat diterima pada saat itu.
                                            </Typography>

                                            <Typography>
                                                Kami dapat membuat, menggunakan, melisensikan, atau mengungkapkan Data Gabungan seperti data statistik atau demografi untuk tujuan apa pun. 
                                                Data Gabungan dapat berasal dari Data Pribadi Anda tetapi tidak akan dianggap sebagai Data Pribadi karena data ini tidak akan secara langsung 
                                                atau tidak langsung mengungkapkan identitas Anda karena Kami akan memastikan: (i) bahwa semua pengenal telah dihapus sedemikian rupa sehingga data, 
                                                sendiri atau bersama-sama dengan data lain yang tersedia, tidak dapat dikaitkan atau dihubungkan dengan atau tidak dapat mengidentifikasi siapapun, dan (ii) data tersebut 
                                                kemudian digabungkan dengan data serupa sehingga data asli membentuk bagian dari kumpulan data yang lebih besar.
                                            </Typography>
                                        </Stack>

                                        <Typography
                                            component="h4"
                                            color="#464646"
                                            fontWeight={700}
                                            fontSize={22}
                                            sx={{
                                                mt: 3,
                                                mb: 2,
                                            }}
                                        >
                                            2. JIKA ANDA MENYEDIAKAN DATA PRIBADI YANG TIDAK LENGKAP
                                        </Typography>

                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography>
                                                Ketika Kami perlu mengumpulkan Data Pribadi berdasarkan hukum, 
                                                atau berdasarkan ketentuan perjanjian yang Kami miliki dengan Anda, dan Anda memilih untuk tidak menyediakan Data Pribadi tersebut atau menyediakan Data Pribadi yang tidak lengkap kepada Kami saat diminta, 
                                                Kami mungkin tidak dapat menyediakan layanan dan melaksanakan perjanjian yang ada atau yang sedang dalam proses untuk Kami sepakati dengan Anda.
                                            </Typography>
                                        </Stack>

                                        <Typography
                                            component="h4"
                                            color="#464646"
                                            fontWeight={700}
                                            fontSize={22}
                                            sx={{
                                                mt: 3,
                                                mb: 2,
                                            }}
                                        >
                                            3. BAGAIMANA KAMI MENGUMPULKAN DATA PRIBADI ANDA
                                        </Typography>

                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography>
                                                Data Pribadi yang Kami kumpulkan dapat diberikan oleh Anda secara langsung atau oleh pihak ketiga 
                                                (misalnya: ketika Anda mendaftar atau menggunakan Aplikasi, ketika Anda menghubungi layanan pelanggan Kami, 
                                                atau sebaliknya ketika Anda menyediakan Data Pribadi kepada Kami). Kami dapat mengumpulkan data dalam berbagai macam bentuk dan tujuan 
                                                (termasuk tujuan yang diizinkan berdasarkan Peraturan Perundang-undangan yang Berlaku).
                                            </Typography>

                                            <Typography
                                                fontWeight={600}
                                            >
                                                a. Data yang Kami kumpulkan dari Anda secara langsung
                                            </Typography>

                                            <Typography>
                                                Anda dapat memberikan kepada Kami Data Identitas, Data Kontak, Data Transaksi, 
                                                Data Kelayakan, Data Keuangan dan Data Pembayaran, termasuk dalam keadaan di mana hal tersebut diminta oleh Kami atau dalam keadaan diharuskan oleh Peraturan Perundang-undangan yang Berlaku, 
                                                saat berinteraksi dengan Kami secara langsung atau berkorespondensi dengan Kami melalui surat, email atau lainnya. Hal ini termasuk Data Pribadi yang Anda sediakan ketika Anda:
                                            </Typography>

                                            <Typography>
                                                i. mendaftar dan membuat sebuah akun menggunakan Aplikasi;
                                            </Typography>

                                            <Typography>
                                                ii. menggunakan Aplikasi, termasuk jika Anda mendaftar sebagai penyedia layanan atau menggunakan layanan sebagai pengguna;
                                            </Typography>

                                            <Typography>
                                                iii. melakukan pembayaran dengan menggunakan metode yang tersedia termasuk namun tidak terbatas pembayaran menggunakan kartu kredit, virtual account, transfer perbankan, fasilitas uang elektronik dan/atau dompet elektronik (baik sebagai pembayar atau penerima);
                                            </Typography>

                                            <Typography>
                                                iv. mendaftar sebagai pemilik akun terdaftar atau terverifikasi dari sebuah fasilitas uang elektronik;
                                            </Typography>

                                            <Typography>
                                                v. menambahkan sumber pendanaan untuk pembayaran pada Aplikasi;
                                            </Typography>

                                            <Typography>
                                                vi. menggunakan fitur chat pada Aplikasi; dan
                                            </Typography>

                                            <Typography>
                                                vii. memberikan masukan atau menghubungi Kami.
                                            </Typography>
                                        </Stack>

                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography
                                                fontWeight={600}
                                            >
                                                b. Data yang dikumpulkan setiap kali Anda menggunakan Aplikasi
                                            </Typography>

                                            <Typography>
                                                Anda dapat memberi Kami Data Teknis, Data Perangkat, Data Catatan, dan/atau Data Lokasi, setiap kali Anda menggunakan dan/atau mengunjungi Aplikasi. 
                                                Kami juga dapat secara berkala mengumpulkan, memproses, membagikan dan menyimpan Data Aplikasi yang Terinstal. 
                                                Setiap kali Anda mengakses Aplikasi melalui perangkat seluler Anda, Kami akan melacak dan mengumpulkan data lokasi geografis Anda secara waktu-nyata (real-time). 
                                                Dalam beberapa situasi, Anda akan diminta untuk mengaktifkan Global Positioning System (GPS) pada perangkat seluler Anda agar Kami dapat memberikan Anda pengalaman yang lebih baik dalam menggunakan Aplikasi (misalnya, untuk memberi Anda data tentang seberapa dekat penyedia layanan dengan Anda). 
                                                Anda selalu dapat memilih untuk menonaktifkan data pelacakan lokasi geografis pada perangkat seluler Anda untuk sementara. Namun, hal itu dapat mempengaruhi fungsi yang tersedia pada Aplikasi.
                                            </Typography>

                                            <Typography>
                                                Jika Anda menggunakan dan/atau ketika pembayaran atau transfer dilakukan melalui fasilitas uang elektronik dan/atau dompet elektronik yang tersedia pada Aplikasi Kami (baik sebagai pembayar atau penerima), 
                                                Kami dapat mengumpulkan Data Keuangan dan Data Pembayaran tertentu terkait dengan pembayaran dan transfer tersebut.
                                            </Typography>

                                            <Typography>
                                                Selain itu, setiap kali Anda menggunakan dan/atau mengunjungi Aplikasi, informasi tertentu juga dapat dikumpulkan secara otomatis menggunakan cookies. 
                                                Cookies adalah file aplikasi kecil yang disimpan pada komputer atau perangkat seluler Anda. 
                                                Kami menggunakan cookies untuk melacak aktivitas Anda untuk meningkatkan antarmuka dan pengalaman Anda. 
                                                Sebagian besar perangkat seluler dan browser internet mendukung penggunaan cookies; tetapi Anda dapat menyesuaikan pengaturan pada perangkat seluler atau 
                                                browser internet Anda untuk menolak beberapa jenis cookies tertentu atau cookies spesifik tertentu. perangkat seluler dan/atau browser Anda juga memungkinkan Anda untuk menghapus cookies apa pun yang sebelumnya telah disimpan kapan pun. Namun, hal itu dapat memengaruhi fungsi yang tersedia pada Aplikasi.
                                            </Typography>

                                            <Typography>
                                                Kami juga mungkin menggunakan fitur-fitur yang disediakan oleh pihak ketiga dalam rangka meningkatkan layanan dan konten Kami, termasuk diantaranya ialah penilaian, penyesuaian, dan penyajian penawaran kepada Anda berdasarkan minat atau riwayat kunjungan Anda. 
                                                Jika Anda tidak ingin penawaran ditampilkan berdasarkan penyesuaian tersebut, maka Anda dapat mengaturnya melalui browser.
                                            </Typography>
                                        </Stack>

                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography
                                                fontWeight={600}
                                            >
                                                c. Data yang dikumpulkan dari pihak ketiga
                                            </Typography>

                                            <Typography>
                                                Kami juga dapat mengumpulkan Data Pribadi Anda dari pihak ketiga (termasuk agen, vendor, pemasok, kontraktor, mitra, institusi pemerintah dan pihak lainnya yang menyediakan layanan kepada Kami, 
                                                mengumpulkan Data Pribadi dan/atau melakukan tugas atas nama Kami, atau dengan pihak yang melakukan kerja sama dengan Kami).
                                                 Dalam kondisi tersebut, Kami hanya akan mengumpulkan Data Pribadi Anda untuk atau sehubungan dengan tujuan yang melibatkan pihak ketiga tersebut atau tujuan kerja sama Kami dengan pihak ketiga tersebut (tergantung pada kondisinya). 
                                                Khususnya pada saat Anda mendaftarkan kartu pembayaran atau rekening melalui Aplikasi dan/atau mengakses, menambahkan dan/atau menghubungkan kartu pembayaran atau rekening Anda pada Aplikasi (sebagaimana berlaku di negara Anda), Kami dapat mengumpulkan informasi keuangan tertentu 
                                                dan catatan finansial Anda (termasuk tetapi tidak terbatas pada catatan transaksi, rincian dan penempatan kartu pembayaran atau rekening, dan/atau status dan negara kartu pembayaran atau rekening Anda) dari penerbit kredensial pembayaran tersebut atau dari pihak ketiga lainnya.
                                            </Typography>
                                        </Stack>

                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography
                                                fontWeight={600}
                                            >
                                                d. Data tentang pihak ketiga yang Anda berikan kepada Kami
                                            </Typography>

                                            <Typography>
                                                Anda dapat memberikan kepada Kami Data Pribadi yang berkaitan dengan individu pihak ketiga lainnya (termasuk Data Pribadi yang berkaitan dengan pasangan Anda, 
                                                anggota keluarga, teman, atau individu lain). Dalam kondisi tersebut, Anda tentu saja akan memerlukan persetujuan 
                                                dari individu pihak ketiga lainnya tersebut  —lihat “Pengakuan dan Persetujuan” di atas, untuk  informasi lebih lanjut.
                                            </Typography>

                                            <Typography>
                                                Jika Anda menggunakan fitur pesan di Aplikasi perangkat seluler Kami, Anda akan menyediakan nomor telepon yang tersimpan di dalam kontak 
                                                telepon selular Anda untuk menjadikan Anda dapat menggunakan fitur pesan Kami serta tujuan lainnya sebagaimana relevan.
                                            </Typography>
                                        </Stack>

                                        <Typography
                                            component="h4"
                                            color="#464646"
                                            fontWeight={700}
                                            fontSize={22}
                                            sx={{
                                                mt: 3,
                                                mb: 2,
                                            }}
                                        >
                                            4. BAGAIMANA KAMI MENGGUNAKAN DATA PRIBADI ANDA
                                        </Typography>

                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography>
                                                Kami dapat menggunakan Data Pribadi yang dikumpulkan untuk tujuan sebagai berikut maupun untuk tujuan lain yang 
                                                diizinkan oleh Peraturan Perundang-undangan yang Berlaku ("Tujuan"):
                                            </Typography>
                                        </Stack>

                                        <Typography
                                            component="h4"
                                            color="#464646"
                                            fontWeight={700}
                                            fontSize={22}
                                            sx={{
                                                mt: 3,
                                                mb: 2,
                                            }}
                                        >
                                            5. KEAMANAN DATA
                                        </Typography>

                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography>
                                                Kerahasiaan Data Pribadi Anda adalah hal yang terpenting bagi Kami. 
                                                Kami akan memberlakukan upaya terbaik untuk melindungi dan mengamankan Data Pribadi Anda dari akses, pengumpulan, penggunaan atau pengungkapan oleh orang-orang yang tidak berwenang dan dari pengolahan yang
                                                bertentangan dengan hukum, kehilangan yang tidak disengaja, pemusnahan dan kerusakan atau risiko serupa. Namun, dikarenakan pengiriman data melalui internet tidak sepenuhnya aman, Kami tidak dapat sepenuhnya menjamin 
                                                bahwa Data Pribadi tersebut tidak akan dicegat, diakses, diungkapkan, diubah
                                                atau dihancurkan oleh pihak ketiga yang tidak berwenang, karena faktor-faktor di luar kendali Kami. 
                                                Anda bertanggung jawab untuk menjaga kerahasiaan detail akun Anda dan Anda wajib untuk tidak membagikan detail akun Anda, termasuk kata sandi Anda dan One Time Password (OTP) 
                                                dengan siapapun, dan Anda harus selalu menjaga dan bertanggung jawab atas keamanan perangkat yang Anda gunakan.
                                            </Typography>
                                        </Stack>

                                        <Typography
                                            component="h4"
                                            color="#464646"
                                            fontWeight={700}
                                            fontSize={22}
                                            sx={{
                                                mt: 3,
                                                mb: 2,
                                            }}
                                        >
                                            6. DATA ANONIM
                                        </Typography>
                                        
                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography>
                                                Kami dapat membuat, menggunakan, melisensikan atau mengungkapkan Data Pribadi, yang tersedia, dengan catatan, 
                                                (i) bahwa semua hal yang dapat mengidentifikasi telah dihapus sehingga data, baik sendiri atau dikombinasi dengan data lain yang tersedia, 
                                                tidak dapat dihubungkan dengan atau dikaitkan dengan atau tidak dapat mengidentifikasi suatu individu,
                                                dan (ii) data serupa telah digabungkan sehingga data asli membentuk bagian dari kumpulan data yang lebih besar.
                                            </Typography>
                                        </Stack>

                                        <Typography
                                            component="h4"
                                            color="#464646"
                                            fontWeight={700}
                                            fontSize={22}
                                            sx={{
                                                mt: 3,
                                                mb: 2,
                                            }}
                                        >
                                            7. HAK HUKUM ANDA
                                        </Typography>

                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography>
                                                Anda mungkin memiliki hak tertentu berdasarkan Peraturan Perundang-undangan yang Berlaku untuk 
                                                meminta kepada Kami terhadap akses kepada, koreksi dari dan/atau penghapusan terhadap 
                                                Data Pribadi Anda yang berada dalam penguasaan dan kendali Kami. 
                                                Anda dapat menggunakan hak-hak ini dengan menghubungi Kami pada kontak yang telah disediakan di Pemberitahuan Privasi ini. 
                                            </Typography>

                                            <Typography>
                                                Kami dapat menolak permintaan Anda terhadap akses kepada, koreksi dari dan/atau penghapusan terhadap, 
                                                sebagian atau semua Data Pribadi Anda yang Kami kuasai atau kendalikan jika diwajibkan atau diperlukan 
                                                berdasarkan Peraturan Perundang-undangan yang Berlaku. Hal ini termasuk dalam keadaan di mana Data Pribadi tersebut dapat berisi referensi 
                                                kepada orang lain atau di mana permintaan untuk akses atau permintaan untuk mengoreksi atau menghapus adalah untuk alasan yang Kami anggap tidak relevan,
                                                tidak serius, atau mengada-ada atau terindikasi terkait tindakan pelanggaran ketentuan penggunaan atau dengan aktivitas atau pelanggaran hukum.
                                            </Typography>

                                            <Typography>
                                                Sesuai Peraturan Perundang-undangan yang Berlaku, Kami memiliki hak untuk membebankan biaya administratif untuk setiap permintaan akses dan/atau koreksi.
                                            </Typography>
                                        </Stack>

                                        <Typography
                                            component="h4"
                                            color="#464646"
                                            fontWeight={700}
                                            fontSize={22}
                                            sx={{
                                                mt: 3,
                                                mb: 2,
                                            }}
                                        >
                                            8. PEMBAHARUAN TERHADAP PEMBERITAHUAN PRIVASI
                                        </Typography>
                                        
                                        <Stack
                                            marginTop={2}
                                            spacing={1}
                                        >
                                            <Typography>
                                                Kami dapat meninjau kembali dan mengubah Pemberitahuan Privasi ini atas kebijakan Kami sendiri dari waktu ke waktu, 
                                                untuk memastikan bahwa Pemberitahuan Privasi ini konsisten dengan perkembangan Kami di masa depan, dan/atau perubahan 
                                                persyaratan hukum atau peraturan. Jika Kami memutuskan untuk mengubah Pemberitahuan Privasi ini, 
                                                Kami akan memberitahu Anda tentang perubahan tersebut melalui kanal resmi Kami, atau ke alamat email Anda yang tercantum 
                                                dalam akun Anda. Anda setuju bahwa adalah tanggung jawab Anda untuk memeriksa Pemberitahuan Privasi ini secara 
                                                berkala untuk mengetahui informasi terbaru tentang pengolahan data dan praktik pelindungan data Kami, 
                                                dan bahwa dengan Anda terus menggunakan Aplikasi Kami, berkomunikasi dengan Kami, atau mengakses dan menggunakan layanan 
                                                setelah adanya perubahan apapun terhadap Pemberitahuan Privasi ini akan dianggap sebagai persetujuan Anda terhadap Pemberitahuan Privasi ini dan segala perubahannya.
                                            </Typography>
                                        </Stack>
                                    </Box>

                                    <Typography
                                        variant="caption"
                                        component="div"
                                        textAlign="center"
                                        marginTop={5}
                                    >
                                        <Link
                                            variant="caption"
                                            target="_blank"
                                            href="https://nore.web.id/"
                                            underline="hover"
                                        >
                                            Nore Inovasi
                                        </Link>{" "}
                                        {`© ${thisYear}. All right reserved`}
                                    </Typography>                 
                                </Container>
                            </Box>
                            
                        </Box>
                    </Box>
                </Box>
            </ThemeDefaultComponent>
        </>
    );
}
