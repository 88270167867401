import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef, GridPagination } from "@mui/x-data-grid";
import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useMemo, useState } from "react";
import NoRowsImage from "../NoRowsImage/NoRowsImage";
import LoadingTabel from "../LoadingTabel/LoadingTabel";
import toRupiah from "../../utils/toRupiah";
import { useAuth } from "../../context/authContext";
import {
    ContentGetListInvoice,
    ParamsGetListInvoice,
} from "../../constants/types";
import useListInvoice from "../../services/queries/useListInvoice";
import useUsersByUkm from "../../services/queries/useUsersByUkm";
import useMetodePembayaran from "../../services/queries/useMetodePembayaran";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import useUser from "../../services/queries/useUser";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ModalRevertInvoice from "../ModalRevertInvoice/ModalRevertInvoice";
import ModalDetailInvoice from "../ModalDetailInvoice/ModalDetailInvoice";
import { useTransaksi } from "../../context/transaksiContext";
import useUkm from "../../services/queries/useUkm";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";

moment.locales();
moment.locale("id");

interface ICardDaftarInvoiceProps {
    tanggalAwal: string;
    tanggalAkhir: string;
}

const CardDaftarInvoice = ({
    tanggalAwal,
    tanggalAkhir,
}: ICardDaftarInvoiceProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));
    let now = moment().format("YYYY/MM/DD");

    const { isFetchingItems, cancelFetchingItems } = useTransaksi();
    const { data: user } = useUser();
    const { ukmIdUser } = useAuth();
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const ukmId = useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const [id, setId] = useState<number>();
    const [searchDaftarInvoice, setSearchDaftarInvoice] = useState("");
    const [isOpenModalDetailInvoice, setIsOpenModalDetailInvoice] =
        useState(false);
    const [isOpenModalRevertInvoice, setIsOpenModalRevertInvoice] =
        useState(false);

    const openModalDetailInvoice = (id: number) => {
        setId(id);
        setIsOpenModalDetailInvoice(true);
    };
    const closeModalDetailInvoice = () => setIsOpenModalDetailInvoice(false);

    const openModalRevertInvoice = (id: number) => {
        setId(id);
        setIsOpenModalRevertInvoice(true);
    };
    const closeModalRevertInvoice = () => setIsOpenModalRevertInvoice(false);

    const initialParamsUsersByUkm = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 100,
        page: 1,
        search: "",
    };
    const [paramsUsersByUkm, setParamsUsersByUkm] = useState(
        initialParamsUsersByUkm,
    );
    const { data: usersByUkm } = useUsersByUkm(paramsUsersByUkm);

    const [paramsMetodePembayaran, setParamsMetodePembayaran] = useState(
        ukmIdUser && ukmIdUser[0],
    );

    const { data: metodePembayaran } = useMetodePembayaran(
        paramsMetodePembayaran,
    );

    const mappedMetodePembayaran = metodePembayaran?.map((li) => ({
        id: li.id,
        nama: li.namaMetode,
        ukmId: { id: li.ukmId.id, nama: li.ukmId.nama },
    }));

    const initialParamsListInvoice = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 10,
        page: 1,
        tanggalAwal: now,
        tanggalAkhir: now,
        kasir: "",
        sales: "",
        metodeId: "",
        voidInvoice: "",
    };

    const [paramsListInvoice, setParamsListInvoice] =
        useState<ParamsGetListInvoice>(initialParamsListInvoice);

    const {
        data: listInvoice,
        refetch: refetchListInvoice,
        isLoading: isLoadingListInvoice,
    } = useListInvoice(paramsListInvoice);

    const mappedUsersByUkm = usersByUkm?.content.map((li) => ({
        id: li.userId,
        nama: li.namaLengkap,
    }));

    const handleOnPaginationInvoice = (page: number) => {
        setParamsListInvoice((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChangeInvoice = (size: number) => {
        setParamsListInvoice((prev) => ({ ...prev, size }));
    };

    const columnsListInvoice: GridColDef[] = [
        {
            field: "tanggal",
            headerName: "Tanggal",
            headerAlign: "center",
            align: "left",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 200,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "nomorInvoice",
            headerName: "Invoice",
            headerAlign: "center",
            align: "center",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value}>
                        <Typography variant="subtitle2" fontWeight="bold">
                            {params.value}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "metode",
            headerName: "Metode",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 120,
            renderCell: (params) => (
                <Tooltip title={params.value ?? "Tunai"}>
                    <span className="table-cell-trucate">
                        {params.value ?? "Tunai"}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "sales",
            headerName: "Sales",
            headerAlign: "center",
            align: "center",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 150,
            renderCell: (params) => (
                <Tooltip
                    title={
                        params.value !== null ? params.value : "Tidak ada sales"
                    }
                >
                    <span className="table-cell-trucate">
                        {params.value !== null
                            ? params.value
                            : "Tidak ada sales"}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "hargaPenjualan",
            headerName: "Total Harga",
            headerAlign: "center",
            align: "right",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 150,
            renderCell: (params) => {
                const row: ContentGetListInvoice = params.row;
                return (
                    <Tooltip
                        title={toRupiah(params.value + row.pajak - row.diskon)}
                    >
                        <span className="table-cell-trucate">
                            {toRupiah(params.value + row.pajak - row.diskon)}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "diskon",
            headerName: "Diskon",
            headerAlign: "center",
            align: "right",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 120,
            renderCell: (params) => (
                <Tooltip title={toRupiah(params.value)}>
                    <span className="table-cell-trucate">
                        {toRupiah(params.value)}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "statusWebstore",
            headerName: "Status Webstore",
            headerAlign: "center",
            align: "center",
            flex: isPhoneScreen ? undefined : 1,
            minWidth: 160,
            renderCell: (params) => {
                const row: ContentGetListInvoice = params.row;
                if (row.statusWebstore.length > 0) {
                    return (
                        <Tooltip title={row.statusWebstore[0].status ?? ""}>
                            <span className="table-cell-trucate">
                                {row.statusWebstore[0].status ?? ""}
                            </span>
                        </Tooltip>
                    );
                }
                return "-";
            },
        },
        // {
        //     field: "uangYangDibayar",
        //     headerName: "Tunai",
        //     headerAlign: "center",
        //     align: "right",
        //     flex: isPhoneScreen ? undefined : 1,
        //     minWidth: 150,
        //     renderCell: (params) => {
        //         return (
        //             <Box marginY={1}>
        //                 <Typography variant="subtitle2">
        //                     {toRupiah(params.value)}\
        //                 </Typography>
        //             </Box>
        //         );
        //     },
        // },
        // {
        //     field: "kembalian",
        //     headerName: "Kembalian",
        //     headerAlign: "center",
        //     align: "right",
        //     flex: isPhoneScreen ? undefined : 1,
        //     minWidth: 150,
        //     renderCell: (params) => {
        //         return (
        //             <Box marginY={1}>
        //                 <Typography variant="subtitle2">
        //                     {toRupiah(params.value)}
        //                 </Typography>
        //             </Box>
        //         );
        //     },
        // },
        {
            field: "statusWebstore",
            headerName: "Status Webstore",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 160,
            renderCell: (params) => {
                const row: ContentGetListInvoice = params.row;
                if (row.statusWebstore.length > 0) {
                    return (
                        <Tooltip title={row.statusWebstore[0].status ?? ""}>
                            <span className="table-cell-trucate">
                                {row.statusWebstore[0].status ?? ""}
                            </span>
                        </Tooltip>
                    );
                }
                return "-";
            },
        },
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            minWidth: 160,
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                openModalDetailInvoice(params.row.id);
                            }}
                            size="small"
                            color="buttongreen"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <VisibilityIcon
                                sx={{ width: "16px", height: "16px" }}
                            />
                        </Button>
                        {user && user.akses.laporan.includes(32) && (
                            <Button
                                disabled={
                                    params.row.hargaPenjualan < 0 ||
                                    params.row.isReverted !== 0
                                }
                                variant="contained"
                                onClick={() => {
                                    openModalRevertInvoice(params.row.id);
                                }}
                                size="small"
                                color="buttonyellow"
                                disableElevation
                                sx={{
                                    minWidth: "unset",
                                    padding: "8px",
                                    width: "32px",
                                    height: "32px",
                                    // visibility:
                                    //     params.row.hargaPenjualan > 0 &&
                                    //     params.row.isReverted === 0
                                    //         ? "hidden"
                                    //         : undefined,
                                }}
                            >
                                <DeleteIcon
                                    sx={{ width: "16px", height: "16px" }}
                                />
                            </Button>
                        )}
                    </Stack>
                );
            },
        },
    ];

    useEffect(() => {
        if (ukmIdUser) {
            setParamsUsersByUkm((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
            setParamsListInvoice((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
            setParamsMetodePembayaran(ukmIdUser && ukmIdUser[0]);
        }
    }, [ukmIdUser]);

    useEffect(() => {
        if (tanggalAwal || tanggalAkhir) {
            setParamsListInvoice((prev) => ({
                ...prev,
                tanggalAwal: tanggalAwal,
                tanggalAkhir: tanggalAkhir,
            }));
        }
    }, [tanggalAkhir, tanggalAwal]);

    useEffect(() => {
        if (isFetchingItems) {
            refetchListInvoice();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchListInvoice]);

    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    borderRadius: "0 4px 4px 4px",
                }}
            >
                <CardContent>
                    <Stack
                        direction={{
                            xs: "column",
                            sm: "row",
                        }}
                        width="100%"
                        display="flex"
                        sx={{
                            alignItems: {
                                xs: "stretch",
                                sm: "center",
                            },
                            justifyContent: {
                                xs: "space-between",
                                sm: "space-between",
                            },
                        }}
                        spacing={2}
                    >
                        <Stack
                            direction={{
                                xs: "column",
                                sm: "row",
                            }}
                            sx={{
                                alignItems: {
                                    sx: "normal",
                                    sm: "center",
                                },
                            }}
                            spacing={{
                                xs: 1,
                                sm: 2,
                            }}
                        >
                            <Typography id="daftarInvoice" fontWeight="bold">
                                Daftar Invoice
                            </Typography>
                            <Typography variant="subtitle2">
                                {`Periode: ${moment(
                                    paramsListInvoice.tanggalAwal,
                                ).format("DD/MM/YYYY")} - ${
                                    paramsListInvoice.tanggalAkhir &&
                                    moment(
                                        paramsListInvoice.tanggalAkhir,
                                    ).format("DD/MM/YYYY")
                                }`}
                            </Typography>
                        </Stack>
                        <Stack
                            direction={{
                                xs: "column",
                                sm: "row",
                            }}
                            sx={{
                                alignItems: {
                                    sx: "normal",
                                    sm: "center",
                                },
                            }}
                            spacing={{
                                xs: 1,
                                sm: 2,
                            }}
                        >
                            <TextField
                                placeholder="Cari invoice..."
                                size="small"
                                value={searchDaftarInvoice}
                                onChange={(e) =>
                                    setSearchDaftarInvoice(e.target.value)
                                }
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        setParamsListInvoice((prev) => ({
                                            ...prev,
                                            search: searchDaftarInvoice,
                                            page: 1,
                                        }));
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <Link
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            onClick={() =>
                                                setParamsListInvoice(
                                                    (prev) => ({
                                                        ...prev,
                                                        search: searchDaftarInvoice,
                                                        page: 1,
                                                    }),
                                                )
                                            }
                                            sx={{
                                                cursor: "pointer",
                                            }}
                                        >
                                            <SearchIcon fontSize="small" />
                                        </Link>
                                    ),
                                }}
                                variant="outlined"
                            />
                        </Stack>
                    </Stack>
                    <Grid container spacing={2} marginY={2}>
                        <Grid item xs={12} md={3}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="demo-simple-select-helper-label">
                                    Pilih kasir
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="Pilih kasir"
                                    defaultValue=""
                                    onChange={(e) => {
                                        setParamsListInvoice((prev) => ({
                                            ...prev,
                                            kasir: String(e.target.value),
                                        }));
                                    }}
                                >
                                    <MenuItem value="">Semua</MenuItem>
                                    {mappedUsersByUkm &&
                                        mappedUsersByUkm.map((k) => (
                                            <MenuItem key={k.id} value={k.id}>
                                                {k.nama}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="demo-simple-select-helper-label">
                                    Pilih sales
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="Pilih sales"
                                    defaultValue=""
                                    onChange={(e) =>
                                        setParamsListInvoice((prev) => ({
                                            ...prev,
                                            sales: String(e.target.value),
                                        }))
                                    }
                                >
                                    <MenuItem value="">Semua</MenuItem>
                                    {mappedUsersByUkm &&
                                        mappedUsersByUkm.map((k) => (
                                            <MenuItem key={k.id} value={k.id}>
                                                {k.nama}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="demo-simple-select-helper-label">
                                    Pilih metode
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="Pilih metode"
                                    defaultValue=""
                                    onChange={(e) =>
                                        setParamsListInvoice((prev) => ({
                                            ...prev,
                                            metodeId: String(e.target.value),
                                        }))
                                    }
                                >
                                    <MenuItem value="">Semua</MenuItem>
                                    {mappedMetodePembayaran &&
                                        mappedMetodePembayaran.map((k) => (
                                            <MenuItem key={k.id} value={k.id}>
                                                {k.nama}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            paramsListInvoice.voidInvoice ===
                                            "void"
                                        }
                                        onChange={(_event, checked) => {
                                            if (checked) {
                                                setParamsListInvoice(
                                                    (prev) => ({
                                                        ...prev,
                                                        voidInvoice: "void",
                                                    }),
                                                );
                                            } else {
                                                setParamsListInvoice(
                                                    (prev) => ({
                                                        ...prev,
                                                        voidInvoice: "",
                                                    }),
                                                );
                                            }
                                        }}
                                        inputProps={{
                                            "aria-label": "controlled",
                                        }}
                                    />
                                }
                                label="Void Invoice?"
                            />
                        </Grid>
                    </Grid>
                    <Box
                        marginTop={2}
                        sx={{
                            height: 670,
                            width: "100%",
                            borderRadius: 1,
                            "& .headerColumn": {
                                backgroundColor: "#E4EEE8",
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontWeight: "bold",
                            },
                            "& .MuiDataGrid-columnSeparator": {
                                visibility: "hidden",
                            },
                            "& .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },
                            "& .MuiDataGrid-iconButtonContainer": {
                                visibility: "visible",
                                width: "0 !important",
                            },
                        }}
                    >
                        {/* <ThemeProvider theme={theme}> */}
                        <DataGrid
                            pagination
                            rows={listInvoice?.content ?? []}
                            columns={columnsListInvoice}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        statusWebstore:
                                            ukmData?.isWebstore === 1
                                                ? true
                                                : false,
                                    },
                                },
                            }}
                            components={{
                                ColumnUnsortedIcon: () => {
                                    return <ImportExportIcon />;
                                },
                                NoRowsOverlay: NoRowsImage,
                                LoadingOverlay: LoadingTabel,
                                Footer: () => (
                                    <>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                borderTop:
                                                    "1px solid rgba(0,0,0,0.12)",
                                                justifyContent: "flex-end",
                                                paddingY: 1,
                                                paddingX: 3,
                                            }}
                                        >
                                            <Typography fontWeight={500}>
                                                Pendapatan:{" "}
                                                {isLoadingListInvoice
                                                    ? "Rp. 0"
                                                    : toRupiah(
                                                          Number(
                                                              listInvoice?.totalHarga,
                                                          ),
                                                      )}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <GridPagination />
                                        </Box>
                                    </>
                                ),
                            }}
                            rowsPerPageOptions={[10]}
                            disableColumnMenu
                            disableSelectionOnClick
                            paginationMode="server"
                            onPageSizeChange={handleOnSizeChangeInvoice}
                            onPageChange={handleOnPaginationInvoice}
                            rowCount={listInvoice?.totalElements ?? 0}
                            page={paramsListInvoice.page - 1}
                            pageSize={paramsListInvoice.size}
                            loading={isLoadingListInvoice}
                        />
                        {/* </ThemeProvider> */}
                    </Box>
                </CardContent>
            </Card>
            <ModalDetailInvoice
                id={Number(id)}
                fullScreen={isTabletScreen}
                isOpenModalDetailInvoice={isOpenModalDetailInvoice}
                closeModalDetailInvoice={closeModalDetailInvoice}
            />
            <ModalRevertInvoice
                id={Number(id)}
                isOpenModalRevertInvoice={isOpenModalRevertInvoice}
                closeModalRevertInvoice={closeModalRevertInvoice}
            />
        </>
    );
};

export default CardDaftarInvoice;
