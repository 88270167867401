import {
    Card,
    Stack,
    Avatar,
    Typography,
    Link,
    Button,
    Chip,
    Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDetailBarangModal } from "../../context/detailBarangModalContext";
import { useHapusStokModal } from "../../context/hapusStokModalContext";
import { useStokModal } from "../../context/stokModalContext";
import avatarAlt from "../../utils/avatarAlt";
import StarIcon from "@mui/icons-material/Star";
import toRibuan from "../../utils/toRibuan";
import toRupiah from "../../utils/toRupiah";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";
import { ItemSatuan, DetailBundling, IOptions } from "../../constants/types";
import { usePrintBarcodeModal } from "../../context/printBarcodeModal";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IconButton from "@mui/material/IconButton";

interface ICardStokBarangProps {
    id: number;
    namaBarang: string;
    deskripsi: string;
    harga: number;
    url: string;
    sku: string;
    jumlahStok: number;
    kategori: string;
    kategoriId: IOptions;
    isActiveStok: boolean;
    itemSatuan: ItemSatuan[] | null;
    isBundling: number;
    detailBundling: DetailBundling[] | [];
    favorite: boolean;
    hargaMultiple: { id: number; harga: number; keterangan: string }[];
    handleSelectedProducts: (id: number, nama: string, harga: number, stok: number) => void;
    isSelected: (id: number) => boolean;
}

const CardStokBarang = ({
    id,
    namaBarang,
    deskripsi,
    harga,
    url,
    sku,
    jumlahStok,
    kategori,
    kategoriId,
    isActiveStok,
    itemSatuan,
    isBundling,
    detailBundling,
    favorite,
    hargaMultiple,
    handleSelectedProducts,
    isSelected
}: ICardStokBarangProps) => {
    const { openModal } = useStokModal();
    const { openModalHapusStok } = useHapusStokModal();
    const { openModalDetailBarang } = useDetailBarangModal();
    const { openModalPrintBarcode } = usePrintBarcodeModal();

    const truncate = (str: string) => {
        return str.length > 60 ? str.substring(0, 60) + "..." : str;
    };

    return (
        <React.Fragment>
            <Card
                variant="outlined"
                sx={{
                    marginBottom: 1,
                    padding: 2,
                }}
            >
                <Chip label={kategori} color="primary" size="small" />
                <Stack
                    className="card-barang"
                    direction="column"
                    spacing={1}
                    justifyContent="space-between"
                    marginTop={1}
                >
                    <Stack direction="row" alignItems="center">
                        <IconButton
                            disabled={(jumlahStok <= 0 && jumlahStok !== null)}
                            onClick={() => handleSelectedProducts(id, namaBarang, harga, jumlahStok)}
                            sx={{ minWidth: "unset", padding: "6px", width: "fit-content", marginRight: 2 }}
                            size="small"
                            color="primary"
                        >
                            {isSelected(id) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                        </IconButton>

                        <Link
                            onClick={() =>
                                openModalDetailBarang(namaBarang, url, deskripsi)
                            }
                            color="#000000"
                            underline="none"
                            sx={{ cursor: "pointer" }}
                        >
                            <Stack direction="row" spacing={2}>
                                <Avatar
                                    sx={{
                                        backgroundColor: "primary.main",
                                        width: 78,
                                        height: 78,
                                        borderRadius: "10px",
                                    }}
                                    variant="square"
                                    alt={namaBarang}
                                    src={url}
                                >
                                    {avatarAlt(namaBarang)}
                                </Avatar>
                                {favorite === true && (
                                    <StarIcon
                                        sx={{
                                            position: "relative",
                                            zIndex: 2,
                                            bottom: 8,
                                            left: -34,
                                            border: 1,
                                            borderColor: "#e7e5e4",
                                            borderRadius: "50%",
                                            padding: 0.1,
                                            color: "#fbbf24",
                                            bgcolor: "#FFFFFF",
                                        }}
                                        fontSize="medium"
                                    />
                                )}
                                <Box>
                                    <Typography variant="subtitle2">
                                        {sku}
                                    </Typography>
                                    <Typography variant="body2" fontWeight="bold">
                                        {namaBarang}
                                    </Typography>
                                    <Typography variant="body1" fontWeight="bold">
                                        {toRupiah(harga)}
                                    </Typography>
                                    {/* {deskripsi ? (
                                        <Typography
                                            variant="subtitle2"
                                            color="#76747C"
                                        >
                                            {truncate(deskripsi)}
                                        </Typography>
                                    ) : undefined} */}
                                    {/* <Typography
                                        mt={1}
                                        variant="subtitle2"
                                        color={
                                            jumlahStok < 5 && jumlahStok !== null
                                                ? "#ef4444"
                                                : "#76747C"
                                        }
                                        fontWeight={
                                            jumlahStok < 5 && jumlahStok !== null
                                                ? 700
                                                : 600
                                        }
                                    >
                                        {jumlahStok === null
                                            ? "Tanpa stok"
                                            : jumlahStok > 5
                                            ? "Tersedia " +
                                            toRibuan(jumlahStok) +
                                            " barang"
                                            : jumlahStok <= 5 && jumlahStok > 0
                                            ? "Barang tersisa " +
                                            toRibuan(jumlahStok)
                                            : "Barang telah habis"}
                                    </Typography> */}
                                </Box>
                            </Stack>
                        </Link>
                    </Stack>

                    <Link
                        onClick={() =>
                            openModalDetailBarang(namaBarang, url, deskripsi)
                        }
                        color="#000000"
                        underline="none"
                        sx={{ cursor: "pointer" }}
                    >
                        
                        <Stack direction="column" marginTop={1} spacing={1}>
                            <Typography variant="body2" component="div">
                                Stok Tersedia:{" "}
                                <Typography
                                    variant="body2"
                                    fontWeight="bold"
                                    color={
                                        jumlahStok < 5 && jumlahStok !== null
                                            ? "#ef4444"
                                            : "#76747C"
                                    }
                                    display="inline"
                                >
                                    {jumlahStok === null
                                        ? "Tidak Aktif"
                                        : jumlahStok > 5
                                        ? toRibuan(jumlahStok)
                                        : jumlahStok <= 5 && jumlahStok > 0
                                        ? toRibuan(jumlahStok)
                                        : "Habis"}
                                </Typography>
                            </Typography>
                            <Typography variant="body2">
                                {truncate(deskripsi ?? "Tidak ada deskripsi")}
                            </Typography>
                        </Stack>
                    </Link>
                    <Stack
                        display="flex"
                        alignItems="flex-end"
                        justifyContent="flex-end"
                        direction="row"
                    >
                        <Stack direction="row" spacing={2}>
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    openModal({
                                        id: id,
                                        namaBarang: namaBarang,
                                        deskripsiBarang: deskripsi,
                                        kategoriId: kategoriId,
                                        harga: harga,
                                        sku: sku,
                                        jumlahStok: jumlahStok,
                                        isActiveStok: isActiveStok,
                                        itemSatuan: itemSatuan,
                                        isBundling,
                                        detailBundling,
                                        favorite: favorite,
                                        hargaMultiple: hargaMultiple,
                                    })
                                }
                                size="small"
                                sx={{
                                    minWidth: "unset",
                                    padding: "6px",
                                    width: "fit-content",
                                }}
                            >
                                <EditIcon />
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    openModalPrintBarcode(
                                        sku,
                                        namaBarang,
                                        String(harga),
                                    )
                                }
                                size="small"
                                sx={{
                                    minWidth: "unset",
                                    padding: "6px",
                                    width: "fit-content",
                                }}
                            >
                                <PrintIcon />
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    openModalHapusStok(id, namaBarang)
                                }
                                size="small"
                                color="error"
                                sx={{
                                    minWidth: "unset",
                                    padding: "6px",
                                    width: "fit-content",
                                }}
                            >
                                <DeleteIcon />
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Card>
        </React.Fragment>
    );
};

export default CardStokBarang;
