import React, { useCallback, useEffect, useMemo, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import Spinner from "../Spinner/Spinner";
import useUser from "../../services/queries/useUser";
import AddIcon from "@mui/icons-material/Add";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import * as yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useKategori from "../../services/queries/useKategori";
import defaultAxios, { AxiosError } from "axios";
import { useAuth } from "../../context/authContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { useKategoriModal } from "../../context/kategoriModalContext";
import { Controller, useForm } from "react-hook-form";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEditKategoriModal } from "../../context/editKategoriModalContext";
import { useHapusKategoriModal } from "../../context/hapusKategoriModalContext";
import {
    AddKategoriBody,
    AddKategoriResponse,
    ErrorFieldResponse,
    ParamsGetKategori,
} from "../../constants/types";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NoRowsImage from "../NoRowsImage/NoRowsImage";
import LoadingTabel from "../LoadingTabel/LoadingTabel";

interface IModalKategoriProps {
    refetch: () => void;
}

const schema = yup
    .object({
        namaKategori: yup.string().required("Kolom wajib diisi"),
    })
    .required();

const ModalKategori = ({ refetch }: IModalKategoriProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const { isOpenModalKategori, closeModalKategori } = useKategoriModal();
    const { openModalEditKategori } = useEditKategoriModal();
    const { openModalHapusKategori } = useHapusKategoriModal();
    const { ukmIdUser } = useAuth();
    const { status: statusUser } = useUser();
    const initialParams = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 8,
        page: 1,
        search: "",
    };
    const [paramsKategori, setParamsKategori] =
        useState<ParamsGetKategori>(initialParams);
    const {
        data: dataKategori,
        refetch: refetchKategori,
        isLoading: isLoadingKategori,
    } = useKategori(paramsKategori);
    const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    useEffect(() => {
        refetchKategori();
    }, [isOpenModalKategori, refetchKategori]);

    const columns: GridColDef[] = [
        {
            field: "nama",
            headerName: "Nama Kategori",
            // headerClassName: "headerColumn",
            // flex: isPhoneScreen ? undefined : 3,
            flex: 1,
            minWidth: 170,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "aksi",
            headerName: "Aksi",
            // headerClassName: "headerColumn",
            headerAlign: "center",
            align: "center",
            // flex: isPhoneScreen ? undefined : 1,
            minWidth: 130,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                openModalEditKategori(
                                    Number(params.id),
                                    params.row.nama,
                                );
                                closeModalKategori();
                            }}
                            size="small"
                            color="buttonyellow"
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <EditIcon sx={{ width: "16px", height: "16px" }} />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                openModalHapusKategori(
                                    params.id,
                                    params.row.nama,
                                );
                                closeModalKategori();
                            }}
                            size="small"
                            color="buttonred"
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <DeleteIcon
                                sx={{ width: "16px", height: "16px" }}
                            />
                        </Button>
                    </Stack>
                );
            },
        },
    ];

    const handleOnPagination = (page: number) => {
        setParamsKategori((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsKategori((prev) => ({ ...prev, size }));
    };

    const initialValues = useMemo(
        () => ({
            ukmId: ukmIdUser && ukmIdUser[0],
            namaKategori: "",
        }),
        [ukmIdUser],
    );

    const {
        handleSubmit,
        control,
        reset,
        setError,
        formState: { errors },
    } = useForm<AddKategoriBody>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: AddKategoriBody) => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.post<AddKategoriResponse>(
                `/api/kategori`,
                values,
            );
            if (data.code === 200) {
                Swal.fire({
                    title: "Kategori berhasil ditambahkan",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                // closeModalKategori();
                refetchKategori();
            }
            setIsButtonLoading(false);
            setIsSuccessSubmit(true);
            reset(initialValues);

            // console.log(data.nama);
        } catch (err) {
            if (defaultAxios.isAxiosError(err)) {
                const serverError = err as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    console.log(serverError.response?.data?.errors);
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.errors) {
                        Object.keys(fieldError.errors).forEach((key) => {
                            const errorMessage = fieldError.errors[key];
                            setError(key as any, {
                                type: "manual",
                                message: errorMessage[0],
                            });
                        });
                    }
                }
                setIsButtonLoading(false);
                setIsSuccessSubmit(false);
            }
            handleErrorResponse(err);
            console.log(err);
            setIsSuccessSubmit(false);
            setIsButtonLoading(false);
        }
    };

    const handleErrorResponse = useCallback((error) => {
        if (defaultAxios.isAxiosError(error)) {
            const serverError = error as AxiosError<any>;
            console.log(serverError.response);
            if (serverError && serverError.response) {
                console.log(`serverError`, serverError);
                if (serverError.response.data.data.errors) {
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.message}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    console.log("", `${serverError.response.data.data}`, [
                        { text: "OK" },
                    ]);
                }
            } else {
                Swal.fire({
                    title: "Terjadi Kesalahan!",
                    text: `Terjadi kesalahan! Silahkan coba lagi.`,
                    icon: "error",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });
                console.log("", `Terjadi kesalahan! Silahkan coba lagi.`, [
                    { text: "OK" },
                ]);
            }
        }
    }, []);

    useEffect(() => {
        if (ukmIdUser) {
            setParamsKategori((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        if (isSuccessSubmit === true) {
            refetch();
        }
        setIsSuccessSubmit(false);
        console.log(isSuccessSubmit);
    }, [isSuccessSubmit, refetch]);

    useEffect(() => {
        if (isOpenModalKategori) {
            reset(initialValues);
        }
    }, [initialValues, isOpenModalKategori, reset]);

    const isLoading = statusUser === "loading";

    if (isLoading) {
        return (
            <Stack
                display="grid"
                gridTemplateColumns="1fr"
                alignItems="center"
                height={400}
                position="relative"
            >
                <Spinner />
            </Stack>
        );
    }

    return (
        <Dialog
            // maxWidth="sm"
            maxWidth="sm"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModalKategori}
            onClose={closeModalKategori}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                    position: "relative",
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalKategori}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        {/* Atur Kategori */}
                        Atur Kategori
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {/* @ts-ignore */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                        container
                        // alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={9} sm={8} md={8} lg={8.5} xl={8.5}>
                            <Controller
                                name="namaKategori"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        autoFocus
                                        id="namaKategori"
                                        placeholder="Kategori baru..."
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.namaKategori)}
                                        helperText={
                                            errors.namaKategori
                                                ? errors.namaKategori.message
                                                : " "
                                        }
                                        {...field}
                                    />
                                )}
                                rules={{ required: "Nama Kategori required" }}
                            />
                        </Grid>
                        <Grid item xs={3} sm={4} md={4} lg={3.5} xl={3.5}>
                            <LoadingButton
                                loading={isButtonLoading}
                                loadingPosition={
                                    isPhoneScreen ? "center" : "start"
                                }
                                // loadingPosition="center"
                                variant="contained"
                                size="large"
                                fullWidth
                                startIcon={
                                    isPhoneScreen ? undefined : <AddIcon />
                                }
                                type="submit"
                                // sx={{
                                //     height: isPhoneScreen ? undefined : "40px",
                                // }}
                            >
                                {isPhoneScreen ? <AddIcon /> : "Simpan"}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
                <Box
                    sx={{
                        // height: 550,
                        width: "100%",
                        marginTop: 2,
                        backgroundColor: "#ffffff",
                        borderRadius: 1,
                        "& .headerColumn": {
                            backgroundColor: "#E4EEE8",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                        },
                        "& .MuiDataGrid-columnSeparator": {
                            visibility: "hidden",
                        },
                        "& .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        },
                        "& .MuiDataGrid-iconButtonContainer": {
                            visibility: "visible",
                            width: "0 !important",
                        },
                    }}
                >
                    <DataGrid
                        autoHeight
                        paginationMode="server"
                        components={{
                            ColumnUnsortedIcon: () => {
                                return <ImportExportIcon />;
                            },
                            NoRowsOverlay: NoRowsImage,
                            LoadingOverlay: LoadingTabel,
                            // Pagination: CustomPagination,
                        }}
                        rows={dataKategori?.content ?? []}
                        columns={columns}
                        disableColumnMenu
                        disableSelectionOnClick
                        // rowsPerPageOptions={[10, 15, 20]}
                        rowsPerPageOptions={[10]}
                        onPageSizeChange={handleOnSizeChange}
                        onPageChange={handleOnPagination}
                        rowCount={dataKategori?.totalElements ?? 0}
                        page={paramsKategori.page - 1}
                        pageSize={paramsKategori.size}
                        density="standard"
                        loading={isLoadingKategori}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ModalKategori;
