import React, { Fragment } from "react";
import { Text, Font, View, StyleSheet } from "@react-pdf/renderer";
import toRibuan from "../../utils/toRibuan";
import { DaftarKategoriListInvoice } from "../../constants/types";

Font.register({
    family: "Open-Sans",
    src: "https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf",
});

Font.register({
    family: "OpenSansBold",
    src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
});

interface IPrintStrukA4KategoriProps {
    category?: DaftarKategoriListInvoice[];
}

const styles = StyleSheet.create({
    container: {
        marginBottom: 0,
    },
    header: {
        flexDirection: "row",
        borderTopColor: "#000",
        borderTopWidth: 1,
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        alignItems: "center",
        fontFamily: "OpenSansBold",
        fontSize: 11,
        borderLeftColor: "#000",
        borderLeftWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
    },
    headerText: {
        paddingHorizontal: 8,
        height: 24,
        fontFamily: "OpenSansBold",
        fontSize: 11,
    },
    row: {
        flexDirection: "row",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        borderLeftColor: "#000",
        borderLeftWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
        alignItems: "center",
        fontFamily: "Open-Sans",
        fontSize: 11,
    },
    rowItem: {
        flexDirection: "row",
        alignItems: "center",
        fontFamily: "Open-Sans",
        fontSize: 11,
    },
    no: {
        width: "5%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 4,
        height: 24,
    },
    kategori: {
        width: "20%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 24,
    },
    description: {
        width: "40%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 24,
    },
    qty: {
        width: "10%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        textAlign: "center",
        height: 24,
    },
    rate: {
        width: "25%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        textAlign: "right",
        height: 24,
    },
    amount: {
        width: "25%",
        paddingHorizontal: 8,
        textAlign: "right",
        height: 24,
    },
});

const PrintStrukA4Kategori = ({ category }: IPrintStrukA4KategoriProps) => {
    if (!category || category.length === 0) return null;

    return (
        <Fragment>
            <View style={styles.container}>
                <View style={styles.header}>
                    <Text style={[styles.headerText, styles.no]}>NO</Text>
                    <Text style={[styles.headerText, styles.kategori]}>NAMA KATEGORI</Text>
                    <Text style={[styles.headerText, styles.description]}>NAMA BARANG</Text>
                    <Text style={[styles.headerText, styles.qty]}>QTY</Text>
                    <Text style={[styles.headerText, styles.rate]}>HARGA SATUAN (Rp)</Text>
                    <Text style={[styles.headerText, styles.amount]}>JUMLAH (Rp)</Text>
                </View>
                {category.map((kategori, kategoriIndex) => (
                    <View style={styles.container} key={kategoriIndex}>
                        {kategori.items.map((item, itemIndex) => (
                            <View style={styles.row} key={itemIndex}>
                                <Text style={styles.no}>{itemIndex + 1}</Text>
                                <Text style={styles.kategori}>{kategori.kategori}</Text>
                                <View style={styles.rowItem}>
                                    <Text style={styles.description}>{item.namaItem}</Text>
                                    <Text style={styles.qty}>{item.jumlahItem}</Text>
                                    <Text style={styles.rate}>{toRibuan(item.hargaSatuan)}</Text>
                                    <Text style={styles.amount}>{toRibuan(item.jumlahItem * item.hargaSatuan)}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                ))}
            </View>
        </Fragment>
    );
};

export default PrintStrukA4Kategori;
