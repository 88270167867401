import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { BlobProvider } from "@react-pdf/renderer";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import PrintLabaRugi from "../PrintLabaRugi/PrintLabaRugi";
import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "@mdi/react";
import { mdiFilePdfBox } from "@mdi/js";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import toRibuan from "../../utils/toRibuan";
import { useAuth } from "../../context/authContext";
import { ParamsGetLaporanLabaRugi } from "../../constants/types";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import useLaporanLabaRugi from "../../services/queries/useLaporanLabaRugi";

interface ICardLaporanLabaRugiProps {
    tanggalAwal: string;
    tanggalAkhir: string;
}

const CardLaporanLabaRugi = ({
    tanggalAwal,
    tanggalAkhir,
}: ICardLaporanLabaRugiProps) => {
    let now = moment().format("YYYY/MM/DD");

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const { data: user } = useUser();
    const { ukmIdUser } = useAuth();
    const ukmId = useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const initialParamsLaporanLabaRugi = {
        ukmId: ukmIdUser && ukmIdUser[0],
        tglawal: now,
        tglakhir: now,
    };
    const [paramsLaporanLabaRugi, setParamsLaporanLabaRugi] =
        useState<ParamsGetLaporanLabaRugi>(initialParamsLaporanLabaRugi);
    const { data: laporanLabaRugi, isLoading: isLoadingLaporanLabaRugi } =
        useLaporanLabaRugi(paramsLaporanLabaRugi);

    useEffect(() => {
        if (ukmIdUser) {
            setParamsLaporanLabaRugi((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        if (tanggalAwal || tanggalAkhir) {
            setParamsLaporanLabaRugi((prev) => ({
                ...prev,
                tglawal: tanggalAwal,
                tglakhir: tanggalAkhir,
            }));
        }
    }, [tanggalAkhir, tanggalAwal]);

    return (
        <Card variant="outlined" sx={{ borderRadius: "0 4px 4px 4px" }}>
            <CardContent>
                <Stack
                    direction={{
                        xs: "column",
                        sm: "row",
                    }}
                    width="100%"
                    display="flex"
                    sx={{
                        alignItems: {
                            xs: "stretch",
                            sm: "center",
                        },
                        justifyContent: {
                            xs: "space-between",
                            sm: "space-between",
                        },
                    }}
                    spacing={2}
                    marginBottom={1}
                >
                    <Stack
                        direction={{
                            xs: "column",
                            sm: "row",
                        }}
                        sx={{
                            alignItems: {
                                sx: "normal",
                                sm: "center",
                            },
                        }}
                        spacing={{ xs: 1, sm: 2 }}
                    >
                        <Typography id="produkTerjual" fontWeight="bold">
                            Laporan Laba Rugi
                        </Typography>
                        <Typography variant="subtitle2">
                            {`Periode: ${moment(
                                paramsLaporanLabaRugi.tglawal,
                            ).format("DD/MM/YYYY")} - ${
                                paramsLaporanLabaRugi.tglakhir &&
                                moment(paramsLaporanLabaRugi.tglakhir).format(
                                    "DD/MM/YYYY",
                                )
                            }`}
                        </Typography>
                    </Stack>
                    <BlobProvider
                        document={
                            <PrintLabaRugi
                                laporanLabaRugi={laporanLabaRugi}
                                tglAwal={moment(
                                    paramsLaporanLabaRugi.tglawal,
                                ).format("DD/MM/YYYY")}
                                tglAkhir={moment(
                                    paramsLaporanLabaRugi.tglakhir,
                                ).format("DD/MM/YYYY")}
                                ukm={ukmData?.namaToko ?? ""}
                            />
                        }
                    >
                        {({ blob, url, loading, error }) => {
                            return (
                                <LoadingButton
                                    loading={
                                        loading || isLoadingLaporanLabaRugi
                                    }
                                    disableElevation
                                    loadingPosition="start"
                                    startIcon={
                                        <Icon
                                            path={mdiFilePdfBox}
                                            size="24px"
                                        />
                                    }
                                    variant="contained"
                                    href={url ?? ""}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Ekspor PDF
                                </LoadingButton>
                            );
                        }}
                    </BlobProvider>
                </Stack>
                <TableContainer
                    component={Paper}
                    sx={{
                        borderRadius: 1,
                        backgroundColor: "#fff",
                        border: "1px solid rgba(0,0,0,0.12)",
                        display: isLoadingLaporanLabaRugi ? "flex" : undefined,

                        justifyContent: isLoadingLaporanLabaRugi
                            ? "center"
                            : undefined,

                        alignItems: isLoadingLaporanLabaRugi
                            ? "center"
                            : undefined,

                        padding: 2,
                        marginTop: "1rem",
                        minHeight: 300,
                        width: "100%",
                    }}
                >
                    {isLoadingLaporanLabaRugi ? (
                        <img
                            src="/images/LoadingUlo.gif"
                            width={100}
                            height={100}
                            alt="loading..."
                            style={{
                                objectFit: "contain",
                            }}
                        />
                    ) : (
                        laporanLabaRugi && (
                            <Table
                                sx={{
                                    minWidth: 700,
                                }}
                                size="small"
                                aria-label="spanning table"
                            >
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            backgroundColor: "primary.main",
                                        }}
                                    >
                                        <TableCell
                                            colSpan={2}
                                            sx={{
                                                color: "#fff",
                                                fontWeight: 500,
                                            }}
                                        >
                                            Tanggal
                                        </TableCell>
                                        <TableCell
                                            colSpan={3}
                                            align="right"
                                            sx={{
                                                color: "#fff",
                                                fontWeight: 500,
                                            }}
                                        >
                                            {moment(
                                                paramsLaporanLabaRugi.tglawal,
                                            ).format("DD/MM/YYYY")}{" "}
                                            -{" "}
                                            {paramsLaporanLabaRugi.tglakhir &&
                                                moment(
                                                    paramsLaporanLabaRugi.tglakhir,
                                                ).format("DD/MM/YYYY")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        sx={{
                                            backgroundColor: "rgb(248,248,248)",
                                        }}
                                    >
                                        <TableCell
                                            colSpan={5}
                                            sx={{
                                                fontWeight: 500,
                                            }}
                                        >
                                            Pendapatan
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            colSpan={4}
                                            sx={{
                                                paddingLeft: 4,
                                            }}
                                        >
                                            Total dari Pendapatan:
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {laporanLabaRugi?.content[
                                                "Total dari Cost of Sales"
                                            ] >= 0
                                                ? toRibuan(
                                                      String(
                                                          laporanLabaRugi
                                                              ?.content[
                                                              "Total dari Revenue"
                                                          ],
                                                      ),
                                                  )
                                                : `( ${toRibuan(
                                                      String(
                                                          laporanLabaRugi
                                                              ?.content[
                                                              "Total dari Revenue"
                                                          ] * -1,
                                                      ),
                                                  )} )`}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{
                                            backgroundColor: "rgb(248,248,248)",
                                        }}
                                    >
                                        <TableCell
                                            colSpan={5}
                                            sx={{
                                                fontWeight: 500,
                                            }}
                                        >
                                            Biaya Pembelian
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            colSpan={4}
                                            sx={{
                                                paddingLeft: 4,
                                            }}
                                        >
                                            Total dari Biaya Pembelian:
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {laporanLabaRugi?.content[
                                                "Total dari Cost of Sales"
                                            ] >= 0
                                                ? toRibuan(
                                                      String(
                                                          laporanLabaRugi
                                                              ?.content[
                                                              "Total dari Cost of Sales"
                                                          ],
                                                      ),
                                                  )
                                                : `( ${toRibuan(
                                                      String(
                                                          laporanLabaRugi
                                                              ?.content[
                                                              "Total dari Cost of Sales"
                                                          ] * -1,
                                                      ),
                                                  )} )`}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{
                                            backgroundColor: "#f0fff1",
                                        }}
                                    >
                                        <TableCell
                                            colSpan={4}
                                            sx={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Laba Kotor
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {laporanLabaRugi.content[
                                                "Gross Profit"
                                            ] >= 0
                                                ? toRibuan(
                                                      String(
                                                          laporanLabaRugi
                                                              ?.content[
                                                              "Gross Profit"
                                                          ],
                                                      ),
                                                  )
                                                : `( ${toRibuan(
                                                      String(
                                                          laporanLabaRugi
                                                              ?.content[
                                                              "Gross Profit"
                                                          ] * -1,
                                                      ),
                                                  )} )`}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            colSpan={5}
                                            sx={{
                                                color: "#fff",
                                            }}
                                        ></TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{
                                            backgroundColor: "rgb(248,248,248)",
                                        }}
                                    >
                                        <TableCell
                                            colSpan={5}
                                            sx={{
                                                fontWeight: 500,
                                            }}
                                        >
                                            Pengeluaran
                                        </TableCell>
                                    </TableRow>
                                    {laporanLabaRugi.content[
                                        "Operational Expense"
                                    ].map((oe, idx) => (
                                        <TableRow
                                            key={oe.kodeBiaya}
                                            sx={{
                                                verticalAlign: "top",
                                            }}
                                        >
                                            <TableCell
                                                sx={{
                                                    paddingLeft: 4,
                                                    maxWidth: 250,
                                                }}
                                            >
                                                {moment(oe.tanggalBiaya).format(
                                                    "DD MMM YYYY",
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Typography variant="body2">
                                                    {oe.kodeBiaya}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    color="grey"
                                                >
                                                    {oe.namaBiaya}
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                colSpan={3}
                                                align="right"
                                            >
                                                {Number(oe.totalBiaya) >= 0
                                                    ? toRibuan(
                                                          String(oe.totalBiaya),
                                                      )
                                                    : `( ${toRibuan(
                                                          String(
                                                              Number(
                                                                  oe.totalBiaya,
                                                              ) * -1,
                                                          ),
                                                      )} )`}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell
                                            colSpan={4}
                                            sx={{
                                                paddingLeft: 4,
                                            }}
                                        >
                                            Total dari Pengeluaran:
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                fontWeight: 500,
                                            }}
                                        >
                                            {laporanLabaRugi?.content[
                                                "Total dari Operational Expense"
                                            ] >= 0
                                                ? toRibuan(
                                                      String(
                                                          laporanLabaRugi
                                                              ?.content[
                                                              "Total dari Operational Expense"
                                                          ],
                                                      ),
                                                  )
                                                : toRibuan(
                                                      String(
                                                          laporanLabaRugi
                                                              ?.content[
                                                              "Total dari Operational Expense"
                                                          ] * -1,
                                                      ),
                                                  )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{
                                            backgroundColor: "#f0fff1",
                                        }}
                                    >
                                        <TableCell
                                            colSpan={4}
                                            sx={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Laba Bersih (Rugi)
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {laporanLabaRugi.content[
                                                "Profit (Loss)"
                                            ] >= 0
                                                ? toRibuan(
                                                      String(
                                                          laporanLabaRugi
                                                              ?.content[
                                                              "Profit (Loss)"
                                                          ],
                                                      ),
                                                  )
                                                : `( ${toRibuan(
                                                      String(
                                                          laporanLabaRugi
                                                              ?.content[
                                                              "Profit (Loss)"
                                                          ] * -1,
                                                      ),
                                                  )} )`}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        )
                    )}
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default CardLaporanLabaRugi;
