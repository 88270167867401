import { useQuery } from "react-query";
import {
    ParamsPostUsersByUkm,
    PostUsersByUkmResponse,
} from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (params: ParamsPostUsersByUkm) => {
    const url = qs.stringifyUrl({
        url: "api/getusers",
        query: {
            ukmId: params.ukmId,
            size: params.size,
            page: params.page,
            search: `${params.search}`,
        },
    });
    const { data } = await axios.get<PostUsersByUkmResponse>(url);
    return data.data;
};

export default function useUsersByUkm(params: ParamsPostUsersByUkm) {
    return useQuery<PostUsersByUkmResponse["data"]>(
        ["users", params],
        () => handleRequest(params),
        { enabled: !!params.ukmId },
    );
}
