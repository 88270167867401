import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import PrintStrukTableHeader from "./PrintStrukTableHeader";
import PrintStrukA4Kategori from "./PrintStrukA4Kategori";
import PrintStrukA4TableRow from "./PrintStrukA4TableRow";
import PrintStrukA4TableTransaksi from "./PrintStrukA4TableTransaksi";
import PrintStrukA4Total from "./PrintStrukA4Total";
import PrintStrukA4Diskon from "./PrintStrukA4Diskon";
import PrintStrukA4Pajak from "./PrintStrukA4Pajak";
import PrintStrukA4Tunai from "./PrintStrukA4Tunai";
import PrintStrukA4Kembalian from "./PrintStrukA4Kembalian";
import PrintStrukA4Subtotal from "./PrintStrukA4Subtotal";
import PrintStrukA4SubtotalKategori from "./PrintStrukA4SubtotalKategori";
import { DaftarKategoriListInvoice } from "../../constants/types";


interface ICart {
    id: number;
    namaBarang: string;
    namaKategori: string;
    sku?: string | undefined;
    qty: number;
    harga: number;
    jumlahStok?: number | undefined;
}

interface IPrintStrukA4TableProps {
    cart?: ICart[];
    diskon?: number;
    pajak?: number;
    pajakPersen?: number | null;
    tunai?: number;
    kembalian?: number;
    namaMetode?: string | null;
    total: number;
    category: DaftarKategoriListInvoice[] | undefined;
    groupByKategori: boolean;
}

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 24,
    },
});

const PrintStrukA4Table = ({
    cart,
    diskon,
    pajak,
    pajakPersen,
    tunai,
    kembalian,
    namaMetode,
    total,
    category,
    groupByKategori,
}: IPrintStrukA4TableProps) => {
    return (
        <View style={styles.tableContainer}>
            {/* <PrintStrukTableHeader /> */}

            {/* <PrintStrukA4TableRow cart={cart} /> */}
            
            {groupByKategori ? <PrintStrukA4TableTransaksi cart={cart} /> :  <PrintStrukA4TableRow cart={cart} />}

            <PrintStrukA4Subtotal cart={cart} />

            <PrintStrukA4Kategori category={category} />
            <PrintStrukA4SubtotalKategori category={category} />

            {diskon ? <PrintStrukA4Diskon diskon={diskon} /> : <View></View>}
            {pajak ? (
                <PrintStrukA4Pajak pajak={pajak} pajakPersen={pajakPersen} />
            ) : (
                <View></View>
            )}
            <PrintStrukA4Total total={total} />
            {tunai ? (
                <PrintStrukA4Tunai tunai={tunai} namaMetode={namaMetode} />
            ) : (
                <View></View>
            )}
            {kembalian ? (
                <PrintStrukA4Kembalian kembalian={kembalian} />
            ) : (
                <View></View>
            )}
        </View>
    );
};

export default PrintStrukA4Table;
