import React from "react";
import { Text, Font, View, StyleSheet } from "@react-pdf/renderer";
import toRibuan from "../../utils/toRibuan";
import { DaftarKategoriListInvoice } from "../../constants/types";

Font.register({
    family: "Open-Sans-Bold",
    src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
});


interface IPrintStrukA4SubtotalKategoriProps {
    category?: DaftarKategoriListInvoice[];
}

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
        fontFamily: "Open-Sans-Bold",
        borderLeftColor: "#fff",
        borderLeftWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
        fontSize: 11,
    },
    description: {
        width: "60%",
        textAlign: "right",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 18,
    },
    subtotalText: {
        width: "20%",
        textAlign: "right",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 18,
    },
    subtotal: {
        width: "20%",
        textAlign: "right",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        paddingHorizontal: 8,
        height: 18,
    },
});

const PrintStrukA4SubtotalKategori = ({ category }: IPrintStrukA4SubtotalKategoriProps) => {

    if (!category || category.length === 0) {
        return null;
    }

    const total = category?.reduce((acc, curr) => {
        const subtotalForCategory = curr.items.reduce((subtotalAcc, item) => {
            return subtotalAcc + item.jumlahItem * item.hargaSatuan;
        }, 0);
        return acc + subtotalForCategory;
    }, 0);

    return (
        <View style={styles.row}>
            <Text style={styles.description}> </Text>
            <Text style={styles.subtotalText}>Subtotal</Text>
            <Text style={styles.subtotal}>{toRibuan(Number(total))}</Text>
        </View>
    );
};

export default PrintStrukA4SubtotalKategori;
