import { useQuery } from "react-query";
import { GetMetodePembayaranResponse } from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (ukmId?: number | null) => {
    const apiGetMetodePembayaran = qs.stringifyUrl({
        url: "/api/metode-bayar",
        query: {
            ukmId: ukmId,
        },
    });
    const { data } = await axios.get<GetMetodePembayaranResponse>(
        apiGetMetodePembayaran,
    );
    return data.data;
};

export default function useMetodePembayaran(ukmId?: number | null) {
    return useQuery<GetMetodePembayaranResponse["data"]>(
        ["listMetodePembayaran", ukmId],
        () => handleRequest(ukmId),
        { enabled: !!ukmId },
    );
}
