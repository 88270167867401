import React, { ReactNode } from "react";
import { AlertProvider } from "./alertContext";
import { AuthProvider } from "./authContext";
import { DetailBarangModalProvider } from "./detailBarangModalContext";
import { EditKategoriModalProvider } from "./editKategoriModalContext";
import { HapusKategoriModalProvider } from "./hapusKategoriModalContext";
import { HapusStokModalProvider } from "./hapusStokModalContext";
import { HapusMultipleStokModalProvider } from "./hapusMultipleStokModalContext";
import { KatalogModalProvider } from "./katalogModalContext";
import { KategoriModalProvider } from "./kategoriModalContext";
import { LogoutModalProvider } from "./logoutModalContext";
import { StokModalProvider } from "./stokModalContext";
import { TransaksiProvider } from "./transaksiContext";
import { UkmModalProvider } from "./ukmModalContext";
import { HapusUkmModalProvider } from "./hapusUkmModalContext";
import { ImportModalProvider } from "./importModalContext";
import { PrintBarcodeModalProvider } from "./printBarcodeModal";
import { MutasiStokModalProvider } from "./mutasiStokModalContext";

interface IindexProps {
    children: ReactNode;
}

const AppProvider = ({ children }: IindexProps) => {
    return (
        <AuthProvider>
            <AlertProvider>
                <LogoutModalProvider>
                    <StokModalProvider>
                        <HapusStokModalProvider>
                            <HapusMultipleStokModalProvider>
                                <KategoriModalProvider>
                                    <HapusKategoriModalProvider>
                                        <EditKategoriModalProvider>
                                            <KatalogModalProvider>
                                                <ImportModalProvider>
                                                    <MutasiStokModalProvider>
                                                        <DetailBarangModalProvider>
                                                            <UkmModalProvider>
                                                                <HapusUkmModalProvider>
                                                                    <PrintBarcodeModalProvider>
                                                                        <TransaksiProvider>
                                                                            {
                                                                                children
                                                                            }
                                                                        </TransaksiProvider>
                                                                    </PrintBarcodeModalProvider>
                                                                </HapusUkmModalProvider>
                                                            </UkmModalProvider>
                                                        </DetailBarangModalProvider>
                                                    </MutasiStokModalProvider>
                                                </ImportModalProvider>
                                            </KatalogModalProvider>
                                        </EditKategoriModalProvider>
                                    </HapusKategoriModalProvider>
                                </KategoriModalProvider>
                            </HapusMultipleStokModalProvider>
                        </HapusStokModalProvider>
                    </StokModalProvider>
                </LogoutModalProvider>
            </AlertProvider>
        </AuthProvider>
    );
};

export default AppProvider;
