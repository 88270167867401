import React, { useCallback, useEffect, useMemo, useState } from "react";
import "moment/locale/id";
import Copy from "../../Assets/Copy";
import axios from "../../../../services/axios";
import moment from "moment";
import styles from "../../Payment.module.css";
import toRupiah from "../../../../utils/toRupiah";
import useLayanan from "../../../../services/queries/useLayanan";
import ChevronLeft from "../../Assets/ChevronLeft";
import useStatusPembayaran from "../../../../services/queries/useStatusPembayaran";
import PaymentContainer, {
    bgColor,
} from "../../PaymentContainer/PaymentContainer";
import {
    AddPaymentVAResponse,
    PaymentRouteParams,
} from "../../../../constants/types";
import { CircularProgress, Divider } from "@mui/material";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";
import BRIImage from "../../../../assets/images/banks/BRI.png";
import BNIImage from "../../../../assets/images/banks/BNI.png";
import BCAImage from "../../../../assets/images/banks/BCA.png";
import PERMATAImage from "../../../../assets/images/banks/PERMATA.png";
import CIMBImage from "../../../../assets/images/banks/CIMB.png";
import CountDown from "count-down-react";

interface CountdownProps {
    expiredTime: number;
}

interface CountDownRendererProps {
    hours: string;
    minutes: string;
    seconds: string;
}

const CoundownRenderer = ({
    hours,
    minutes,
    seconds,
}: CountDownRendererProps) => (
    <span>
        {hours}:{minutes}:{seconds}
    </span>
);

const UnmemorizedCountdownComponent: React.FC<CountdownProps> = (props) => {
    return (
        <CountDown
            date={Date.now() + props.expiredTime - 4000}
            renderer={CoundownRenderer}
        />
    );
};

const CountdownComponent = React.memo(UnmemorizedCountdownComponent);

interface IVirtualAccountProps {
    isMounted: boolean;
}

const VirtualAccount = (props: IVirtualAccountProps) => {
    // const moment = require("moment");
    moment.locales();
    moment.locale("id");
    const history = useHistory();
    const { orderId, layananId, ukmId } = useParams<PaymentRouteParams>();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");

    const [paymentVAResponse, setPaymentVAResponse] =
        useState<AddPaymentVAResponse>();
    // const [isOpenModalBatal, setIsOpenModalBatal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { data: statusPembayaran } = useStatusPembayaran(
        paymentVAResponse?.data.transaction_id,
    );
    const { data: dataLayanan, status: statusLayanan } = useLayanan();

    const namaLayanan =
        dataLayanan &&
        dataLayanan?.find((item) => item.id.toString() === layananId)?.nama;
    const deskripsiLayanan =
        dataLayanan &&
        dataLayanan?.find((item) => item.id.toString() === layananId)
            ?.deskripsiLayanan;
    const hargaLayanan =
        dataLayanan &&
        dataLayanan?.find((item) => item.id.toString() === layananId)?.harga;

    // const openModalBatal = () => setIsOpenModalBatal(true);
    // const closeModalBatal = () => setIsOpenModalBatal(false);

    const inquiryVA = paymentVAResponse?.data;

    const dateExpiredTime = useMemo(
        () => moment(inquiryVA?.payment_details.expired_time),
        [inquiryVA?.payment_details.expired_time],
    );
    const dateNow = useMemo(() => moment(), []);
    const expiredTime = dateExpiredTime.diff(dateNow); // 86400000

    useEffect(() => console.log("Moment diff: ", expiredTime), [expiredTime]);

    const copy = async (copyText: string) => {
        await navigator.clipboard.writeText(copyText);
        Swal.fire({
            title: "Disalin ke papan klip",
            position: "top-end",
            showConfirmButton: false,
            icon: "success",
            toast: true,
            timer: 3000,
            timerProgressBar: true,
            showCloseButton: true,
            customClass: {
                container: "my-swal",
            },
        });
    };

    useEffect(
        () => console.log("payment inquiry response: ", inquiryVA),
        [inquiryVA],
    );

    useEffect(() => {
        if (statusPembayaran?.transaction_status === "PAID") {
            history.push("/payment/success");
        }
    }, [history, statusPembayaran]);

    const request = useCallback(async () => {
        try {
            const { data } = await axios.post<AddPaymentVAResponse>(
                `/api/payment/generate`,
                {
                    orderId: orderId,
                    ukmId: ukmId,
                    layananId: layananId,
                    bank: code,
                },
            );
            if (data.data.response_code === "00") {
                setIsLoading(false);
                console.log(data);
                setPaymentVAResponse(data);
            } else {
                history.push(
                    `/payment/error?message=${data.data.response_message}`,
                );
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error);
            // Router.push("/payment/error");
        }
    }, [code, history, layananId, orderId, ukmId]);

    useEffect(() => {
        console.log(props.isMounted);
        if (props.isMounted) {
            request();
        }
    }, [props.isMounted, request]);

    if (isLoading) {
        return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    backgroundColor: "#4a4a4a",
                    alignItems: "center",
                    height: "100vh",
                    width: "100vw",
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    if (statusLayanan === "loading") {
        return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    backgroundColor: "#4a4a4a",
                    alignItems: "center",
                    height: "100vh",
                    width: "100vw",
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <PaymentContainer
                title="Virtual Account"
                headerLeft={<ChevronLeft />}
                onClickHeaderLeft={() => history.goBack()}
            >
                <div className={styles.section}>
                    <div className={styles.section_header}>
                        <div className={styles.half_focused_text}>Order ID</div>
                        <div className={styles.focused_text}>
                            {inquiryVA?.order_id}
                            <button
                                onClick={() => {
                                    copy(String(inquiryVA?.order_id));
                                }}
                                className={styles.button_icon}
                            >
                                <Copy
                                    style={{
                                        color: "#40A0F9",
                                    }}
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div className={styles.spacer} />
                <div className={styles.section}>
                    <div className={styles.section_header}>
                        <div className={styles.half_focused_text}>
                            Batas pembayaran
                        </div>
                    </div>
                    <div className={styles.batasPembayaran_content}>
                        <div>
                            {`${moment(
                                inquiryVA?.payment_details.expired_time,
                            ).format("DD MMMM YYYY HH:mm")} WIB`}
                        </div>
                        <div>
                            {/* <Countdown
                                date={Date.now() + expiredTime}
                                renderer={({ completed, formatted }) => {
                                    if (completed) {
                                        // Render a complete state
                                        return <span>Waktu habis!</span>;
                                    } else {
                                        // Render a countdown
                                        return (
                                            <span>
                                                {formatted.hours}:
                                                {formatted.minutes}:
                                                {formatted.seconds}
                                            </span>
                                        );
                                    }
                                }}
                            /> */}
                            <CountdownComponent expiredTime={expiredTime} />
                        </div>
                    </div>
                </div>
                <div className={styles.spacer} />
                <div className={styles.section}>
                    <div className={styles.selectedMethod_container}>
                        <div className={styles.method_image}>
                            <img
                                src={
                                    code === "BNI"
                                        ? BNIImage
                                        : code === "BRI"
                                        ? BRIImage
                                        : code === "CIMB"
                                        ? CIMBImage
                                        : code === "PERMATA"
                                        ? PERMATAImage
                                        : code === "BCA"
                                        ? BCAImage
                                        : ""
                                }
                                width={64}
                                height={64}
                                alt="loading..."
                                style={{ objectFit: "contain" }}
                            />
                        </div>
                        <div className={styles.selectedMethod_content}>
                            <div className={styles.half_focused_text}>
                                {code} Virtual Account
                            </div>
                            <div className={styles.selectedMethod_mid}>
                                {inquiryVA?.payment_details.va_number}
                                <button
                                    onClick={() =>
                                        copy(
                                            String(
                                                inquiryVA?.payment_details
                                                    .va_number,
                                            ),
                                        )
                                    }
                                    className={styles.button_icon}
                                >
                                    <Copy
                                        style={{
                                            color: "#40A0F9",
                                        }}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.selectedMethod_billing}>
                        <div className={styles.section_header}>
                            <div className={styles.half_focused_text}>Fee</div>
                            <div className={styles.focused_text}>
                                {toRupiah(
                                    Number(
                                        inquiryVA?.payment_details
                                            .total_fee_amount,
                                    ) +
                                        Number(
                                            inquiryVA?.payment_details
                                                .total_feenore_amount,
                                        ),
                                )}
                            </div>
                        </div>
                        <div
                            className={styles.section_header}
                            style={{ marginBottom: "16px" }}
                        >
                            <div className={styles.half_focused_text}>
                                Subtotal
                            </div>
                            <div className={styles.focused_text}>
                                {toRupiah(
                                    Number(
                                        inquiryVA?.payment_details
                                            .layanan_amount,
                                    ),
                                )}
                            </div>
                        </div>
                        <Divider />
                        <div
                            className={styles.section_header}
                            style={{ marginTop: "16px", marginBottom: "16px" }}
                        >
                            <div
                                className={styles.half_focused_text}
                                style={{
                                    color: "#000",
                                    fontSize: 18,
                                    fontWeight: 600,
                                }}
                            >
                                Total
                            </div>
                            <div
                                className={styles.focused_text}
                                style={{ fontSize: 18 }}
                            >
                                {toRupiah(
                                    Number(
                                        inquiryVA?.payment_details.total_amount,
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.spacer} />
                <div className={styles.section}>
                    <div className={styles.section_header}>
                        <div className={styles.half_focused_text}>
                            Detail Pesanan
                        </div>
                    </div>
                    <div className={styles.product}>
                        <div className={styles.product_container}>
                            <div
                                className={styles.product_info}
                                style={{ width: "50%" }}
                            >
                                <div className={styles.info_title}>
                                    {namaLayanan}
                                </div>
                                <div
                                    className={[
                                        styles.info_subtitle,
                                        styles.half_focused_text,
                                    ].join(" ")}
                                >
                                    {deskripsiLayanan}
                                </div>
                            </div>
                            <div
                                className={[
                                    styles.product_price,
                                    styles.focused_text,
                                ].join(" ")}
                            >
                                {toRupiah(hargaLayanan ?? 0)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.spacer} />
                <div className={styles.section}>
                    <div className={styles.section_header}>
                        <div className={styles.half_focused_text}>
                            Ingin mengubah pembayaran?
                        </div>
                    </div>
                    <button
                        onClick={() => history.goBack()}
                        className={styles.button_outline}
                        style={{
                            outline: `1px solid ${bgColor}`,
                            color: bgColor,
                        }}
                    >
                        Kembali
                    </button>
                </div>
            </PaymentContainer>
            {/* {inquiryVA && (
                <ModalBatal
                    transactionId={inquiryVA?.transaction_id}
                    closeModalBatal={closeModalBatal}
                    isOpenModalBatal={isOpenModalBatal}
                />
            )} */}
        </>
    );
};

export default React.memo(VirtualAccount);
