import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
    Image,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import toRupiah from "../../utils/toRupiah";
import toRibuan from "../../utils/toRibuan";
import OpenSans from "../../assets/fonts/OpenSans-Regular.ttf";
import OpenSansBold from "../../assets/fonts/OpenSans-Bold.ttf";
import OpenSansSemiBold from "../../assets/fonts/OpenSans-SemiBold.ttf";
import OpenSansMedium from "../../assets/fonts/OpenSans-Medium.ttf";
import UbuntuMono from "../../assets/fonts/UbuntuMono-Regular.ttf";
import { DaftarKategoriListInvoice } from "../../constants/types";

Font.register({
    family: "OpenSans",
    format: "truetype",
    src: OpenSans,
});
Font.register({
    family: "OpenSansBold",
    format: "truetype",
    src: OpenSansBold,
});
Font.register({
    family: "OpenSansSemiBold",
    format: "truetype",
    src: OpenSansSemiBold,
});
Font.register({
    family: "OpenSansMedium",
    format: "truetype",
    src: OpenSansMedium,
});
Font.register({
    family: "UbuntuMono",
    format: "truetype",
    src: UbuntuMono,
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#FFF",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 14,
        paddingRight: 14,
        height: "100%",
    },
    containerTitle: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        marginVertical: 12,
    },
    title: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 10,
        marginTop: 0,
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        fontFamily: "OpenSansSemiBold",
        fontWeight: "bold",
    },
    subtitle: {
        fontSize: 8,
        marginTop: 0,
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        fontFamily: "OpenSans",
    },
    cashierName: {
        fontSize: 8,
        marginTop: 6,
        fontFamily: "OpenSans",
    },
    tanggalTransaksi: {
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: 8,
        fontFamily: "OpenSans",
    },
    containerHeader: {
        flexDirection: "row",
        backgroundColor: "#45A779",
        color: "#fff",
        alignItems: "center",
        height: 24,
        textAlign: "center",
        fontStyle: "bold",
        flexGrow: 1,
    },
    namaHeader: {
        width: "45%",
        fontSize: 14,
        fontWeight: 700,
    },
    hargaHeader: {
        width: "15%",
        fontSize: 14,
        fontWeight: 700,
    },
    jumlahHeader: {
        width: "10%",
        fontSize: 14,
        fontWeight: 700,
    },
    skuHeader: {
        width: "25%",
        fontSize: 14,
        fontWeight: 700,
    },
    row: {
        fontSize: 8,
        flexDirection: "row",
        fontFamily: "OpenSans",
    },
    column: {
        marginTop: 8,
        marginBottom: 4,
        fontSize: 8,
        flexDirection: "column",
        fontFamily: "OpenSans",
    },
    columnKategori: {
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 5,
        fontSize: 8,
        flexDirection: "column",
        fontFamily: "OpenSans",
    },
    containerNamaBarang: {
        width: "100%",
        flexDirection: "column",
    },
    namaBarang: {
        textAlign: "left",
        fontSize: 8,
        marginBottom: 1,
        fontFamily: "OpenSansSemiBold",
    },
    qty: {
        width: "15%",
        textAlign: "left",
        fontSize: 8,
    },
    hargaSatuan: {
        width: "40%",
        fontSize: 8,
        textAlign: "left",
    },
    rate: {
        width: "45%",
        textAlign: "right",
        fontSize: 8,
    },
    keterangan: {
        fontSize: 8,
        marginTop: 8,
        fontFamily: "OpenSans",
    },
    divider: {
        marginVertical: 4,
        borderTop: "1px",
        color: "black",
        borderStyle: "dashed",
    },
    containerInfoTransaksi: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        fontFamily: "OpenSans",
    },
    infoTransaksi: {
        fontSize: 8,
        width: "50%",
        textAlign: "left",
    },
    nilaiTransaksi: {
        width: "50%",
        fontSize: 8,
        textAlign: "right",
    },
    infoTransaksiBold: {
        fontSize: 8,
        fontFamily: "OpenSansSemiBold",
        width: "40%",
        textAlign: "left",
    },
    nilaiTransaksiBold: {
        width: "50%",
        fontSize: 8,
        fontFamily: "OpenSansSemiBold",
        textAlign: "right",
    },
    containerPenutup: {
        marginTop: 14,
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        fontSize: 8,
        fontFamily: "OpenSansSemiBold",
    },
    containerBelumLunas: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 8,
        fontFamily: "OpenSans",
    },
    penutup: {
        fontSize: 8,
        fontFamily: "OpenSansSemiBold",
    },
    containerWaterMark: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 6,
        marginTop: 10,
        marginBottom: 5,
        fontFamily: "OpenSans",
    },
    containerNore: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 6,
    },
    containerLogo: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 12,
        marginBottom: 6,
    },
    logo: {
        width: 54,
        height: 54,
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
    },
    qr: {
        width: "3cm",
        height: "3cm",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
    },
    boldText: {
        fontWeight: 700,
        fontFamily: "OpenSansSemiBold",
    },
    containerJatuhTempo: {
        width: "100%",
        marginTop: 10,
        marginBottom: 14,
    },
    namaKategori: {
        textAlign: "left",
        fontSize: 8,
        marginBottom: 1,
        fontWeight: 700,
        fontFamily: "OpenSansBold",
    },
});

const stylesheet = {
    // clear margins for all <p> tags
    p: {
        margin: 0,
        textAlign: "center",
    },
};

interface ICart {
    id: number;
    namaBarang: string;
    namaKategori: string;
    sku?: string | undefined;
    qty: number;
    harga: number;
    jumlahStok?: number | undefined;
}

interface IPrintStrukProps {
    is80mm: boolean;
    namaToko: string;
    gambarToko?: string;
    alamatToko: string;
    telpToko: string;
    total: number;
    kasir: string;
    sales: string | null;
    tanggalTransaksi: string;
    cart: ICart[] | undefined;
    category: DaftarKategoriListInvoice[] | undefined;
    diskon?: number;
    pajak?: number;
    pajakPersen?: number | null;
    tunai?: number;
    kembalian?: number;
    isPro: number;
    namaMetode?: string | null;
    noMetode?: string | null;
    belumLunas?: boolean;
    qr?: string | null;
    html?: any;
    keterangan?: string | null;
    transaksiKe: string;
    groupByKategori: boolean;
}

const PrintStrukPdf = (props: IPrintStrukProps) => {
    const rows =
        props.cart &&
        props.cart.map((row) => (
            <View style={styles.column} key={row.id}>
                <View style={{ width: "65%", flexDirection: "column" }}>
                    <Text style={styles.namaBarang}>{row.namaBarang}</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                    <Text style={styles.qty}>{row.qty}x</Text>
                    <Text style={styles.hargaSatuan}>
                        @{toRibuan(row.harga)}
                    </Text>
                    <Text style={styles.rate}>
                        {toRupiah(row.harga * row.qty)}
                    </Text>
                </View>
            </View>
        ));

    const rowk = props.category?.map((row, rowIndex) => (
        <View key={rowIndex}>
            <View
                style={{ width: "80%", flexDirection: "column", marginTop: 4 }}
            >
                <Text style={styles.namaKategori}>{row.kategori}</Text>
            </View>
            {row.items.map((item, itemIndex) => (
                <View style={styles.columnKategori} key={itemIndex}>
                    <View style={{ width: "65%", flexDirection: "column" }}>
                        <Text style={styles.namaBarang}>{item.namaItem}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={styles.qty}>{item.jumlahItem}x</Text>
                        <Text style={styles.hargaSatuan}>
                            @{toRibuan(item.hargaSatuan)}
                        </Text>
                        <Text style={styles.rate}>
                            {toRupiah(item.hargaSatuan * item.jumlahItem)}
                        </Text>
                    </View>
                </View>
            ))}
        </View>
    ));

    // const rowtransaksi =
    //     props.cart &&
    //     props.cart.map((row) => (
    //         <View style={styles.column} key={row.id}>
    //             <View style={{ width: "65%", flexDirection: "column" }}>
    //             <Text style={styles.namaBarang}>{row.namaKategori}</Text>
    //                 <Text style={styles.namaBarang}>{row.namaBarang}</Text>
    //             </View>
    //             <View style={{ flexDirection: "row" }}>
    //                 <Text style={styles.qty}>{row.qty}x</Text>
    //                 <Text style={styles.hargaSatuan}>
    //                     @{toRibuan(row.harga)}
    //                 </Text>
    //                 <Text style={styles.rate}>
    //                     {toRupiah(row.harga * row.qty)}
    //                 </Text>
    //             </View>
    //         </View>
    //     ));

    // const rowtransaksi = props.cart?.map((row) => (
    //     <View style={styles.column} key={row.id}>
    //         <View style={{ width: '65%', flexDirection: 'column' }}>
    //             <Text style={styles.namaBarang}>{row.namaKategori}</Text>
    //             <Text style={styles.namaBarang}>{row.namaBarang}</Text>
    //         </View>
    //         <View style={{ flexDirection: 'row' }}>
    //             <Text style={styles.qty}>{row.qty}x</Text>
    //             <Text style={styles.hargaSatuan}>@{toRibuan(row.harga)}</Text>
    //             <Text style={styles.rate}>{toRupiah(row.harga * row.qty)}</Text>
    //         </View>
    //     </View>
    // ));

    let lastCategory: string | null = null;

    const rowtransaksi = props.cart?.map((row, index) => {
        const showCategory = row.namaKategori !== lastCategory;
        lastCategory = row.namaKategori;

        return (
            <View key={index}>
                <View
                    style={{
                        width: "80%",
                        flexDirection: "column",
                        marginTop: 4,
                    }}
                >
                    {showCategory && (
                        <Text style={styles.namaKategori}>
                            {row.namaKategori}
                        </Text>
                    )}
                </View>
                <View style={styles.columnKategori}>
                    <View style={{ width: "65%", flexDirection: "column" }}>
                        <Text style={styles.namaBarang}>{row.namaBarang}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={styles.qty}>{row.qty}x</Text>
                        <Text style={styles.hargaSatuan}>
                            @{toRibuan(row.harga)}
                        </Text>
                        <Text style={styles.rate}>
                            {toRupiah(row.harga * row.qty)}
                        </Text>
                    </View>
                </View>
            </View>
        );
    });

    const widthInCm = 7;
    const widthInPoints = widthInCm * 28.35;

    return (
        <Document>
            <Page
                wrap
                size={{
                    width: props.is80mm ? widthInPoints : "5.5cm",
                    height: "100%",
                }}
                style={styles.page}
            >
                <View>
                    {props.gambarToko ? (
                        <View style={styles.containerLogo}>
                            <Image style={styles.logo} src={props.gambarToko} />
                        </View>
                    ) : (
                        <View></View>
                    )}
                    <View style={styles.containerTitle}>
                        <View style={styles.title}>
                            <Text>{props.namaToko}</Text>
                        </View>
                        <View style={styles.subtitle}>
                            <Text>{props.alamatToko}</Text>
                        </View>
                        <View style={styles.subtitle}>
                            <Text>+{props.telpToko}</Text>
                        </View>
                    </View>
                    {props.qr ? (
                        <View style={styles.containerLogo}>
                            <Image style={styles.qr} src={props.qr} />
                        </View>
                    ) : (
                        <View></View>
                    )}

                    {props.transaksiKe !== null && (
                        <View style={styles.tanggalTransaksi}>
                            <Text>No. Transaksi</Text>
                            <Text>{props.transaksiKe}</Text>
                        </View>
                    )}

                    <View style={styles.tanggalTransaksi}>
                        <Text>Kasir</Text>
                        <Text>{props.kasir}</Text>
                    </View>
                    {props.sales !== null ? (
                        <View style={styles.tanggalTransaksi}>
                            <Text>Sales</Text>
                            <Text>{props.sales}</Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    <View style={styles.tanggalTransaksi}>
                        <Text>Tanggal</Text>
                        <Text>{props.tanggalTransaksi}</Text>
                    </View>
                    {props.namaMetode ? (
                        <View style={styles.tanggalTransaksi}>
                            <Text>Metode</Text>
                            <Text>{props.namaMetode}</Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    {props.noMetode ? (
                        <View style={styles.tanggalTransaksi}>
                            <Text>No. Tujuan</Text>
                            <Text>{props.noMetode}</Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    <View style={styles.divider}></View>
                    {/* <View>{rows}</View> */}
                    <View>{rowk}</View>
                    <View>{props.groupByKategori ? rowtransaksi : rows}</View>
                    {props.diskon ? (
                        <View style={styles.containerInfoTransaksi}>
                            <Text style={styles.infoTransaksi}>Diskon</Text>
                            <Text style={styles.nilaiTransaksi}>
                                {toRupiah(props.diskon)}
                            </Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    {props.pajak ? (
                        <View style={styles.containerInfoTransaksi}>
                            <Text style={styles.infoTransaksi}>
                                {props.pajakPersen
                                    ? `Pajak (${props.pajakPersen}%)`
                                    : `Pajak`}
                            </Text>
                            <Text style={styles.nilaiTransaksi}>
                                {toRupiah(props.pajak)}
                            </Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    <View style={styles.divider}></View>
                    <View style={styles.containerInfoTransaksi}>
                        <Text style={styles.infoTransaksiBold}>Total</Text>
                        <Text style={styles.nilaiTransaksiBold}>
                            {toRupiah(props.total)}
                        </Text>
                    </View>
                    <View style={styles.divider}></View>
                    {props.tunai ? (
                        <View style={styles.containerInfoTransaksi}>
                            <Text style={styles.infoTransaksi}>
                                {props.namaMetode}
                            </Text>
                            <Text style={styles.nilaiTransaksi}>
                                {toRupiah(props.tunai)}
                            </Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    {props.kembalian ? (
                        <View style={styles.containerInfoTransaksi}>
                            <Text style={styles.infoTransaksi}>Kembalian</Text>
                            <Text style={styles.nilaiTransaksi}>
                                {toRupiah(props.kembalian)}
                            </Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    <View style={styles.divider}></View>
                    {props.belumLunas ? (
                        <>
                            <View style={styles.divider}></View>
                            <View style={styles.containerBelumLunas}>
                                <Text style={styles.penutup}>BELUM LUNAS</Text>
                            </View>
                            <View style={styles.divider}></View>
                        </>
                    ) : (
                        <View></View>
                    )}
                    {props.keterangan ? (
                        <View style={styles.keterangan}>
                            <Text>Keterangan:</Text>
                            <Text>{props.keterangan}</Text>
                        </View>
                    ) : (
                        <View></View>
                    )}
                    <View style={styles.containerPenutup}>
                        {props.html ? (
                            <Html
                                stylesheet={stylesheet}
                                style={{ fontSize: 8 }}
                            >
                                {`<div>${props.html}</div>`}
                            </Html>
                        ) : (
                            // <div
                            //     dangerouslySetInnerHTML={{ __html: props.html }}
                            // />
                            <>
                                <Text style={styles.penutup}>
                                    Terimakasih telah berbelanja
                                </Text>
                                <Text style={styles.penutup}>
                                    Sampai bertemu kembali
                                </Text>
                            </>
                        )}
                    </View>
                    <View style={styles.containerWaterMark}>
                        <Text>
                            {props.isPro !== 1 && (
                                <Text style={styles.boldText}>Free</Text>
                            )}{" "}
                            POS System by UKM Lokal
                        </Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default PrintStrukPdf;
